import React from 'react'
import { useEffect, useRef } from 'react';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import Cloud3 from '../../assets/images/cloud-3.png'
import BlackHalfCircle from '../../assets/images/black-half-circle.png'
import Ellipse13 from '../../assets/images/Ellipse 13.png'
import CardSecGreenCirc from '../../assets/images/card-sec-green-circle.png'
import social3 from '../../assets/images/social(3).png'
import social4 from '../../assets/images/social(4).png'
import social5 from '../../assets/images/social(5).png'
import social6 from '../../assets/images/social(6).png'
import social7 from '../../assets/images/social(7).png'
import social8 from '../../assets/images/social(8).png'
import social9 from '../../assets/images/social(9).png'
import MaskGroup from '../../assets/images/Mask Group.png'
import Maps from '../../assets/images/maps.png'
import GooglePlus from '../../assets/images/google-plus.png'
import HalfCircle from '../../assets/images/half-circle.png'
import Iphone14 from '../../assets/images/iPhone-14.png'
import Iphone12 from '../../assets/images/iPhone-12.png'
import Iphone12Xl from '../../assets/images/iPhone-12Xl.png'
import Group927 from '../../assets/images/Group 927.png'
import Group888 from '../../assets/images/Group 888.png'
import Group887 from '../../assets/images/Group 887.png'
import Group931 from '../../assets/images/Group 931.png' 
import Vector from '../../assets/images/Vector.png'
import Vector2 from '../../assets/images/Vector(2).png' 

function CustomSection() {
    const { t, i18n } = useTranslation();

    const slider1 = useRef();
    useEffect( () => {
        document.querySelectorAll('.scroll-to-center').forEach((stc) => {
          // First try to scroll well beyond the possible width
          stc.scrollLeft = 1000000
          // it will be set to the maximum scrollLeft
          //console.log(stc.scrollLeft) 
        
          // Then set to half it's value
          stc.scrollLeft /= 2
        })
       
    },[])

    return (
    <>
        <section className="animated-scroll hidden lg:flex lg:flex-col items-center justify-center pt-[4rem] px-[5rem] text-center bg-[#F4F7FA] relative pb-[12rem] active">
            <div className="pt-[2em] pb-[5rem] z-10">
                <h1 className="lg:text-7xl pb-[1rem]">
                    <span id="text-primary">{t("index.customize_your_card_profile_1")}</span> <br />{t("index.customize_your_card_profile_2")}
                </h1>
                <p className="text-gray-600 text-2xl">{t("index.select_your_type_of_card")}</p>
            </div>
            <div className="w-[14rem] absolute top-[2rem] left-[1rem]">
                <img src={Cloud3} alt="" />
            </div>
            <div className="absolute top-[105px] right-10 w-[13rem]">
                <img src={BlackHalfCircle} alt="" />
            </div>
            <div className="absolute top-[230px] right-[145px] w-[5rem]">
                <img src={Ellipse13} alt="" />
            </div>
            <div className="absolute top-[418px] left-0 w-[4rem]">
                <img src={CardSecGreenCirc} alt="" />
            </div>
            <div className="absolute top-[331px] left-[335px] w-[8rem]">
                <div rel="noopener noreferrer"><img className="hover:scale-110 hover:transition-transform hover:ease-in-out" src={social3} alt="" /></div>
            </div>
            <div className="absolute top-[331px] right-[290px] w-[8rem]">
                <div rel="noopener noreferrer"><img className="hover:scale-110 hover:transition-transform hover:ease-in-out" src={social4} alt="" /></div>
            </div>
            <div className="absolute top-[542px] left-[86px] w-[8rem]">
                <div rel="noopener noreferrer"><img className="hover:scale-110 hover:transition-transform hover:ease-in-out" src={social5} alt="" /></div>
            </div>
            <div className="absolute top-[542px] right-[86px]  w-[6rem]">
                <div rel="noopener noreferrer"><img className="hover:scale-110 hover:transition-transform hover:ease-in-out" src={social6} alt="" /></div>
            </div>
            <div className="absolute bottom-[140px] left-[240px]  w-[8rem]">
                <div rel="noopener noreferrer"><img className="hover:scale-110 hover:transition-transform hover:ease-in-out" src={social7} alt="" /></div>
            </div>
            <div className="absolute bottom-[80px] left-[44%]  w-[8rem]">
                <div rel="noopener noreferrer"><img className="hover:scale-110 hover:transition-transform hover:ease-in-out" src={social8} alt="" /></div>
            </div>
            <div className="absolute bottom-[140px] right-[300px]  w-[8rem]">
                <div rel="noopener noreferrer"><img className="hover:scale-110 hover:transition-transform hover:ease-in-out" src={social9} alt="" /></div>
            </div>
            <div className="absolute bottom-[0px] right-[0px]  w-[16rem]">
                <img src={MaskGroup} alt="" />
            </div>
            <div className="absolute bottom-[265px] left-[80px]  w-[8rem]">
                <img className="cursor-pointer hover:scale-110 hover:transition-transform hover:ease-in-out" src={Maps} alt="" />
            </div>
            <div className="absolute bottom-[265px] right-[80px] w-[6rem]">
                <img className="cursor-pointer hover:scale-110 hover:transition-transform hover:ease-in-out" src={GooglePlus} alt="" />
            </div>
            <div className="absolute bottom-[40px] left-[75px]  w-[1rem]">
                <img src={Ellipse13} alt="" />
            </div>
            <div className="absolute bottom-[40px] left-[70px]  w-[3rem]" style={{ transform: 'rotate(60deg)' }}>
                <img className="cursor-pointer hover:scale-110 hover:transition-transform hover:ease-in-out" src={HalfCircle} alt="" />
            </div>
            <div className="lg:flex items-center justify-center w-full px-[4rem] mt-[3rem] gap-x-[30px] z-10 xl:py-0">
                <div className="scale-class flex flex-col  items-center justify-center hover:scale-110">
                    <img className="xl:w-80" src={Iphone14} alt="" />
                    <div className="flex flex-col items-center justify-center pt-[20px]">
                        <h3 className="text-xl" style={{ color: '#A4A4A4' }}>{t("index.personal")}</h3>
                    </div>
                </div>
                <div className="scale-class flex flex-col items-center justify-center xl:px-10 hover:scale-110">
                    <img className="xl:w-96" src={Iphone12Xl} alt="" />
                    <div className="flex flex-col items-center justify-center pt-[20px] ">
                        <h3 className="text-xl" style={{ color: '#001428' }}>{t("index.business")}</h3>
                    </div>
                </div>
                <div className="scale-class flex  flex-col items-center justify-center hover:scale-110">
                    <img className="xl:w-80" src={Iphone14} alt="" />
                    <div className="flex flex-col items-center justify-center pt-[20px]">
                        <h3 className="text-xl" style={{ color: '#A4A4A4' }}>{t("index.influencers")}</h3>
                    </div>
                </div>
            </div>
        </section>
        <div className="flex flex-col justify-center items-center relative mx-auto m_lg:pt-6 lg:pt-[6em] text-center lg:hidden custom-sec animated-scroll active" style={{backgroundColor: 'rgb(245, 250, 244) !important'}}>
            <div className="px-[2em] py-[2rem]">
                <h1 className="cursor-scale text-4xl">
                    <span className="" id="text-primary">{t("index.customize_your_card_profile_1")}</span> {t("index.customize_your_card_profile_2")}
                </h1>
                <p className="text-gray-600 text-2xl">{t("index.select_your_type_of_card")}</p>
            </div>


            <Slider className="m_md:w-full md:w-10/12 mt-6 mb-12 relative" {...{infinite: true, draggable: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, centerMode: true, centerPadding: '15%'}} ref={slider1} >
                <div>
                    <img className="item" src={Iphone12Xl} style={{display: 'inline'}}/>
                    <p className="m_sm:text-xl sm:text-3xl mt-3">{t("index.personal")}</p>
                </div>
                <div>
                    <img className="item" src={Iphone12Xl} style={{display: 'inline'}}/>
                    <p className="m_sm:text-xl sm:text-3xl mt-3">{t("index.business")}</p>
                </div>
                <div>
                    <img className="item" src={Iphone12Xl} style={{display: 'inline'}}/>
                    <p className="m_sm:text-xl sm:text-3xl mt-3">{t("index.influencers")}</p>
                </div>
            </Slider>
            <div className="absolute m_md:w-full md:w-10/12 top-2/4 m_2sm:pt-20">
                <div className="absolute left-0 car_leftbutton cursor-pointer bg-gray-200 hover:bg-gray-300"
                    onClick={(e) => { slider1.current.slickPrev(); }} style={{transform: 'rotate(180deg)'}}></div>
                <div className="absolute right-0 car_leftbutton cursor-pointer bg-gray-200 hover:bg-gray-300"
                    onClick={(e) => { slider1.current.slickNext(); }}></div>             
            </div>
        </div>
    </>
    )     
    
}

 export default CustomSection
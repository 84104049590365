import React from 'react'
import { useTranslation } from "react-i18next";
import Cloud3 from '../../assets/images/cloud-3.png' 
import testimonialsCircle from '../../assets/images/testimonials-circle.png'  
import RCircle from '../../assets/images/r-circle.png' 
import Ellipse9 from '../../assets/images/Ellipse 9.png' 
import Ellipse13 from '../../assets/images/Ellipse 13.png'
import DotsWhite from '../../assets/images/dots-white.png' 
import RoundedBottomCircle from '../../assets/images/rounded-bottom-circle.png'
import Profile from '../../assets/images/profile.png'
import CardImg from '../../assets/images/card-img.png'
import CardImg1 from '../../assets/images/bg-card-1.png'
import CardImg2 from '../../assets/images/bg-card-2.png'
import CardImg3 from '../../assets/images/bg-card-3.png'
import CardImg5 from '../../assets/images/bg-card-5.png'
import CardImg6 from '../../assets/images/bg-card-6.png'
import Quotes from '../../assets/images/icon-quotes.png'
import Group876 from '../../assets/images/Group 876(1).png'
import Agrimedia1 from '../../assets/images/agrimedia 1.png'
import Group1 from '../../assets/images/Group(1).png' 
import Group2 from '../../assets/images/Group(2).png'
import Bancolombia1 from '../../assets/images/bancolombia 1(1).png'
import Brand4 from '../../assets/images/brand-4.svg' 
import BranX from '../../assets/images/bran-x.svg'
import Brand from '../../assets/images/BRAND.svg'

function Testimonials() {
    const { t, i18n } = useTranslation();
    return (
    <>
        <section className="animated-scroll flex flex-col items-center justify-center w-full h-full pt-[3rem] bg-black relative active" id="testimonials">
            <div className="absolute m_lg:hidden top-0 right-14 w-[11rem]">
                <img src={Cloud3} alt=""/>
            </div>
            <div className="absolute top-[310px] right-0 w-[5rem]">
                <img src={testimonialsCircle} alt=""/>
            </div>
            <div className="absolute top-[0px] left-[70px] w-[5rem]">
                <img src={RCircle} alt=""/>
            </div>
            <div className="absolute top-[20px] left-[90px] w-[3rem]">
                <img src={Ellipse9} alt=""/>
            </div>
            <div className="absolute top-[40px] left-[110px] w-[1rem]">
                <img src={Ellipse13} alt=""/>
            </div>
            <div className="absolute top-[200px] left-[50px] w-[3rem]" style={{transform:'rotate(90deg)'}}>
                <img src={Ellipse9} alt=""/>
            </div>
            <div className="absolute bottom-[305px] left-[130px] w-[11rem]" style={{transform:'rotate(90deg)'}}>
                <img src={DotsWhite} alt=""/>
            </div>
            <div className="absolute m_md:hidden bottom-0 right-0 w-[15rem]">
                <img src={RoundedBottomCircle} alt=""/>
            </div>
            <div className="w-full pt-[30px] pb-[4rem]  px-[20px] flex flex-col  justify-center items-center text-white">
            <h2 className="m_sm:text-5xl sm:text-8xl">{t("index.testimonials")}</h2>
            <p className="m_sm:text-sm sm:text-xl text-center pt-[10px]" style={{letterSpacing: '-0.03em'}}>{t("index.powerful_tool_1")}<br />{t("index.powerful_tool_2")}</p>
                
            <div className="mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 m_md:w-full gap-10 mt-20 z-10 text-black">
                <div className="flex flex-col space-y-10">
                    <div className="py-[15px] bg-white px-[14px] flex flex-col drop-shadow" style={{borderRadius: '31px'}}>
                        <div className="flex gap-x-[25px] items-center">
                            <img className="w-14 rounded-full" src={Profile} alt=""/>
                            <div className="flex flex-col gap-y-[4px]" >
                                <span style={{fontFamily:'Poppins,sans-serif'}}>@nickudhary01</span>
                                <span className="text-gray-400 text-sm"> Kiev, Ukraine</span>
                            </div>
                        </div>
                        <div className="py-[15px]">
                            <img className="w-full" src={CardImg1} alt=""/>
                        </div>
                        <div className="flex gap-x-[25px] items-center">
                            <img src={Quotes} alt=""/>
                            <div>
                                <p className="text-md">Tap your card on a phone and<br /> share your contact details without.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="py-[15px] bg-white px-[14px] flex flex-col drop-shadow" style={{borderRadius: '31px'}}>
                        <div className="flex gap-x-[25px] items-center">
                            <img className="w-14 rounded-full" src={Profile} alt=""/>
                            <div className="flex flex-col gap-y-[4px]" >
                                <span style={{fontFamily:'Poppins,sans-serif'}}>@nickudhary01</span>
                                <span className="text-gray-400 text-sm"> Kiev, Ukraine</span>
                            </div>
                        </div>
                        <div className="py-[15px]">
                            <img className="w-full" src={CardImg3} alt=""/>
                        </div>
                        <div className="flex gap-x-[25px] items-center">
                            <img src={Quotes} alt=""/>
                            <div>
                                <p className="text-md">Tap your card on a phone and<br /> share your contact details without.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:flex flex-col space-y-10 hidden">
                    <div className="py-[15px] bg-white px-[14px] flex flex-col drop-shadow" style={{borderRadius: '31px'}}>
                        <div className="flex gap-x-[25px] items-center">
                            <img className="w-14 rounded-full" src={Profile} alt=""/>
                            <div className="flex flex-col gap-y-[4px]" >
                                <span style={{fontFamily:'Poppins,sans-serif'}}>@nickudhary01</span>
                                <span className="text-gray-400 text-sm"> Kiev, Ukraine</span>
                            </div>
                        </div>
                        <div className="py-[15px]">
                            <img className="w-full" src={CardImg} alt=""/>
                        </div>
                        <div className="flex gap-x-[25px] items-center">
                            <img src={Quotes} alt=""/>
                            <div>
                                <p className="text-md">Tap your card on a phone and<br /> share your contact details without.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="py-[15px] bg-white px-[14px] flex flex-col drop-shadow" style={{borderRadius: '31px'}}>
                        <div className="flex gap-x-[25px] items-center">
                            <img className="w-14 rounded-full" src={Profile} alt=""/>
                            <div className="flex flex-col gap-y-[4px]" >
                                <span style={{fontFamily:'Poppins,sans-serif'}}>@nickudhary01</span>
                                <span className="text-gray-400 text-sm"> Kiev, Ukraine</span>
                            </div>
                        </div>
                        <div className="py-[15px]">
                            <img className="w-full" src={CardImg5} alt=""/>
                        </div>
                        <div className="flex gap-x-[25px] items-center">
                            <img src={Quotes} alt=""/>
                            <div>
                                <p className="text-md">Tap your card on a phone and<br /> share your contact details without.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:flex flex-col space-y-10 hidden ">
                    <div className="py-[15px] bg-white px-[14px] flex flex-col drop-shadow" style={{borderRadius: '31px'}}>
                        <div className="flex gap-x-[25px] items-center">
                            <img className="w-14 rounded-full" src={Profile} alt=""/>
                            <div className="flex flex-col gap-y-[4px]" >
                                <span style={{fontFamily:'Poppins,sans-serif'}}>@nickudhary01</span>
                                <span className="text-gray-400 text-sm"> Kiev, Ukraine</span>
                            </div>
                        </div>
                        <div className="py-[15px]">
                            <img className="w-full" src={CardImg2} alt=""/>
                        </div>
                        <div className="flex gap-x-[25px] items-center">
                            <img src={Quotes} alt=""/>
                            <div>
                                <p className="text-md">Tap your card on a phone and<br /> share your contact details without.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="py-[15px] bg-white px-[14px] flex flex-col drop-shadow" style={{borderRadius: '31px'}}>
                        <div className="flex gap-x-[25px] items-center">
                            <img className="w-14 rounded-full" src={Profile} alt=""/>
                            <div className="flex flex-col gap-y-[4px]" >
                                <span style={{fontFamily:'Poppins,sans-serif'}}>@nickudhary01</span>
                                <span className="text-gray-400 text-sm"> Kiev, Ukraine</span>
                            </div>
                        </div>
                        <div className="py-[15px]">
                            <img className="w-full" src={CardImg6} alt=""/>
                        </div>
                        <div className="flex gap-x-[25px] items-center">
                            <img src={Quotes} alt=""/>
                            <div>
                                <p className="text-md">Tap your card on a phone and<br /> share your contact details without.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="w-full flex flex-col items-center justify-center pt-20">
                <h2 className="text-primary text-center">TRUSTED BY</h2>
                <div className="flex justify-center items-center gap-x-[4rem] pt-[1rem] w-[10rem]">
                    <img style={{height:'2.3rem'}} src={Group876} alt=""/>
                    <img src={Agrimedia1} alt=""/>
                    <img src={Group1} alt=""/>
                    <img src={Group2} alt=""/>
                    <img src={Bancolombia1} alt=""/>
                </div>
            </div>*/}
            </div>
        </section>
    </>
  )
}

export default Testimonials
import React from 'react'
import {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import { api_GetProfile, api_SetAccount } from './lib/Axios'
import ProfileNavBar from "./Settings_page/ProfileNavBar";
import { AiOutlineLink } from "react-icons/ai";

import Logo from "../assets/images/logo.svg";
import ProfileIcon from "../assets/images/ProfileIcon.png";
import SuperiorRotate from "../assets/images/Superior-Rotate.png";
import PersonalInfo from "../assets/images/personal-info.png";
import PersonalIcon from "../assets/images/PersonalIcon.png";
import ProfileIcon2 from "../assets/images/Personal-icon-svg.svg";
import Superior from "../assets/images/SuperiorIcon.png";
import GreenGrayCircle from "../assets/images/green-gray-circle.png";
import ProfileImg from "../assets/images/ProfileImg.png";
import Edit1 from "../assets/images/Edit-1.png";
import EmailIcon from "../assets/images/Email-Icon.svg";
import PhoneIcon from "../assets/images/Phone-Green-Icon.png";
import PlusIcon from "../assets/images/Plus-Icon.png";
import Website from "../assets/images/Website.svg";
import Location from "../assets/images/Location.svg";
import AddLocation from "../assets/images/Add-Location.svg";
import OpenIcon from '../assets/images/Open-Icon.svg'

const Account = () => {
    const { t, i18n } = useTranslation();
    let profile_obj = {};
    let account_obj = {
        currPassword: '',
        newPassword: '',
        rePassword: ''
    }
    var AxiosWork = false;
    let notFilled = '';

    const getProfileData = async (e) => {
        let response = await api_GetProfile();
        if( response.status == 200 ) {
            console.log(response);
            profile_obj = response.data;
            document.querySelectorAll('.js_pnav_fullname').forEach(function(el){ el.innerHTML = response.data.fullname; });
            document.querySelectorAll('.js_pnav_email').forEach(function(el){ el.innerHTML = response.data.email; });
            if (response.data.avatar != ''){ document.querySelector('.inp_p_leftavatar_img').setAttribute('src', 'http://'+window.location.hostname+':5000/static/avatar/'+response.data.avatar); response.data.avatar = ''; }
        } else {
            if (response.status == 401 || response.status == 404) { window.location.href = "/login"; }
            if (response.status == 500) { window.location.href = "/"; }
        }
    }

    function Change_Input_CurrPassowrd_profile(e) { account_obj.currPassword = e.currentTarget.value; }
    function Change_Input_NewPassowrd_profile(e) { account_obj.newPassword = e.currentTarget.value; }
    function Change_Input_RePassowrd_profile(e) { account_obj.rePassword = e.currentTarget.value; }

    function Click_SaveAccountInfo(e) {
        notFilled = '';

        if (account_obj.currPassword == '') { InputWasWrong('inp_p_currpass'); }
        else if (account_obj.currPassword.length < 6 || account_obj.currPassword.length > 24) { InputWasWrong('inp_p_currpass', t("index.current_password_length_error")); }
        
        if (account_obj.newPassword == '') { InputWasWrong('inp_p_newpass'); }
        else if (account_obj.newPassword.length < 6 || account_obj.newPassword.length > 24) { InputWasWrong('inp_p_newpass', t("index.current_password_length_error")); }
        
        if (account_obj.rePassword == '') { InputWasWrong('inp_p_repass'); }
        else if (account_obj.rePassword.length < 6 || account_obj.rePassword.length > 24) { InputWasWrong('inp_p_repass', t("index.current_password_length_error")); }
        
        if (notFilled == '' && account_obj.newPassword != account_obj.rePassword){ InputWasWrong('inp_p_repass', t("index.password_mismatch_error")); }

        if (notFilled != '') { document.querySelector('#'+notFilled).scrollIntoView(); }
        else { Click_SaveAccountInfo_2(e); }
    }
    const Click_SaveAccountInfo_2 = async (e) => {
        if (AxiosWork) { return; }
        else { document.querySelectorAll('.js_p_page_submit').forEach(function(el){ el.classList.add('opacity-50'); }); AxiosWork = true; }
        let response = await api_SetAccount({account_obj: account_obj});
        AxiosWork = false; document.querySelectorAll('.js_p_page_submit').forEach(function(el){ el.classList.remove('opacity-50'); });

        console.log(response);
        if( response.status == 200 ) {
            document.querySelectorAll('.inp_account_error').forEach(function(el){el.classList.remove('text-red-400');}); document.querySelectorAll('.inp_account_error').forEach(function(el){el.classList.add('text-green-400');});
            document.querySelectorAll('.inp_account_error').forEach(function(el){el.innerHTML = 'Changed successfuly';}); document.querySelectorAll('.inp_account_error').forEach(function(el){el.classList.remove('hidden');});
        } else {
            if (response.status == 400) {
                if (typeof response.data.currpass !== 'undefined') { InputWasWrong('inp_p_currpass', response.data.currpass); }
                if (typeof response.data.newpass !== 'undefined') { InputWasWrong('inp_p_newpass', response.data.newpass); }
            } else if (response.status == 401 || response.status == 404) {
                window.location.href = "/login";
            } else if (response.status == 500) {
                document.querySelectorAll('.inp_account_error').forEach(function(el){el.classList.remove('text-green-400');}); document.querySelectorAll('.inp_account_error').forEach(function(el){el.classList.add('text-red-400');});
                document.querySelectorAll('.inp_account_error').forEach(function(el){el.innerHTML = t("index.request_error");});
                document.querySelectorAll('.inp_account_error').forEach(function(el){el.classList.remove('hidden');});
            }
        }
    }

    function InputWasWrong(dom_id, error_text = '') { 
        notFilled = dom_id; document.querySelector('#'+dom_id).style.border = '1px solid #f56565';
        if (error_text != '') { document.querySelector('.'+dom_id+'_error').innerHTML = error_text; document.querySelector('.'+dom_id+'_error').classList.remove('hidden'); }
    }
    function InputClearError(dom_id) {
        document.querySelector('#'+dom_id).style.border = null;
        document.querySelectorAll('.'+dom_id+'_error').forEach(function(el){el.classList.add('hidden');});
        document.querySelectorAll('.inp_account_error').forEach(function(el){el.classList.add('hidden');});
    }

    function ReloadPage(e) { window.location.reload(); }
    function GoToUsernamePage(e) { window.open('/'+profile_obj.username, '_blank'); }

    function clearPlaceHolders(ndiv) { document.querySelector(ndiv).nextSibling.style.display = 'none'; }
    function onFocus(e) { e.currentTarget.nextSibling.style.display = 'none'; InputClearError(e.currentTarget.id); }
    function onBlur(e) { if (e.currentTarget.value.length != 0) { return; } e.currentTarget.nextSibling.style.display = ''; }

    useEffect(() => {
        getProfileData();
    });

    return (  
    <>
        <section id="ProfilePage" className="w-full min-h-screen flex flex-col lg:flex-row font-primary overflow-hidden">
            <ProfileNavBar id_menu_active={2} />

            <div className="w-full bg-[#F5FAF5]  pl-[2rem] pr-[2rem] lg:px-[2rem] py-[3rem] h-screen overflow-y-auto">
                <div className="flex flex-col gap-[2rem]">
                    <div className="flex flex-row justify-between">
                        <h2 className="text-2xl lg:text-3xl font-[600]">Edit Account Settings</h2>
                        <button className="flex items-center min-w-[7.625rem] gap-2 hover:opacity-75" onClick={(e) => GoToUsernamePage(e)} style={{ padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px"}}>
                            <AiOutlineLink size="26px" color="#000" /> {t("index.view_profile")}
                        </button>
                    </div>
                    <div className=" flex items-center gap-[1rem] ">
                        <span className="text-[#A4A4A4]">{t("index.my_profile")}</span>
                        <span>
                            <img src={Superior} alt="" />
                        </span>
                        <span className="font-[600]">{t("index.account_settings")}</span>
                    </div>
                    <div className="hidden md:flex items-center justify-between gap-[2rem] sm:w-[90%] lg:w-full">
                        <div className="flex flex-col gap-[.7rem]">
                            <h2 className="lg:text-2xl font-[600]">{t("index.account_settings")}</h2>
                            <p className="text-[#A4A4A4] text-[.9rem]">{t("index.update_settings")}</p>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center justify-center m_md:hidden gap-[1rem]">
                                <button className="min-w-[7.625rem]" onClick={(e) => ReloadPage(e)}
                                        style={{ padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px"}}>
                                    {t("index.cancel")}
                                </button>
                                <button className="min-w-[7.625rem] text-white js_p_page_submit" onClick={(e) => Click_SaveAccountInfo(e)}
                                        style={{ padding: "0.875rem 1.875rem", background: "linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)", boxShadow: "4px 16px 32px rgba(35, 180, 107, 0.35)", borderRadius: "40px"}} >
                                    {t("index.save")}
                                </button>
                            </div>
                            <span className="text-red-400 text-sm pt-2 pl-4 inp_account_error hidden"></span>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }} ></div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col items-baseline gap-[1rem]" >
                        <div className="flex m_lg:w-full m_lg:justify-center">
                            <h3 className="font-[600] lg:w-16 xl:w-48 whitespace-nowrap">{t("index.change_your_password")}</h3>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-row gap-[1rem] lg:gap-[3rem] w-full xl:w-10/12 2xl:w-8/12">
                                <div className="flex flex-col gap-[1rem] w-full">
                                    <div className="relative w-full">
                                        <input id="inp_p_currpass" onFocus={onFocus} onBlur={onBlur} maxLength="24" onChange={(e) => Change_Input_CurrPassowrd_profile(e)}
                                            type="password" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem] input-styles profile-inputs" placeholder=" " />
                                        <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                            {t("index.current_password")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span className="text-red-400 text-sm inp_p_currpass_error hidden"></span>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-row gap-[1rem] lg:gap-[3rem] w-full xl:w-10/12 2xl:w-8/12">
                                <div className="flex flex-col gap-[1rem] w-full">
                                    <div className="relative w-full">
                                        <input id="inp_p_newpass" onFocus={onFocus} onBlur={onBlur} maxLength="24" onChange={(e) => Change_Input_NewPassowrd_profile(e)}
                                            type="password" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem] input-styles profile-inputs" placeholder=" " />
                                        <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                            {t("index.new_password")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span className="text-red-400 text-sm inp_p_newpass_error hidden"></span>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-row gap-[1rem] lg:gap-[3rem] w-full xl:w-10/12 2xl:w-8/12">
                                <div className="flex flex-col gap-[1rem] w-full">
                                    <div className="relative w-full">
                                        <input id="inp_p_repass" onFocus={onFocus} onBlur={onBlur} maxLength="24" onChange={(e) => Change_Input_RePassowrd_profile(e)}
                                            type="password" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem] input-styles profile-inputs" placeholder=" " />
                                        <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                            {t("index.repeat_your_new_password")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span className="text-red-400 text-sm inp_p_repass_error hidden"></span>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>
                <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center gap-[1rem] pt-[3rem] md:hidden">
                        <button className="min-w-[7.625rem]" onClick={(e) => ReloadPage(e)}
                                style={{padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px" }} >
                            {t("index.cancel")}
                        </button>
                        <button className="min-w-[7.625rem] text-white js_p_page_submit" onClick={(e) => Click_SaveAccountInfo(e)}
                                style={{ padding: "0.875rem 1.875rem", background: "linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)", boxShadow: "4px 16px 32px rgba(35, 180, 107, 0.35)", borderRadius: "40px" }} >
                            {t("index.save")}
                        </button>
                    </div>
                    <span className="text-red-400 text-sm pt-2 pl-4 inp_account_error hidden"></span>
                </div>
            </div>
        </section>
    </>
    );
};


export default Account;
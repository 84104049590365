import { useEffect, useState } from "react"
import { QRCodeStyling_render } from '../lib/QRCodeStyling';

export function QrModal({ close }) {
    const [qrCode, setQrCode] = useState(null);

    useEffect(() => {
        if (qrCode == null){ 
            page_resize();
            window.addEventListener('resize', page_resize);
        }
    });

    function page_resize() {
        let w = window.innerWidth;
        if (w < 1024) { setQrCode(<QRCodeStyling_render size={300} url={window.location.href} />); }
        else { setQrCode(<QRCodeStyling_render size={500} url={window.location.href} />); }
    }

    return (
        <div className="relative">
            <div className="flex justify-between items-start p-4 rounded-t">
                <button type="button" className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" style={{ outline: 'none' }}
                    onClick={(e) => close()} data-modal-toggle="defaultModal">
                    <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="flex flex-col justify-center p-2">
                <h1 className="text-center text-3xl font-semibold text-gray-mute mb-6 ">QR CODE</h1>
                <div className="flex w-full justify-center">{qrCode}</div>
            </div>
        </div>
    )
}

import React from 'react'
import { useTranslation } from "react-i18next";
import VideoBgDesktop from '../../assets/images/video-bg-desktop.png'
import RoundedXL  from '../../assets/images/rounded-xl.png'      
import EllipseDes from '../../assets/images/Ellipse-des.png'       
import Cloud3 from '../../assets/images/cloud-3.png'        
import BgWhiteDot from '../../assets/images/bg-white-dot.png'        
import Rect from '../../assets/images/rectangle.png'        
import DotsWhite from '../../assets/images/dots-white.png'        
import BgCircle from '../../assets/images/bg-circle.png'        
import HalfScreen from '../../assets/images/half-screen.png'
import Ellipse13 from '../../assets/images/Ellipse 13.png'       
        
import Ellipse11 from '../../assets/images/Ellipse 11.png' 
import Ellipse10 from '../../assets/images/Ellipse 10.png' 
import Ellipse12 from '../../assets/images/Ellipse 12.png' 
import Ellipse8 from '../../assets/images/Ellipse 8.png' 
import HalfCircle from '../../assets/images/half-circle.png' 
import Ellipse144 from '../../assets/images/Ellipse-144.png'
import Ellipse28 from '../../assets/images/Ellipse 28.png'    
import Cloud2 from '../../assets/images/cloud-2.png'   
import WhiteBg from '../../assets/images/white-bg.png'
import Layer12 from '../../assets/images/Layer-1-2.png'
import GreenPoint from '../../assets/images/green-point.png'


function HowItWorks() {
    const { t, i18n } = useTranslation();
    return (
    <div id="how-it-works">
        <section className="animated-scroll hidden lg:flex flex-col items-center justify-center w-full pt-[6rem] bg-black active">
            <div className=" px-[20px] flex flex-col  justify-center items-center text-white relative">
                <h2 className="text-4xl">{t("index.how_it_works_question")}</h2>
                <p className="text-sm text-center pt-[10px]" style={{letterSpacing:'-0.03em'}}>
                    {t("index.powerful_tool_1")}
                    <br />
                    {t("index.powerful_tool_2")}
                </p>
                <div className="p-[60px] px-[20px] w-[45rem] z-10">
                    <img src={VideoBgDesktop} alt="" srcSet="" />
                </div>
                <div className="absolute top-[35px] -right-[68px] w-[15rem] z-0">
                    <img src={RoundedXL} alt=""/>
                </div>
                <div className="absolute top-[15px] -left-[115px] w-[22rem] z-0">
                    <img src={EllipseDes} alt=""/>
                </div>
                <div className="absolute top-[20px] -left-[115px] w-[10rem] z-10">
                    <img src={Cloud3} alt=""/>
                </div>
                <div className="absolute top-[185px] -left-[30px] w-[1rem] z-10">
                    <img src={BgWhiteDot} alt=""/>
                </div>
                <div className="absolute top-[65px] left-[85px] w-[3rem] z-10">
                    <img src={Rect} alt=""/>
                </div>
                <div className="absolute bottom-[30px] -left-[25px] w-[15rem] z-0">
                    <img src={DotsWhite} alt=""/>
                </div>
                <div className="absolute bottom-[10px] -right-[132px] w-[15rem] z-0">
                    <img src={BgCircle} alt=""/>
                </div>
                <div className="absolute bottom-[150px] -left-[30px]  w-[910px] z-0">
                    <img src={HalfScreen} alt=""/>
                </div>
                <div className="absolute bottom-[188px] left-[10px]  w-[1rem] z-0">
                    <img src={Ellipse13} alt=""/>
                </div>
            </div>
        </section>
        <section className="bg-black pt-10 relative w-full h-[840px] lg:hidden h-tw overflow-hidden" >
            <div className="absolute w-8 top-[37px] left-[34px] rounded-full">
                <img src={Ellipse11} alt="" />
            </div>
            <div className="absolute w-20 top-[12px] left-[10px] rounded-full">
                <img src={Ellipse10} alt=""/>
            </div>
            <div className="absolute w-40 -top-[12px] -left-[4px] rounded-full">
                <img src={Ellipse12} alt="" />
            </div>
            <div className="absolute w-64 h-[812px] -top-[12px] -left-[10px] rounded-full">
                <img src={Ellipse8} alt="" />
            </div>
            <div className="absolute bottom-[3.5rem] left-[3.5rem]  w-[1rem]">
                <img src={Ellipse13} alt=""/>
            </div>
            <div className="absolute bottom-[5rem] left-[50px]  w-[3rem]" style={{transform: 'rotate(-109deg)'}}>
            <img className="cursor-pointer hover:scale-110 hover:transition-transform hover:ease-in-out" src={HalfCircle} alt=""/>
            </div>
            <div className="absolute w-72 top-[60px]" style={{borderRadius: '50%', transform: 'rotate(-75deg)', right: '-100px'}}>
            <img src={Ellipse144} alt="" />
            <div className="absolute w-40  top-[96px] right-[90px] rounded-full ">
                <img src={Ellipse28} alt=""  />
            </div>
            </div>
            <div className="absolute w-40  top-[72px] right-0">
                <img src={Cloud2} alt="" />
            </div>
            <div className="absolute z-0" style={{bottom: '-60px', right: '-60px'}}>
                <img src={BgCircle} alt="" style={{width: '150px'}}/>
            </div>
            <div className="absolute z-0" style={{bottom: '75px', right: '15px'}}>>
                <img src={DotsWhite} alt=""/>
            </div>
            <div className="pt-[220px] mt-[14rem] px-[20px] flex flex-col  justify-center items-center text-white how-it-works">
                <h2 className="cursor-scale  text-[1.875rem] " style={{fontSize:'2.5rem !important'}}>{t("index.how_it_works_question")}</h2>
                <p className="text-sm text-center pt-[10px] cursor-scale  text-[1.1rem]" style={{lettetSpacing: '-0.03em'}}>
                    {t("index.powerful_tool_1")}
                    <br />
                    {t("index.powerful_tool_2")}
                </p>
                <div className="flex w-full justify-center p-[60px]">
                    <img className="" src={WhiteBg} alt="" />
                </div>
            </div>
            <div className="flex absolute -bottom-[70px] right-[14px] w-28 dots-white">
                <img src={DotsWhite} alt=""/>
            </div>
            <div className="flex absolute -bottom-[160px] right-0 w-28 layer-1-2">
                <img src={Layer12} alt=""/>
            </div>
            {/* <div className="flex absolute -bottom-[56px] -left-[11px] w-28" style={{transform: 'rotate(-120deg)'}}>
            <img src={HalfCircle} alt=""/>
            </div>
            <div className="flex absolute -bottom-[104px] left-[48px] w-28">
                <img src={GreenPoint} alt=""/>
            </div>
            */}
        </section>
    </div>
  )
}

export default HowItWorks
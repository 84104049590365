import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { api_Guest, api_Check, api_CheckTokenRegister, api_TokenRegister } from './lib/Axios'

import loadingGif from '../assets/images/loading.gif';

import Logo from "../assets/images/logo.svg";
import HeroImg from '../assets/images/frame-110.png'
import HeroImgMob from '../assets/images/Frame-97.png'
import Cloud from '../assets/images/cloud-3.png'
const ActiveAccountStep1 = () => {
    const [isload, setIsLoad] = useState(null);
    var AxiosWork = false;
    let register_obj = { fullname: '', email: '', password: '', repassword: '', username: '', link: '' }; let notFilled = '';
    let gen_username = true;
    register_obj.link = useParams().linkcode;

    // -- Axios Works -- 
    const guestToken = async () => {
        let response = await api_Check();
        if( response.status == 500 || response.status == 401 ) { 
            response = await api_Guest();
            checkDemoRegisterLink();
        } else { checkDemoRegisterLink(); }
    }
    const checkDemoRegisterLink = async () => {
        let response = await api_CheckTokenRegister({ link: register_obj.link });
        console.log(response);
        if( response.status == 200 ) {
            setIsLoad(true);
        } else { window.location.href = "/404"; }
    }

    useEffect(() => {
        
        if (isload == null) { guestToken(); }
    });
    if (isload == null) { 
        return(
            <div className="flex flex-col w-full h-screen justify-center items-center">
                <svg className="scale-150" width="238" height="48" viewBox="0 0 119 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_73_590)">
                        <path d="M33.7737 11.3913C33.5575 10.6759 33.1614 10.0272 32.6229 9.50676C32.0216 8.9631 31.3015 8.56623 30.5195 8.34759C29.4797 8.0593 28.4031 7.92425 27.3239 7.94675C26.8041 7.94675 26.3163 7.96818 25.8606 8.01106L26.9769 11.0399C27.0265 11.0399 27.0775 11.0399 27.1271 11.0399C27.9474 11.0399 28.6015 11.179 29.0892 11.4572C29.576 11.7339 29.8194 12.1212 29.8194 12.6148C29.8194 13.1518 29.6346 13.5377 29.268 13.774C28.9014 14.0103 28.1863 14.2018 27.1271 14.3454C26.0679 14.4889 25.0884 14.6445 24.1899 14.824C23.3769 14.9691 22.5933 15.246 21.8702 15.6436C21.2306 16.0041 20.7031 16.533 20.3453 17.1722C19.9787 17.8214 19.7949 18.6919 19.7939 19.7837C19.7939 21.0192 20.2211 22.0233 21.0755 22.7961C21.9298 23.5688 23.2765 23.9547 25.1154 23.9537C26.0886 23.9726 27.0567 23.8103 27.97 23.4751C28.7192 23.2038 29.396 22.7653 29.9486 22.1933C29.9821 22.655 30.0693 23.1112 30.2085 23.5529H34.1343V13.94C34.1456 13.0771 34.024 12.2176 33.7737 11.3913ZM29.881 17.772C29.8831 18.2177 29.7775 18.6573 29.573 19.0538C29.37 19.4498 29.0949 19.8048 28.7617 20.1008C28.4085 20.4094 28.001 20.6502 27.5598 20.8113C27.0791 20.9884 26.57 21.0771 26.0574 21.073C24.6942 21.073 24.0132 20.5066 24.0142 19.3739C24.0142 18.7158 24.241 18.2112 24.6947 17.8602C25.1485 17.5093 25.9813 17.2226 27.1932 17.0002C27.7942 16.8964 28.3894 16.7621 28.9766 16.5979C29.325 16.508 29.6475 16.3385 29.9186 16.1028L29.881 17.772Z" fill="#001428"></path>
                        <path d="M36.2886 23.4076V8.19785H40.2474V10.0211C40.5412 9.56357 40.9081 9.15687 41.3336 8.81707C41.6893 8.54164 42.0823 8.31756 42.5009 8.15148C42.8913 7.99796 43.3006 7.89735 43.7179 7.85234C44.1285 7.81146 44.5502 7.79102 44.9829 7.79102H45.5343V11.9057C45.1482 11.8444 44.7579 11.8134 44.3669 11.813C41.7938 11.813 40.5068 13.0389 40.5058 15.4909V23.4076H36.2886Z" fill="#001428"></path>
                        <path d="M56.3079 13.2266H60.2336V17.6299C60.2366 18.4242 60.1103 19.2137 59.8595 19.9677C59.617 20.7037 59.219 21.3795 58.6922 21.9495C58.1313 22.548 57.4443 23.0154 56.6805 23.3181C55.7551 23.6743 54.7683 23.8455 53.7764 23.8221C52.6095 23.8221 51.6149 23.6541 50.7926 23.3181C50.0264 23.0212 49.3343 22.5619 48.7644 21.972C48.2349 21.4067 47.8414 20.729 47.6136 19.9902C47.3719 19.2154 47.2513 18.4083 47.256 17.597V3.75732H51.4086V7.94528H60.2336V10.8395H51.4086V17.1947C51.4086 18.1799 51.5979 18.9402 51.9765 19.4756C52.3551 20.0111 52.9771 20.2748 53.8425 20.2669C54.6858 20.2669 55.3073 20.0046 55.7069 19.4801C56.1066 18.9556 56.3069 18.2113 56.3079 17.247V13.2266Z" fill="#001428"></path>
                        <path d="M66.1415 16.8508C66.2056 18.0034 66.5561 18.9198 67.1931 19.5999C67.8301 20.2799 68.6695 20.6199 69.7111 20.6199C70.3511 20.6329 70.9832 20.4782 71.5441 20.1712C71.7908 20.0429 72.0086 19.8658 72.1843 19.6508C72.3599 19.4358 72.4897 19.1874 72.5657 18.9208H76.9136C76.4128 20.4853 75.5474 21.6903 74.3175 22.5359C73.0875 23.3815 71.6172 23.8033 69.9064 23.8013C64.585 23.8013 61.9248 21.0412 61.9258 15.5211C61.9102 14.4387 62.0851 13.362 62.4426 12.3397C62.7636 11.4291 63.2758 10.597 63.945 9.89874C64.6242 9.20594 65.45 8.67251 66.3623 8.33723C67.4126 7.96091 68.5229 7.77796 69.639 7.79728C72.1049 7.79728 73.9704 8.54912 75.2354 10.0528C76.5004 11.5565 77.1329 13.8225 77.1329 16.8508H66.1415ZM72.791 14.317C72.783 13.8132 72.6811 13.3151 72.4906 12.8483C72.324 12.4523 72.0757 12.0956 71.7619 11.8013C71.4626 11.5259 71.1091 11.3156 70.7237 11.1835C70.3371 11.0507 69.9309 10.983 69.5218 10.9831C69.1164 10.978 68.714 11.0532 68.338 11.2041C67.962 11.3551 67.6198 11.5789 67.3314 11.8626C66.7154 12.4509 66.3648 13.27 66.2797 14.32L72.791 14.317Z" fill="#001428"></path>
                        <path d="M88.0731 23.457L81.2913 12.9497V23.457H78.2114V7.81494H81.721L88.031 17.8735V7.81494H91.135V23.457H88.0731Z" fill="#00CC66"></path>
                        <path d="M96.0404 16.8505V23.457H92.6465V7.78955H104.197V10.5267H96.0404V14.0894H103.171V16.8505H96.0404Z" fill="#00CC66"></path>
                        <path d="M118.8 18.077C118.638 18.925 118.35 19.7442 117.945 20.5075C117.569 21.212 117.069 21.8434 116.468 22.3711C115.862 22.8968 115.162 23.3051 114.405 23.5752C113.574 23.8684 112.698 24.0142 111.817 24.0059C110.804 24.0138 109.799 23.838 108.849 23.4869C107.949 23.1523 107.135 22.6195 106.47 21.9284C105.774 21.1919 105.237 20.3208 104.894 19.3692C104.491 18.2293 104.296 17.0267 104.32 15.8185C104.303 14.6067 104.481 13.4 104.846 12.2437C105.155 11.2531 105.667 10.3366 106.348 9.55146C107.004 8.81775 107.818 8.24144 108.729 7.86581C109.724 7.46081 110.791 7.25987 111.866 7.27501C112.8 7.25981 113.729 7.4045 114.613 7.70278C115.39 7.97048 116.11 8.38036 116.736 8.9113C117.322 9.41395 117.804 10.0269 118.152 10.7151C118.502 11.409 118.721 12.1608 118.798 12.9332H115.406C115.224 12.104 114.757 11.3638 114.087 10.8393C113.46 10.3504 112.686 10.0869 111.89 10.0914C110.474 10.0914 109.432 10.583 108.764 11.5662C108.096 12.5493 107.762 13.904 107.763 15.63C107.763 17.376 108.093 18.7326 108.752 19.6998C109.411 20.667 110.4 21.1506 111.719 21.1506C112.729 21.1506 113.538 20.8869 114.148 20.3594C114.758 19.8319 115.178 19.0731 115.406 18.0829L118.8 18.077Z" fill="#00CC66"></path>
                        <path d="M14.1751 15.343C13.9974 16.2098 13.5855 17.0118 12.9837 17.6627C12.382 18.3136 11.6132 18.7887 10.7603 19.0368C9.90738 19.2849 9.00262 19.2965 8.14353 19.0705C7.28444 18.8445 6.50358 18.3894 5.8851 17.7542C5.26663 17.1191 4.83398 16.3279 4.63378 15.466C4.43358 14.6041 4.47342 13.7042 4.74901 12.8632C5.0246 12.0222 5.52549 11.272 6.1977 10.6935C6.86992 10.1149 7.68797 9.72998 8.5637 9.58009V5.05859C6.79038 5.21482 5.09821 5.86969 3.68449 6.94686C2.27077 8.02402 1.19376 9.47908 0.579033 11.1424C-0.035693 12.8057 -0.162803 14.6087 0.212525 16.3412C0.587854 18.0737 1.45015 19.6642 2.69888 20.9274C3.94761 22.1906 5.53131 23.0744 7.26533 23.4757C8.99934 23.877 10.8122 23.7793 12.4926 23.1939C14.1729 22.6086 15.6515 21.5598 16.756 20.1697C17.8604 18.7797 18.5451 17.1057 18.7304 15.343H14.1751Z" fill="#001428"></path>
                        <path d="M17.0702 12.8752H14.5432L14.5191 12.6778C14.4241 11.9491 14.1163 11.2642 13.634 10.708C13.1517 10.1518 12.516 9.74874 11.8058 9.54877L11.6436 9.49791V6.99561L11.911 7.04646C14.7235 7.57294 16.8268 9.87034 17.0522 12.6389L17.0702 12.8752Z" fill="#001428"></path>
                        <path d="M20.4626 12.875H17.7147V12.8002L17.7057 12.6701C17.6043 11.0886 16.9668 9.58808 15.8976 8.41407C14.8283 7.24006 13.3905 6.46202 11.8193 6.2072L11.6436 6.17729V3.42969L11.9065 3.46409C14.1854 3.75271 16.2899 4.8298 17.8518 6.50697C19.4137 8.18413 20.3336 10.3546 20.4506 12.6387L20.4626 12.875Z" fill="#001428"></path>
                        <path d="M23.888 12.875H21.1492L21.1387 12.6641C20.9058 7.58918 16.9064 3.33391 11.8359 2.76555L11.6436 2.74461V0L11.8975 0.0239317C15.09 0.328652 18.0637 1.77436 20.2681 4.09353C22.4725 6.4127 23.7585 9.44833 23.888 12.6387V12.875Z" fill="#001428"></path>
                    </g>
                    <defs><clipPath id="clip0_73_590"><rect width="118.8" height="24" fill="white"></rect></clipPath></defs>
                </svg>
                <img src={loadingGif} />
            </div>
        );
    }
    // -- Events --
    function Change_Input_FullName(e) { 
        register_obj.fullname = e.currentTarget.value; InputClearError(e.currentTarget.id);
        if (gen_username) { 
            document.querySelector('.js_demo_username').value = register_obj.fullname.replace(/ /g,'.');
            Change_Input_Username_2(register_obj.fullname.replace(/ /g,'.'), 'inp_username');
        }
    }
    function Change_Input_Email(e) { register_obj.email = e.currentTarget.value; InputClearError(e.currentTarget.id); }
    function Change_Input_Password(e) { register_obj.password = e.currentTarget.value; InputClearError(e.currentTarget.id); }
    function Change_Input_RePassword(e) { register_obj.repassword = e.currentTarget.value; InputClearError(e.currentTarget.id); }
    function Change_Input_Username(e) { gen_username = false; Change_Input_Username_2(e.currentTarget.value, e.currentTarget.id); }
    function Change_Input_Username_2(eVal, eId) { register_obj.username = eVal; InputClearError(eId); }
    const Click_RegisterButton = async () => {
        notFilled = '';
        if (register_obj.fullname == '') { InputWasWrong('inp_fullname'); }
        else if (register_obj.fullname.length < 2 || register_obj.fullname.length > 30) { InputWasWrong('inp_fullname', 'Full Name must be between 2 and 30 characters long.'); }
        if (register_obj.email == '') { InputWasWrong('inp_email'); }
        else if (register_obj.email.indexOf('@') == -1) { InputWasWrong('inp_email', 'Email must be contains @'); }
        else if (register_obj.email.length < 7 || register_obj.email.length > 50) { InputWasWrong('inp_email', 'Email must be between 6 and 50 characters long.'); }
        if (register_obj.password == '') { InputWasWrong('inp_pass');}
        else if (register_obj.password.length < 6 || register_obj.password.length > 24) { InputWasWrong('inp_pass', 'Password must be between 6 and 24 characters long.'); }
        if (register_obj.repassword == '') { InputWasWrong('inp_repass');}
        else if (register_obj.repassword.length < 6 || register_obj.repassword.length > 24) { InputWasWrong('inp_repass', 'Password must be between 6 and 24 characters long.'); }
        else if (register_obj.repassword != register_obj.password) { InputWasWrong('inp_repass', 'Passwords do not match.'); }
        if (register_obj.username == '') { InputWasWrong('inp_username'); }
        else if (register_obj.username.length < 2 || register_obj.username.length > 24) { InputWasWrong('inp_username', 'Username must be between 2 and 24 characters long.'); }

        if (notFilled != '') { document.querySelector('#'+notFilled).scrollIntoView(); }

        if (AxiosWork) { return; } else { document.querySelector('.js_registerlink').classList.add('opacity-50'); AxiosWork = true; }
        let response = await api_TokenRegister({register_obj: register_obj});
        AxiosWork = false; document.querySelector('.js_registerlink').classList.remove('opacity-50');

        if( response.status == 200 ) {
            localStorage.setItem('token', response.data);
            localStorage.setItem('islogged', true);
            window.location.href = "/profile";
        } else {
            if (response.status == 400) {
                console.log(response.data);
                if (typeof response.data.fullname !== 'undefined') { InputWasWrong('inp_fullname', response.data.fullname); }
                if (typeof response.data.email !== 'undefined') { InputWasWrong('inp_email', response.data.email); }
                if (typeof response.data.pass !== 'undefined') { InputWasWrong('inp_pass', response.data.pass); }
                if (typeof response.data.repass !== 'undefined') { InputWasWrong('inp_repass', response.data.repass); }
                if (typeof response.data.username !== 'undefined') { InputWasWrong('inp_username', response.data.username); }
            } else if (response.status == 401) {
                window.location.href = "/";
            } else if (response.status == 404 || response.status == 500) {
                window.location.href = "/";
            }
        }
    }
    function InputWasWrong(dom_id, error_text = '') { 
        if (document.querySelector('#'+dom_id) != null) { 
            notFilled = dom_id; document.querySelector('#'+dom_id).style.border = '1px solid #f56565';
        }
        if (error_text != '') { document.querySelector('.'+dom_id+'_error').innerHTML = error_text; document.querySelector('.'+dom_id+'_error').classList.remove('hidden'); }
    }
    function InputClearError(dom_id) {
        if (document.querySelector('#'+dom_id) != null) { document.querySelector('#'+dom_id).style.border = null; }
        document.querySelectorAll('.'+dom_id+'_error').forEach(function(el){el.classList.add('hidden');});
    }
    
    return (
    <section className='flex flex-col lg:flex-row w-full min-h-screen font-primary overflow-x-hidden'>
        <div className="block lg:hidden w-full ml-10 my-2" >
            <a className="cursor-pointer" href="/"><img src={Logo} alt='nfc-logo'/></a>
        </div>

        <div className='w-full lg:mt-[3rem] flex items-center justify-center lg:hidden'>
            <div className='flex w-full bg-[#F5F5F5] m-[1rem] justify-center items-center relative' style={{borderRadius:'60px'}}>
                <div className='absolute -top-[1rem] -left-[6rem] w-[10rem]'><img src={Cloud} alt=''/></div>
                <div className='flex w-full justify-center'>
                    <img className="w-full" src={HeroImg} alt='' style={{maxWidth: '504px'}}/>
                </div>
            </div>
        </div>

        <div className="flex flex-row w-full">
            <div className="w-full lg:w-1/2 flex flex-col justify-center gap-[2rem] mx-6 sm:mx-20 md:mx-40 lg:mx-8 2xl:mx-40 xl:gap-[5rem]">
                <nav className="hidden lg:block absolute top-8 left-8" style={{ width: '178px' }}>
                    <a className="cursor-pointer" href="/"><img src={Logo} alt='nfc-logo'/></a>
                </nav>
                <div className='flex flex-col w-full xl:mt-[1rem]'>
                    <div className='flex flex-col gap-4 m_lg:items-center m_lg:pt-2 '>
                        <h1 className='text-[1.8rem] sm:text-[2.3rem] font-[600] 2xl:text-[3.4rem]'>Demo Register</h1>
                    </div>
                    <div className='flex flex-col gap-4 w-full mt-4'>
                        <div className="flex justify-between">
                            <span className='text-[#ccc]'>Full Name</span>
                        </div>
                        <input type="text" id="inp_fullname" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input" 
                            onChange={(e) => Change_Input_FullName(e)} placeholder=""/>
                        <span className="text-red-400 w-full text-sm hidden inp_fullname_error"></span>
                    </div>
                    <div className='flex flex-col gap-4 w-full mt-4'>
                        <div className="flex justify-between">
                            <span className='text-[#ccc]'>Email</span>
                        </div>
                        <input type="text" id="inp_email" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input" 
                            onChange={(e) => Change_Input_Email(e)} placeholder=""/>
                        <span className="text-red-400 w-full text-sm hidden inp_email_error"></span>
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-10">
                        <div className='flex flex-col gap-4 w-full mt-4'>
                            <span className='text-[#ccc]'>Password</span>
                            <input type="password" id="inp_pass" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input" 
                                onChange={(e) => Change_Input_Password(e)} onKeyPress={event => { if (event.key === 'Enter') { Click_RegisterButton() } }}  placeholder=""/>
                            <span className="text-red-400 w-full text-sm hidden inp_pass_error"></span>
                        </div>
                        <div className='flex flex-col gap-4 w-full mt-4'>
                            <span className='text-[#ccc]'>Re-Password</span>
                            <input type="password" id="inp_repass" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input" 
                                onChange={(e) => Change_Input_RePassword(e)} onKeyPress={event => { if (event.key === 'Enter') { Click_RegisterButton() } }}  placeholder=""/>
                            <span className="text-red-400 w-full text-sm hidden inp_repass_error"></span>
                        </div>
                    </div>
                    {/*<div className='flex flex-col gap-2 w-full my-[2rem]'>
                        <div className="flex justify-between">
                            <span className='text-[#ccc]'>Username Link</span>
                        </div>
                        <input type="text" id="inp_username" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input js_demo_username" 
                            onChange={(e) => Change_Input_Username(e)} placeholder=""/>
                        <span className="pl-4 text-sm" style={{ textDecoration: 'none' }}>https://cartenfc.ma/<span className="text-green_1 js_demoregister_username_span"></span></span>
                         
                        <span className="text-red-400 w-full text-sm hidden inp_username_error"></span>
                    </div>*/}
                    <div className='flex flex-col gap-2 w-full my-[2rem]'>
                        <div className="flex justify-between">
                            <span className='text-[#ccc]'>Username Link</span>
                        </div>

                        <div className="flex w-full">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">https://cartenfc.ma/</span>
                            <input type="text" id="inp_username" className="input-styles w-full profile-inputs font-semibold py-4 disabled:bg-gray-200 semi_hero_input js_demo_username" 
                            onChange={(e) => Change_Input_Username(e)} placeholder="" style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0'}}/>
                        </div>

                        <span className="text-red-400 w-full text-sm hidden inp_username_error"></span>
                    </div>
                    <button className='primary-btn text-white mt-[1rem] py-3 js_registerlink m_lg:mb-10' onClick={(e) => Click_RegisterButton()}
                        style={{ background: 'linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)', boxShadow: '4px 16px 32px rgb(35 180 107 / 35%)', borderRadius: '40px' }}>
                        Register
                    </button>
                </div>
            </div>
            <div className='hidden w-1/2 m-[1rem] lg:flex items-center relative' style={{borderRadius:'60px'}}>
                <div className='absolute -top-[1rem] -left-[6rem] w-[14rem]'><img src={Cloud} alt=''/></div>
                <div className='w-full'>
                    <div className='w-full bg-[#F5F5F5]' style={{borderRadius:'60px'}}><img src={HeroImg} alt='' /></div>
                </div>
            </div>
        </div>
    </section>
    )

}
export default ActiveAccountStep1;
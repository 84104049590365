import axios from "axios";

const $Host = axios.create({ baseURL: process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/' });
const $tokenHost = axios.create({ baseURL: process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/' });
//const $Host = axios.create({ baseURL: 'http://' + window.location.host.replace(':3000','') + ':5000/' });
//const $tokenHost = axios.create({ baseURL: 'http://' + window.location.host.replace(':3000','') + ':5000/' });

//Host without token setted automaticaly
//Set host connection with token for auth requests
const tokenInterceptor = config => {
	if (localStorage.getItem('token') != null) { config.headers.authorization = `Bearer ${localStorage.getItem('token')}`; }
    return config;
}
$tokenHost.interceptors.request.use(tokenInterceptor);

//Get all users
/*export const api_getUsers = async () => {
	const response = await $Host.get('api/user');
	return response;
}
//Get One user
export const api_getOneUsers = async (id) => {
	const response = await $Host.get('api/user/'+ id);
	return response;
}
//Add New User with his settings
export const api_newUser = async (user_obj) => {
	try { return await $tokenHost.post('api/user', user_obj); }
	catch (err) { return err.response; }
}*/
//Get all products
export const api_getProducts = async () => {
	try { return await $Host.get('api/products'); }
	catch (err) { return err.response; }
}
//Get products by type
export const api_getTypeProducts = async (productType) => {
	try { return await $Host.get('api/typeproducts/'+productType); }
	catch (err) { return err.response; }
}
//Get products mocks imgs
export const api_getProductMocks = async (productId) => {
	try { return await $Host.get('api/getproductmockimgs/'+productId); }
	catch (err) { return err.response; }
}
//Get Product Template
export const api_getProductTemplate = async (productId) => {
	try { return await $Host.get('api/tproduct/'+productId); }
	catch (err) { return err.response; }
}
//Get All Products Template
export const api_getAllTemplates = async (productId) => {
	try { return await $Host.get('api/alltemplates'); }
	catch (err) { return err.response; }
}
//Get All Banks
export const api_getBanks = async () => {
	try { return await $Host.get('api/allbanks'); }
	catch (err) { return err.response; }
}
//Save current template
export const api_saveTemplate = async (template_obj) => {
	try { return await $Host.post('api/savecurrtemplate', template_obj); }
	catch (err) { return err.response; }
}
//Load template
export const api_loadTemplate = async (template_obj) => {
	try { return await $Host.post('api/loadtemplate', template_obj); }
	catch (err) { return err.response; }
}
//Check Coupon
export const api_CouponCheck = async (coupon) => {
	try { return await $tokenHost.post('api/coupon', coupon); }
	catch (err) { return err.response; }
}

//Add New UserOrder
export const api_NewUserOrder = async (user_obj) => {
	//try { return await $tokenHost.post('api/neworder', user_obj); }
	try {
		let formData = new FormData();
		if (user_obj.screenshot != '') { formData.append("image", user_obj.screenshot, 'screenshot'+user_obj.screenshot.name.substr(user_obj.screenshot.name.lastIndexOf('.'))); }
		formData.append("document", JSON.stringify(user_obj));
		console.log(formData);
		return await $tokenHost.post('api/neworder', formData, {headers: {'Content-Type': 'multipart/form-data'}});
	} catch (err) { return err.response; }
}

//Check Order
export const api_OrderCheck = async (orderId) => {
	try { return await $tokenHost.post('api/order', orderId); }
	catch (err) { return err.response; }
}
//Check Code by Email
export const api_SendCode = async (email) => {
	try { return await $tokenHost.post('api/sendcode', email); }
	catch (err) { return err.response; }
}
//Check Code by Code
export const api_CheckCode = async (code) => {
	try { return await $tokenHost.post('api/checkcode', code); }
	catch (err) { return err.response; }
}
//Set password
export const api_SetPassword = async (pass_code) => {
	try { return await $tokenHost.post('api/setpassword', pass_code); }
	catch (err) { return err.response; }
}
//Send Recover Code by Email
export const api_SendRecoverCode = async (email) => {
	try { return await $tokenHost.post('api/sendrecovercode', email); }
	catch (err) { return err.response; }
}
//Check Recover Code by Code
export const api_CheckRecoverCode = async (code) => {
	try { return await $tokenHost.post('api/checkrecovercode', code); }
	catch (err) { return err.response; }
}
//Set recover password
export const api_SetRecoverPassword = async (pass_code) => {
	try { return await $tokenHost.post('api/setrecoverpassword', pass_code); }
	catch (err) { return err.response; }
}
//Get Guest Token
export const api_Guest = async (id) => {
	const response = await $tokenHost.get('api/guest');
	if (response.status == 200) { localStorage.setItem('token', response.data); }
	return response;
}
//Check Guest Token
export const api_Check = async (id) => {
	try { return await $tokenHost.get('api/check'); }
	catch (err) { return err.response; }
}
//Authorization (Log In)
export const api_LogIn = async (email_password) => {
	try { return await $tokenHost.post('api/login', email_password); }
	catch (err) { return err.response; }
}
//Check Log In status
export const api_CheckLogIn = async () => {
	try { return await $tokenHost.get('api/checklogin'); }
	catch (err) { return err.response; }
}
//Get User Social
export const api_GetSocial = async () => {
	try { return await $tokenHost.get('api/getsocial'); }
	catch (err) { return err.response; }
}
//Set User Social
export const api_SetSocial = async (social_obj) => {
	try { return await $tokenHost.post('api/setsocial', social_obj); }
	catch (err) { return err.response; }
}
//Get User ProfileInfo
export const api_GetProfile = async () => {
	try { return await $tokenHost.get('api/getprofile'); }
	catch (err) { return err.response; }
}
//Set User ProfileInfo
export const api_SetProfile = async (profile_obj) => {
	//try { return await $tokenHost.post('api/setprofile', social_obj); }
	try {
		let formData = new FormData(); console.log(profile_obj);
		if (profile_obj.avatar != '') { formData.append("image", profile_obj.avatar, 'avatar'+profile_obj.avatar.name.substr(profile_obj.avatar.name.lastIndexOf('.'))); }
		if (profile_obj.companyimg != '') { formData.append("image", profile_obj.companyimg, 'company'+profile_obj.companyimg.name.substr(profile_obj.companyimg.name.lastIndexOf('.'))); }
		formData.append("document", JSON.stringify(profile_obj));
		console.log(formData);
		return await $tokenHost.post('api/setprofile', formData, {headers: {'Content-Type': 'multipart/form-data'}});
	} catch (err) { return err.response; }
}
//Set User ProfileInfo
export const api_SetAccount = async (account_obj) => {
	try { return await $tokenHost.post('api/setaccount', account_obj); }
	catch (err) { return err.response; }
}
//Get Coupoun History
export const api_GetCouponHistory = async () => {
	try { return await $tokenHost.get('api/couponhistory'); }
	catch (err) { return err.response; }
}
//Get Withdraw History
export const api_GetWithdrawHistory = async () => {
	try { return await $tokenHost.get('api/withdrawhistory'); }
	catch (err) { return err.response; }
}
//Send Withdraw Request
export const api_WithdrawProcess = async () => {
	try { return await $tokenHost.post('api/withdrawprocess'); }
	catch (err) { return err.response; }
}
//Send Withdraw Request
export const api_GetPublicProfile = async (link_obj) => {
	try { return await $Host.post('api/getpublicprofile', link_obj); }
	catch (err) { return err.response; }
}
//Check token register
export const api_CheckTokenRegister = async (token_obj) => {
	try { return await $tokenHost.post('api/checktokenregister', token_obj); }
	catch (err) { return err.response; }
}
//Send token register
export const api_TokenRegister = async (register_obj) => {
	try { return await $tokenHost.post('api/tokenregister', register_obj); }
	catch (err) { return err.response; }
}



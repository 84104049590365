import { useEffect, useRef, useState } from "react"
import CompanyImgEditor from 'react-avatar-editor'
import { useTranslation } from "react-i18next";

export function CompanyImgModal({ close, modalCompanyImg, finishCrop }) {
    const { t, i18n } = useTranslation();
    const avtEditor = useRef(null);
    const [avtScale, setAvtScale] = useState(1);
    const [avtSize, setAvtSize] = useState(350);

    function Scroll_ScaleImg(e) {
        let onePercent = (parseFloat(avtScale)/100)*5;
        if (e.nativeEvent.wheelDelta > 0) {
            if ((avtScale+onePercent) >= 10) { setAvtScale(10); }
            else { setAvtScale(parseFloat(avtScale)+onePercent); }  
        } else {
            if ((avtScale-onePercent) <= 1) { setAvtScale(1); }
            else { setAvtScale(parseFloat(avtScale)-onePercent); }  
        }
        document.querySelector('.js_modalAvtScaleInput').value = avtScale;
    }
    function Change_ScaleImg(e) {
        setAvtScale(e.currentTarget.value);
    }
    function preStepCrop(e) {
        const canvas = avtEditor.current.getImage();
        finishCrop('companyimg', canvas);
        close();
    }

    window.addEventListener('resize', page_resize);
    function page_resize() {
        let w = window.innerWidth;
        console.log(w);
        if (w < 410) { setAvtSize(w-50-10); } else { setAvtSize(350); }
    }
    useEffect(() => { page_resize(); });

    return (
        <div className="relative">
            <div className="flex justify-between items-start p-4 rounded-t">
                <button type="button" className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" style={{ outline: 'none' }}
                    onClick={(e) => close()} data-modal-toggle="defaultModal">
                    <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="flex flex-col justify-center py-6 2sm:p-6">
                <CompanyImgEditor
                    ref={avtEditor}
                    image= {modalCompanyImg}
                    width={avtSize}
                    height={avtSize}
                    borderRadius = {200}
                    scale={avtScale}

                    color={[255, 255, 255, 0.6]} // RGBA
                    rotate={0}
                    onWheel ={(e) => Scroll_ScaleImg(e)}
                />
                <div className="w-embed pt-4">
                    <div className="slidecontainer">
                        <input 
                            className="js_modalAvtScaleInput range w-full" type="range" name="scale" 
                            min="1" max="10" step="0.01" defaultValue="1"
                            onChange={Change_ScaleImg}
                        />
                    </div>
                </div>
                <button className="min-w-[7.625rem] text-white mt-4" onClick={(e) => preStepCrop(e)}
                        style={{ padding: "0.875rem 1.875rem", background: "linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)", boxShadow: "4px 16px 32px rgba(35, 180, 107, 0.35)", borderRadius: "40px"}} >
                    {t("index.save")}
                </button>
            </div>
        </div>
    )
}

import React from 'react'
import { useTranslation } from "react-i18next";
import '../../assets/css/style.css'
import GroupeLeftBottom from '../../assets/images/groupe-left-bottom.png'
import BlackHandPhone from  '../../assets/images/black-hand-phone.png'
import WhiteHalfCircle from '../../assets/images/white-half-circle.png'
import Rectangle from '../../assets/images/rectangle.png'
import BgWhiteDot from '../../assets/images/bg-white-dot.png'
import CardAnimated from '../../assets/images/cards/card-animatede.png'
import Cloud2 from '../../assets/images/cloud-2.png'
import SneakArrow from '../../assets/images/sneak-arrow.png'
import BgCircle from '../../assets/images/bg-circle.png'    
import HalfBg from '../../assets/images/half-bg.png'   
import BgHalfCircle from '../../assets/images/bg-half-circle.png'    
import Ellipse13 from '../../assets/images/Ellipse 13.png'    
import Ellipse9 from '../../assets/images/Ellipse 9.png'
import HeroImg from '../../assets/images/hero-image.png'
import Cloud from '../../assets/images/cloud.png' 
import Untitled1 from '../../assets/images/Untitled-1-1.png'
import Ellipse15 from '../../assets/images/Ellipse-15.png' 
import Group891 from '../../assets/images/Group-891.png'
import BlackHandPhon from '../../assets/images/black-hand-phone.png'
import Untitled31 from '../../assets/images/Untitled-3-1.png'

import RoundedXl from '../../assets/images/rounded-xl.png'
import BlackHandXl from '../../assets/images/black_hand_card_xl.png'

function HeaderLandingSection() {
    const { t, i18n } = useTranslation();
    return (
    <>
        <div className="w-full lg:w-full mx-auto lg:mx-0 pb-[3em] md:pt-[1rem] lg:pt-[6rem] lg:flex lg:justify-center lg:relative xl:w-full" style={{fontFamily: 'Cormorant Garamond,serif'}}>
            <main className="flex items-center justify-end m_sm:pb-10 m_lg:px-10 lg:px-20 center-set lg:w-1/2" style={{height:'auto'}}>
                <div className="m_lg:text-center lg:text-left xl:text-left lg:pb-[3rem] lg:pt-[5rem] lg:pt-[1rem]">
                    <div className="flex justify-between mb-2 items-center w-4/5">
                        <p className="leading-none tracking-wider text-green-500 mb-4 cursor-scale small anim-text text-base"></p>
                        <img className="w-16" src={Ellipse9} alt="ellipse" style={{ transform: 'rotate(43deg)'}} />
                    </div>
                    <h2 id="main-heading" className="mb-4 tracking-tight leading-10 font-bold text-black m_sm:text-4xl sm:text-5xl m_md:leading-none md:text-6xl xl:text-8xl cursor-scale">
                        <div className="" style={{opacity:'1'}}>  
                            <span className="anim-text" style={{transform:'translate(0px, 0px)'}}>
                                <span id="home-text">{t("index.smart_safe_and_sustainable_1")}
                                    <span style={{background:'#000', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', backgroundClip: 'text'}}>,</span>
                                    <span id="home-text" className=""> {t("index.smart_safe_and_sustainable_2")}</span>
                                    <span style={{background:'#000', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', backgroundClip: 'text'}}> &amp;</span>
                                </span>
                            </span>
                            <span className="block  anim-text" id="text" style={{transform:'translate(0px, 0px)'}}> {t("index.smart_safe_and_sustainable_3")}</span>
                        </div>
                    </h2>
                    <div className="lg:w-5/6 flex align-items justfiy-center p-space lg:mt-[1rem] md:flex md:justify-center md:items-center" style={{fontFamily: 'Poppins,sans-serif'}}>
                        <p className="text-[#A4A4A4] m_md:text-base md:text-xl cursor-scale small" style={{ maxWidth: '850px'}}>
                            {t("index.first_impression_1")} <span className=" text-black font-bold">.<br />{t("index.first_impression_2")}</span> {t("index.first_impression_3")}
                        </p>
                    </div>
                    <div id="btn-primary" className="mt-[1.8rem] flex flex-col gap-[14px] lg:w-[25rem] sm:-ml-[.5rem] items-center m_xl:justify-center xl:items-start xl:justify-start btn-primary-space uppercase" style={{fontFamily: 'Poppins,sans-serif'}}>
                        <div className="lg:w-[14rem]">
                            <a href="#products" className="flex justify-center items-center  py-4 px-10 text-white rounded-[40px] text-center cursor-scale small" id="main-bn" style={{fontSize:'clamp(10px,13px,2rem)'}}>{t("index.get_yours_now")}</a>
                        </div>
                        <div className="lg:w-[14rem]">
                            <a href="#products" className="cursor-scale small flex justify-center items-center border-2 border-black text-black py-4 px-10 rounded-[40px] learn-more text-center uppercase" style={{
                            fontSize:'clamp(10px,13px,2rem)',maxHeight:'48px'}}>{t("index.learn_more")}</a>
                        </div>
                    </div>
                </div>
            </main>
            <section className="flex w-full justify-center lg:w-1/2 lg:justify-start lg:pr-20">
                <img className="block md:max-w-xl xl:max-w-2xl" src={BlackHandXl} alt="Hero Image" />
            </section>
            <img className="lg:block absolute left-0 -bottom-0 transform -translate-x-1/2 translate-y-1/2 -z-10 hidden" src={RoundedXl} alt="" />
        </div>
    </>
    )
}

export default HeaderLandingSection
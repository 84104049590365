import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { api_getAllTemplates, api_saveTemplate, api_loadTemplate } from '../lib/Axios';
import { LeftMenuTemplates, LeftMenuText, LeftMenuImages, LeftMenuColors, LeftMenuSaveLoad } from '../lib/Editor/EditorLeftMenu';
import { textAlignArray, TopMenu_DefaultSettings_withoutNav, TopMenu_TextSettings, TopMenu_ImageSettings, TopMenu_OnlyRightDefault } from '../lib/Editor/EditorTopMenu';
import { useTranslation } from "react-i18next";
import { AiOutlineSave } from "react-icons/ai";

import BackButtonImg from '../../assets/images/Arrow_left.png';
import NextButtonImg from '../../assets/images/Arrow_right.png';
import BlankTemplateImg from '../../assets/images/emptyTemplate.png';


import { fabric } from 'fabric';

export var canvas = null;

function ProductDesigner({}) {
    const { t, i18n } = useTranslation();
    //#2 Editor Variables
    let FrontTemplate = {"version":"5.2.4","objects":[
            {"type":"i-text","version":"5.2.4","originX":"left","originY":"top","left":95,"top":85,"width":190.26,"height":56.5,"fill":"#b8dcca","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":50,"text":"John Doe","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline"},
            {"type":"i-text","version":"5.2.4","originX":"left","originY":"top","left":105,"top":162,"width":99.99,"height":33.9,"fill":"#b8dcca","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":30,"text":"Founder","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline"},
            {"type":"i-text","version":"5.2.4","originX":"left","originY":"top","left":505,"top":100,"width":164.43,"height":45.2,"fill":"#b8dcca","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":40,"text":"CarteNFC","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline"},
            {"type":"i-text","version":"5.2.4","originX":"left","originY":"top","left":100,"top":390,"width":176.67,"height":22.6,"fill":"#b8dcca","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":20,"text":"johndoe@cartenfc.ma","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline"},
            {"type":"i-text","version":"5.2.4","originX":"left","originY":"top","left":100,"top":415,"width":140.85,"height":22.6,"fill":"#b8dcca","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":20,"text":"www.cartenfc.ma","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline"},
            {"type":"i-text","version":"5.2.4","originX":"left","originY":"top","left":505,"top":410,"width":163.31,"height":20.34,"fill":"#b8dcca","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":18,"text":"1178 Broadway #1021","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline"},
            {"type":"i-text","version":"5.2.4","originX":"left","originY":"top","left":13,"top":16,"width":83,"height":20.34,"fill":"#b8dcca","stroke":"#000000","strokeWidth":0,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":18,"text":"(FrontSide)","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline"}
        ],"background":"#329b67"}; 
    let gcolor = 'darkgreen';
    let allTemplates = []; let currTemplate = '';
    let canvase_width = 800, canvas_height = 500;
    let canvas_zoom = 100;
    let js_EditorLeftMenu = null; let js_EditorTopMenu = null;
    let needClearLeft = false; let leftMenuHidden = false;

    //Axios
    const getProductTemplate = async (productId) => {
        let response = await api_getAllTemplates(productId);
        console.log(response);
        if( response.status == 200 ) {
            FrontTemplate = JSON.parse(response.data[0].template);
            currTemplate = response.data[0].name; allTemplates = response.data;
            
            FrontTemplate.background = gcolor; canvas.loadFromJSON(FrontTemplate, canvas.renderAll.bind(canvas));
        } else if( response.status == 500 ) {}
    }

    //#2 --- Create Canvase for editor   ---   ---   ---   ---   ---
    useEffect(() => {

        //hack for scrolling for mobile
        (function(){
          var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
          fabric.util.object.extend(fabric.Canvas.prototype, { 
            _onTouchStart: function(e) { 
              var target = this.findTarget(e); 
              if (this.allowTouchScrolling && !target && !this.isDrawingMode) { return; } 
              defaultOnTouchStartHandler.call(this, e); 
            } 
          });
        })();

        js_EditorLeftMenu = ReactDOM.createRoot(document.querySelector('.js_EditorLeftMenu'));
        js_EditorTopMenu = ReactDOM.createRoot(document.querySelector('.js_EditorTopMenu'));
        canvas = new fabric.Canvas('canvas', {
            allowTouchScrolling: true,
            width: canvase_width, height: canvas_height, backgroundColor: gcolor
        });
        document.querySelector('.canvas-container').classList.add('m-auto');    
        page_resize();
        //Default template text
        getProductTemplate(1);
        if (window.innerWidth > 1024 ) { Click_SectionSelect({currentTarget: document.querySelector('.js_editor_LeftMenuTextSection')}, 'text'); }
        if (window.innerWidth < 450 ) { document.querySelector('.js_editor_changeSideFront').classList.add('hidden'); } 
    });
    //#2 --- Resize/Zoom function   ---   ---   ---   ---   ---
    window.addEventListener('resize', Resize_ChangeCanvasSize);
    function Resize_ChangeCanvasSize() { page_resize(); }
    function page_resize() {
        let w = window.innerWidth; let w_area = w - 400;
        if (w < 1024 || leftMenuHidden) { w_area += 360; }
        let h = window.innerHeight; let h_area = h - 183 - 76;

        let procdiff = ((w_area - (w_area*0.05*2))/canvase_width)*(canvas_zoom/100);
        let procdiff2 = ((h_area - (h_area*0.05*2))/canvas_height)*(canvas_zoom/100);
        procdiff = Math.min(procdiff, procdiff2);

        //if (w_area < (procdiff*canvase_width)) { document.querySelector('.js_editor_area').style.left = (w_area-(procdiff*canvase_width))/2 + 'px'; }
        //else { document.querySelector('.js_editor_area').style.left = null; }
        canvas.setZoom(procdiff); canvas.setDimensions({width:(canvase_width*procdiff), height:(canvas_height*procdiff)});
    }
    function Click_EditorZoom(e, new_zoom) {
        canvas_zoom += new_zoom;
        document.querySelector('.js_editor_zoom').innerHTML = canvas_zoom+'%'; page_resize();
    } 
    //#2 --- Select Events   ---   ---   ---   ---   ---
    useEffect(() => { 
        canvas.on({ 'selection:updated': CanvasElSelected, 'selection:created': CanvasElSelected });
        canvas.on({ 'selection:cleared': CanvasElCleared });
    });
    function CanvasElClear(e){
        if (e.currentTarget != e.target) { return; }
        canvas.discardActiveObject().renderAll();
    }
    function CanvasElSelected(obj){
        if(needClearLeft) { 
            needClearLeft = false; js_EditorLeftMenu.render(null);
            if (window.innerWidth < 1024 ) { document.querySelector('.js_EditorParentLeftMenu').style.width = null; }
        }
        if (obj.selected.length == 1) { 
            let temp_methods = {};
            let editor_obj = canvas.getActiveObject(); console.log(editor_obj.type);
            if (editor_obj.type == 'text' || editor_obj.type == 'i-text'){
                temp_methods = { Click_ChangeFont, Click_ShowLeftMenuColors, Click_ChangeFontSize, Click_ChangeFontWeight, Click_ChangeTextItalic, Click_ChangeTextUnderline, Click_ChangeTextAligh,
                    Click_ChangeFrontBackPosition, Click_ChangeOpacity, Click_DuplicateElement, Click_DeleteElements};
                js_EditorTopMenu.render( <TopMenu_TextSettings Editor_Obj={editor_obj} Parent_methods={temp_methods} />);
            } else if (editor_obj.type == 'image'){
                temp_methods = { Click_FlipImg, Click_ChangeFrontBackPosition, Click_ChangeOpacity, Click_DuplicateElement, Click_DeleteElements };
                js_EditorTopMenu.render( <TopMenu_ImageSettings Editor_Obj={editor_obj} Parent_methods={temp_methods} />);
            }
        } else { js_EditorTopMenu.render(<TopMenu_OnlyRightDefault Parent_methods={{Click_DeleteElements}} />) }    
    }
    function CanvasElCleared(obj){
        js_EditorTopMenu.render(<TopMenu_DefaultSettings_withoutNav Parent_methods={{Click_ShowLeftMenuColors}} />);
        if(needClearLeft) { needClearLeft = false; js_EditorLeftMenu.render(null); }
    }
    //#2 --- LeftMenu Event Functions   ---   ---   ---   ---   ---
    function Click_SectionSelect(e, type) {
        func_ShowLeftMenu();
        document.querySelectorAll('.js_editor_section_menu div').forEach(function(el){ el.style.backgroundColor = null; });
        e.currentTarget.style.backgroundColor = '#ffffff';

        if (type == 'templates'){ js_EditorLeftMenu.render(<LeftMenuTemplates />); }
        else if (type == 'text'){ js_EditorLeftMenu.render(<LeftMenuText Click_AddNewText={Click_AddNewText} Click_ToggleLeftMenu={Click_ToggleLeftMenu} />); }
        else if (type == 'images'){
            let temp_methods = {Click_ToggleLeftMenu, Focus_BlackBorder, FileChange_UploadNewImage, Change_SetImgLink_ForImgIEditorElement, Click_AddNewText};
            js_EditorLeftMenu.render(<LeftMenuImages Parent_methods={temp_methods} />);
        } else if (type == 'saveload'){
            let temp_methods = {Click_ToggleLeftMenu, Focus_BlackBorder, Click_SaveTemplate, Click_LoadTemplate, ClearErrorMsg};
            js_EditorLeftMenu.render(<LeftMenuSaveLoad Parent_methods={temp_methods} currTemplate={currTemplate} allTemplates={allTemplates} />);
        } else { js_EditorLeftMenu.render(null); }
    }
    function func_ShowLeftMenu() {
        if (window.innerWidth < 450 ) { document.querySelector('.js_EditorParentLeftMenu').style.width = '300px'; }
        else if (window.innerWidth < 1024 ) { document.querySelector('.js_EditorParentLeftMenu').style.width = '360px'; }
        else { document.querySelector('.js_EditorParentLeftMenu').style.width = null; }
        leftMenuHidden = false; page_resize();
    }
    function Click_ToggleLeftMenu(e, type) {
        document.querySelector('.js_EditorParentLeftMenu').style.width = '0px'; leftMenuHidden = true; page_resize();
    }
    function Click_ClearLeftMenuWidth(e) {
        document.querySelector('.js_EditorParentLeftMenu').style.width = null; leftMenuHidden = false; page_resize();
    }
    // --- Add new text   ---   ---   ---   ---   ---
    function Click_AddNewText(e, fontsize, fontweight) {
        var ctext = new fabric.IText('Default Text', { left: canvase_width/2, top: canvas_height/2, fontSize: fontsize, fontWeight: fontweight });
        canvas.add(ctext); Click_ClearLeftMenuWidth();
    }
    // --- Edit Text   ---   ---   ---   ---   ---
    function Click_ChangeFont(e, font) {
        console.log(e,font);
    }
    function Click_ChangeFontSize(e, fontsize) {
        document.querySelector('.js_editor_fontsize').innerHTML = fontsize;
        let editor_obj = canvas.getActiveObject();
        editor_obj.fontSize = fontsize; editor_obj.dirty = true; canvas.renderAll();
    }
    function Click_ShowLeftMenuColors(e) {
        func_ShowLeftMenu();
        needClearLeft = true;
        console.log(canvas);
        let preColor = ''; if (canvas.getActiveObject() != null) { preColor = canvas.getActiveObject().fill; } else { preColor = canvas.backgroundColor; }
        js_EditorLeftMenu.render(<LeftMenuColors Parent_methods={{Change_ChangeFontColor, Click_ToggleLeftMenu}} preColor={preColor} />); 
    }
    function Change_ChangeFontColor(color, render) {
        if (render) { js_EditorLeftMenu.render(<LeftMenuColors Parent_methods={{Change_ChangeFontColor, Click_ToggleLeftMenu}} preColor={color} reRender={true} />); }
        else { js_EditorLeftMenu.render(<LeftMenuColors Parent_methods={{Change_ChangeFontColor, Click_ToggleLeftMenu}} preColor={color} />); }
        if (canvas.getActiveObject() != null) {
            let editor_obj = canvas.getActiveObject();
            editor_obj.fill = color; editor_obj.dirty = true; canvas.renderAll();
        } else {
            canvas.backgroundColor = color; canvas.renderAll();
        }
    }
    function Click_ChangeFontWeight(e) {
        let fontweight = '';
        if (e.currentTarget.classList.contains('text-gray-400')) { document.querySelector('.js_editor_fontweight').classList.remove('text-gray-400'); fontweight = 'bold'; }
        else { document.querySelector('.js_editor_fontweight').classList.add('text-gray-400'); fontweight = 'normal'; }
        let editor_obj = canvas.getActiveObject();
        editor_obj.fontWeight = fontweight; editor_obj.dirty = true; canvas.renderAll();
    }
    function Click_ChangeTextItalic(e) {
        let fontstyle = '';
        if (e.currentTarget.classList.contains('text-gray-400')) { document.querySelector('.js_editor_fontitalic').classList.remove('text-gray-400'); fontstyle = 'italic'; }
        else { document.querySelector('.js_editor_fontitalic').classList.add('text-gray-400'); fontstyle = 'normal'; }
        let editor_obj = canvas.getActiveObject();
        editor_obj.fontStyle = fontstyle; editor_obj.dirty = true; canvas.renderAll();
    }
    function Click_ChangeTextUnderline(e) {
        let fontunderline = '';
        if (e.currentTarget.classList.contains('text-gray-400')) { document.querySelector('.js_editor_fontunderline').classList.remove('text-gray-400'); fontunderline = true; }
        else { document.querySelector('.js_editor_fontunderline').classList.add('text-gray-400'); fontunderline = false; }
        let editor_obj = canvas.getActiveObject();
        editor_obj.underline = fontunderline; editor_obj.dirty = true; canvas.renderAll();
    }
    function Click_ChangeTextAligh(e) {
        let svgaligh = '', nextAligh = '';
        let currAligh = e.currentTarget.querySelector('svg').classList.value; document.querySelector('.js_editor_textalign svg').classList.remove(currAligh);
        if (currAligh == 'svgleft') { svgaligh = textAlignArray[0]; nextAligh = 'svgcenter'; } else if (currAligh == 'svgcenter') { svgaligh = textAlignArray[1]; nextAligh = 'svgright'; }
        else if (currAligh == 'svgright') { svgaligh = textAlignArray[2]; nextAligh = 'justify'; } else { svgaligh = textAlignArray[3]; nextAligh = 'svgleft'; }
        document.querySelector('.js_editor_textalign svg').classList.add(nextAligh); document.querySelector('.js_editor_textalign svg path').setAttribute('d', svgaligh);
        let editor_obj = canvas.getActiveObject();
        editor_obj.textAlign = currAligh.replace('svg',''); editor_obj.dirty = true; canvas.renderAll();
    }
    function Click_ChangeFrontBackPosition(e, posType) {
        if (posType == 'forward') { canvas.bringForward(canvas.getActiveObject()); } else if (posType == 'backward') { canvas.sendBackwards(canvas.getActiveObject()); }
        else if (posType == 'toFront') { canvas.bringToFront(canvas.getActiveObject()); } else if (posType == 'toBack') { canvas.sendToBack(canvas.getActiveObject()); }
    }
    function Click_ChangeOpacity(e) {
        let editor_obj = canvas.getActiveObject();
        editor_obj.opacity = (e.currentTarget.value/100).toFixed(2);; editor_obj.dirty = true; canvas.renderAll();
    }
    function Click_DeleteElements(e) {
        canvas.getActiveObjects().forEach((obj) => { canvas.remove(obj); }); canvas.discardActiveObject().renderAll();
    }
    function Click_DuplicateElement(e) {
        var object = canvas.getActiveObject();
        object.clone(function(clone) { canvas.add(clone.set({left: object.left + 10, top: object.top + 10})); });
    }
    // --- Add New Image   ---   ---   ---   ---   ---
    function FileChange_UploadNewImage(e) {
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e2) { 
                AddNewImgToEditor_Base64OrUrl(e2.target.result);
                e.target.value = ''; e.target.type = 'text'; e.target.type = 'file';
                Click_ClearLeftMenuWidth();
            }
            reader.readAsDataURL(e.currentTarget.files[0]);
        }
    }
    function Change_SetImgLink_ForImgIEditorElement(e) {
        AddNewImgToEditor_Base64OrUrl(e.currentTarget.value); e.currentTarget.value = '';
        Click_ClearLeftMenuWidth();
    }
    function AddNewImgToEditor_Base64OrUrl(imgSource) {
        let newImgDom = document.createElement('img');
        newImgDom.setAttribute('src', imgSource);
        newImgDom.onload = function() {
            let scalew = 1, scaleh = 1;
            if (newImgDom.naturalWidth > (canvase_width/4)) { scalew = (canvase_width/4) / newImgDom.naturalWidth; }
            if (newImgDom.naturalHeight > (canvas_height/4)) { scaleh = (canvas_height/4) / newImgDom.naturalHeight; }
            if ( scalew < scaleh ) { scalew = scaleh; }

            fabric.Image.fromURL(imgSource, (cimg) => {
                canvas.add(cimg);
            }, { crossOrigin: 'anonymous', left: 350, top: 225, scaleX: scalew, scaleY: scalew} );
        }
    }
    // --- Edit Image   ---   ---   ---   ---   ---
    function Click_FlipImg(e, direction) {
        
        let editor_obj = canvas.getActiveObject(); console.log(editor_obj.flipY);
        if (direction == 'horizontal') { if(editor_obj.flipX) { editor_obj.set('flipX', false); } else { editor_obj.set('flipX', true); }  }
        else { if(editor_obj.flipY) { editor_obj.set('flipY', false); } else { editor_obj.set('flipY', true); } }
        canvas.renderAll();
    }
    // --- Style Global Things   ---   ---   ---   ---   ---
    function Focus_BlackBorder(e, status) {
        if (status) { e.currentTarget.parentElement.parentElement.style.border = '2px solid black'; }
        else { e.currentTarget.parentElement.parentElement.style.border = null; }
    }
    // --- Save/Load Functions   ---   ---   ---   ---   ---
    const Click_SaveTemplate = async (e, template_name, stype) => {
        let tjson = canvas.toJSON(); let tjson_string = JSON.stringify(tjson);
        let template_obj = { name: template_name, template: tjson_string };
        let response = await api_saveTemplate(template_obj);
        console.log(response);
        if( response.status == 200 ) {
            allTemplates = response.data;
            document.querySelector('.'+stype+'_error').classList.remove('text-red-400');
            document.querySelector('.'+stype+'_error').classList.add('text-green-400');
            document.querySelector('.'+stype+'_error').innerHTML = 'Template '+stype+'d successfully';
        } else if (response.status == 500) {
            document.querySelector('.'+stype+'_error').classList.remove('text-green-400');
            document.querySelector('.'+stype+'_error').classList.add('text-red-400');
            document.querySelector('.'+stype+'_error').innerHTML = 'Internal server error, refresh page and try again.';
        }
    }
    const Click_LoadTemplate = async (e) => {
        let template_obj = { name: e.currentTarget.previousSibling.value };
        let response = await api_loadTemplate(template_obj);
        if( response.status == 200 ) {
            FrontTemplate = JSON.parse(response.data.template);
            currTemplate = response.data.name;
            gcolor = FrontTemplate.background; canvas.loadFromJSON(FrontTemplate, canvas.renderAll.bind(canvas));
            document.querySelector('.js_leftmenu_currtemp').innerHTML = response.data.name;
        } else if (response.status == 404) { document.querySelector('.load_error').innerHTML = 'Can\'t found this template.'; }
        else if (response.status == 500) { document.querySelector('.load_error').innerHTML = 'Internal server error, refresh page and try again.'; }
    }
    const ClearErrorMsg = async (stype) => { document.querySelector('.'+stype+'_error').innerHTML = ''; }
    

return (
    <div className="flex flex-1 w-full h-screen">
        <div className="bg-neutral-200 w-20 m_sm:w-14 js_editor_section_menu">
            <div className="flex flex-col w-20 h-20 m_sm:w-14 bg-neutral-200 items-center justify-center font-medium text-xs select-none gap-0.5 cursor-pointer hover:bg-neutral-100 js_editor_LeftMenuTextSection"
                    onClick={(e) => Click_SectionSelect(e, 'text')} style={{transition: 'all 0.5s ease 0s'}}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24"><path fill="currentColor" fillRule="evenodd" d="M18 5.5h-5.25V18c0 .28.22.5.5.5h2a.75.75 0 1 1 0 1.5h-6.5a.75.75 0 1 1 0-1.5h2a.5.5 0 0 0 .5-.5V5.5H6a.5.5 0 0 0-.5.5v1.25a.75.75 0 0 1-1.5 0V5.5C4 4.67 4.67 4 5.5 4h13c.83 0 1.5.67 1.5 1.5v1.75a.75.75 0 1 1-1.5 0V6a.5.5 0 0 0-.5-.5z"></path></svg>
                <div>Text</div>
            </div>
            <div className="flex flex-col w-20 h-20 m_sm:w-14 bg-neutral-200 items-center justify-center font-medium text-xs select-none gap-0.5 cursor-pointer hover:bg-neutral-100"
                    onClick={(e) => Click_SectionSelect(e, 'images')} style={{transition: 'all 0.5s ease 0s'}}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 1.5a.5.5 0 0 0-.5.5v14c0 .28.22.5.5.5h14a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H5zm5.75 10.1l3.05-4.15a2 2 0 0 1 3.22-.01L21 15.78V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-.09l3.82-5.25a2 2 0 0 1 3.22 0l.7.95zm3.6 4.9H19a.5.5 0 0 0 .5-.5v-2.72l-3.69-4.94a.5.5 0 0 0-.8 0l-3.33 4.53 2.68 3.63zm-5.51-4.96a.5.5 0 0 0-.81 0l-3.44 4.74a.5.5 0 0 0 .41.22h7.5l-3.66-4.96zM8.5 10a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path></svg>
                <div>Images</div>
            </div>
            <div className="flex flex-col w-20 h-20 m_sm:w-14 bg-neutral-200 items-center justify-center font-medium text-xs select-none gap-0.5 cursor-pointer hover:bg-neutral-100"
                    onClick={(e) => Click_SectionSelect(e, 'saveload')} style={{transition: 'all 0.5s ease 0s'}}>
                <AiOutlineSave size="24px" />
                <div>Save/Load</div>
            </div>
        </div>
        <div className="w-360 m_lg:w-0 js_EditorParentLeftMenu" style={{flex: '0 0 auto', boxShadow: 'rgb(0 0 0 / 15%) 1px 0px 1px'}}>
            <div className="flex flex-col h-full">
                <div className="flex-1">
                    <div className="relative overflow-hidden w-full h-full js_EditorLeftMenu ">

                    </div>
                </div>
            </div>
        </div>

        <div className="flex flex-col flex-1 relative overflow-x-auto relative">
            <div className="h-14 bg-white ml-px flex m_md:overflow-x-auto scroll-h" style={{ boxShadow: 'rgb(0 0 0 / 15%) 0px 1px 1px'}}>
                <div className="flex-1 flex items-center justify-between py-0 m_2sm:px-1 2sm:px-4 js_EditorTopMenu">
                    <TopMenu_DefaultSettings_withoutNav Parent_methods={{Click_ShowLeftMenuColors}} />
                </div>
            </div>
            <div className="flex flex-1 flex-col p-px">
                <div id="scenify-editor-container" className="flex-1 relative overflow-auto bg-neutral-100">
                    <div className="flex absolute h-full w-full justify-center js_editor_area" onClick={(e) => CanvasElClear(e)}>
                        <canvas id="canvas" className="lower-canvas absolute left-0 top-0 touch-none select-none" width="874" height="811" style={{ borderRadius: '5% 5%', width: '874px', height: '811px' }}></canvas>
                    </div>
                    <div className="absolute  h-full flex items-center" style={{ right: '5px' }}>
                        <div className="relative w-1.5 overflow-hidden m-auto" style={{ height: 'calc(100% - 30px)' }}>
                            <div id="scrollbarY" className="absolute w-1.5 h-0 rounded-md" style={{ background: 'rgba(0, 0, 0, 0.4)', top: '125px' }}></div>
                        </div>
                    </div>
                    <div className="absolute w-full flex items-center" style={{ bottom: '5px' }}>
                        <div className="relative h-1.5 overflow-hidden m-auto" style={{ width: 'calc(100% - 30px)' }}>
                            <div id="scrollbarX" className="absolute w-0 h-1.5 rounded-md right-auto" style={{ background: 'rgba(0, 0, 0, 0.4)', left: '203px' }}></div>
                        </div>
                    </div>
                </div>

                <div className="bg-neutral-100 flex absolute bottom-4 right-4">
                    <div className="flex items-center" style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                        <button data-baseweb="button" className="inline-flex flex-row items-center justify-center border-r border-none appearance-none cursor-pointer m-0 text-sm font-medium leading-4 rounded-none px-2.5 py-3 text-black bg-transparent"
                                onClick={(e) => Click_EditorZoom(e, -5)}
                                style={{outline: 'none', boxShadow: 'none', textDecoration: 'none', borderRightStyle: 'solid', borderRightColor: 'rgba(0, 0, 0, 0.1)'}}>
                            <svg data-baseweb="icon" title="Check Indeterminate" viewBox="0 0 24 24" className="inline-block fill-current text-current h-5 w-5"><title>Check Indeterminate</title><path fillRule="evenodd" clipRule="evenodd" d="M6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12Z"></path></svg>
                        </button>
                        <button data-baseweb="button" aria-haspopup="true" aria-expanded="false" className="inline-flex flex-row items-center justify-center border-0 appearance-none cursor-pointer m-0 text-sm font-medium leading-4 rounded-none px-2.5 py-3 text-black bg-transparent js_editor_zoom" 
                            style={{outline: 'none', boxShadow: 'none', textDecoration: 'none', borderBottomStyle: 'none', borderTopStyle: 'none' }}>100%</button>
                        <button data-baseweb="button" className="inline-flex flex-row items-center justify-center border-l border-none appearance-none cursor-pointer m-0 text-sm font-medium leading-4 rounded-none px-2.5 py-3 text-black bg-transparent"
                                onClick={(e) => Click_EditorZoom(e, 5)}
                                style={{outline: 'none', boxShadow: 'none', textDecoration: 'none', borderLeftStyle: 'solid', borderLeftColor: 'rgba(0, 0, 0, 0.1)'}}>
                            <svg data-baseweb="icon" title="Plus" viewBox="0 0 24 24" className="inline-block fill-current text-current h-5 w-5"><title>Plus</title><path fillRule="evenodd" clipRule="evenodd" d="M12 5C12.5523 5 13 5.44772 13 6V11L18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13L13 13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V13L6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44771 11 6 11L11 11V6C11 5.44772 11.4477 5 12 5Z"></path></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default ProductDesigner;
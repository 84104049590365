import React from 'react';

export function LongLabel({icon, reactIcon, value, name}) {
    if (value == "") { return (<></>) }

    let iconDiv = <></>;
    if(typeof reactIcon !== "undefined") { iconDiv = reactIcon; }
    else if(typeof icon !== "undefined") { iconDiv = <i className={`before-block flex ${icon} text-gray-mute text-2xl group-hover:text-white transition-colors duration-300`}></i>; }

    return (
        <div className="bg-gray-100 px-4 py-3 flex space-x-4 rounded-xl hover group hover:bg-green_1 hover:text-white transition-colors duration-300">
            <div className="flex items-center">
                {iconDiv}
            </div>
            <div className="">
                <div className="">
                    <p className="font-medium">{value}</p>
                    <p className="text-gray-mute text-sm group-hover:text-white transition-colors duration-300">{name}</p>
                </div>
            </div>
        </div>
    )
}

export function LongLabelLink({icon, value, name, link}) {
    return (
        <a href={link} className="bg-gray-100 px-4 py-3 flex space-x-4 rounded-xl cursor-pointer hover group hover:bg-green_1 hover:text-white transition-colors duration-300" style={{ outline: 'none', textDecoration: 'none' }}>
            <div className="flex items-center">
                <i className={`before-block flex ${icon} text-gray-mute text-2xl group-hover:text-white transition-colors duration-300`}></i>
            </div>
            <div className="">
                <div className="">
                    <p className="font-medium">{value}</p>
                    <p className="text-gray-mute text-sm group-hover:text-white transition-colors duration-300">{name}</p>
                </div>
            </div>
        </a>
    )
}

export function SocialSquare({icon, link}) {
    return (
        <div className="w-full" style={{ maxWidth: '88px' }}>
            <a href={link} target="_blank" className="flex-1 flex hover group justify-center items-center p-6 aspect-square bg-gray-100 w-full btn-gradient text-white rounded-md block text-xl btn btn-ripple disabled:pointer-events-none disabled:opacity-75">
                {icon}
                {/*<BsInstagram className="fill-gray-mute group-hover:fill-white transition-colors duration-300" size="24px" />*/}
            </a>
        </div>
    )
}
import React from 'react'
import { useTranslation } from "react-i18next";
import RoundedBottomCircle from '../../assets/images/rounded-bottom-circle.png'
import Rec1423 from '../../assets/images/Rectangle-1423.png'
import Shape1 from '../../assets/images/shape-1.png'
import GreenDots from '../../assets/images/green-dots.png'
import Groupe887 from   '../../assets/images/Group 887.png'
import Groupe888 from   '../../assets/images/Group 888.png'
import BlackGreenBg from   '../../assets/images/black-green-cardbg.png'
import GreenBlock1 from '../../assets/images/GreenBlock1.png'
import GreenBlock2 from '../../assets/images/GreenBlock2.png'
import GreenBlock3 from '../../assets/images/GreenBlock3.png'
import GreenBlock4 from '../../assets/images/GreenBlock4.png'


function FeaturesDesktop() {
    const { t, i18n } = useTranslation();
    return (
    <section className="flex m_lg:flex-col justify-center animated-scroll bg-[#F4F7FA] m_md:px-4 md:px-[5rem] gap-x-[30px] m_lg:pt-0 lg:pt-[10rem] m_lg:pb-0 lg:pb-[7rem] relative active" id="features">
        <div className="flex flex-col w-full lg:w-1/2 py-[50px] 3xl:relative" style={{maxWidth: '860px'}}>
            <img className="absolute w-fit z-0 xl:block hidden top-c43 -left-c20 3xl:-top-c105 3xl:-left-c95" src={BlackGreenBg} alt="Card Picture" />
            <div className="flex h-[14rem] gap-x-[20px] justify-center z-10 m_2sm:h-fit">
                <div className="bg-white flex flex-col justify-center items-center relative pb-[25px] drop-shadow w-1/2" style={{borderRadius:'1.8rem'}}>
                    <div className="flex items-center justify-center pb-[20px] pt-[30px]">
                        <img src={GreenBlock1} alt=""/>
                    </div>
                    <div className="flex items-center justify-center flex-col px-2 text-center ">
                        <h3 className="text-[20px] pb-[10px] text-xl font-bold">{t("index.nfc_chip")}</h3>
                        <p className="text-gray-500">{t("index.contact_details")}</p>
                    </div>
                </div>
                <div className="bg-white flex flex-col justify-center items-center relative pb-[25px] drop-shadow w-1/2" style={{borderRadius:'1.8rem'}}>
                    <div className="flex items-center justify-center pb-[20px] pt-[30px]">
                        <img src={GreenBlock3} alt=""/>
                    </div>
                    <div className="flex items-center justify-center flex-col px-2 text-center">
                        <h3 className="text-[20px] pb-[10px] text-xl font-bold">{t("index.qr_codes")}</h3>
                        <p className="text-gray-500">{t("index.qr_code")}</p>
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center pt-[20px] gap-x-[20px] m_2sm:h-fit">
                <div className="bg-white flex flex-col justify-center items-center relative pb-[25px] drop-shadow w-1/2" style={{borderRadius:'1.8rem'}}>
                    <div className="flex items-center justify-center pb-[20px] pt-[30px]">
                        <img src={GreenBlock2} alt=""/>
                    </div>
                    <div className="flex items-center justify-center flex-col px-2 text-center ">
                        <h3 className="text-[20px] pb-[10px] text-xl font-bold">{t("index.plug_and_play")}</h3>
                        <p className="text-gray-500">{t("index.no_app_needed")}</p>
                    </div>
                </div>
                <div className="bg-white flex flex-col justify-center items-center relative pb-[25px] drop-shadow w-1/2" style={{borderRadius:'1.8rem'}}>
                    <div className="flex items-center justify-center pb-[20px] pt-[30px]">
                        <img src={GreenBlock4}  alt=""/>
                    </div>
                    <div className="flex items-center justify-center flex-col px-2 text-center">
                        <h3 className="text-[20px] pb-[10px] text-xl font-bold">{t("index.devices_support")}</h3>
                        <p className="text-gray-500">{t("index.android_ios")}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/2 pt-[3rem] xl:pl-16 relative items-center justify-center" style={{maxWidth: '860px'}}>
            {/*<div className="absolute -top-[20px] -left-24 w-[7.4rem]"><img src={Shape1} alt=""/></div>*/}
            <div className="pb-[2rem]">
                <h1 className="text-5xl pb-[1rem]"><span className="whitespace-nowrap" id="home-text">{t("index.why_cartenfc_are_different_1")} </span><br /><span className="whitespace-nowrap">{t("index.why_cartenfc_are_different_2")}</span></h1>
                <p>{t("index.first_impression_1")} {t("index.first_impression_2")} {t("index.first_impression_3")}</p>
                <div className="flex flex-col items-start justify-center  gap-[20px] py-[2.25rem]">
                    <div className="flex items-center justify-center gap-x-[15px]">
                        <div style={{borderRadius:'80px'}} className="w-[50px] bg-black ">
                            <img src={Groupe887} alt=""/>
                        </div>
                        <div>
                            <p><span id="home-text">{t("index.traffic_analytics_1")}</span> {t("index.traffic_analytics_2")}</p>
                        </div>
                    </div>
                    <div className="flex items-center  justify-center gap-x-[15px]">
                        <div style={{borderRadius:'80px'}} className="w-[50px] bg-black">
                            <img src={Groupe888} alt=""/>
                        </div>
                        <div>
                            <p>{t("index.share_resources_1")} <span id="home-text">{t("index.share_resources_2")}</span> {t("index.share_resources_3")}</p>
                        </div>
                    </div>
                    <div className="py-9 w-[220px] flex justify-center">
                        <a className="flex justify-center py-4 px-10 text-white rounded-[40px] grow text-center whitespace-nowrap" href="#products"
                        style={{fontFamily: 'Poppins, sans-serif', boxShadow: '4px 16px 32px rgba(35, 180, 107, 0.35)', background: 'linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)', fontSize:'clamp(10px,15px,2rem)'}}>
                            {t("index.get_yours_now")}
                        </a>
                    </div>
                </div>
            </div>
            <div className="absolute m_lg:hidden -top-[120px] right-[0px] w-[5.4rem]">
                <img src={GreenDots} alt=""/>
            </div>
        </div>
        <div className="absolute bottom-[0] right-0 w-[13.4rem]">
            <img src={RoundedBottomCircle} alt=""/>
        </div>
    </section>
  )
}

export default FeaturesDesktop
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import BgVr2 from "../../assets/images/bg-vr-2.png";
import Group865 from "../../assets/images/Group 865.png";


function CostSavings() {
    const { t, i18n } = useTranslation();
    let currencySymbol = process.env.REACT_APP_CURR_SYMB;
    let currencyRate = process.env.REACT_APP_CURR_RATE;
    
  useEffect(() => {
      
    // CostSavingsComponent Calculator
    const cardsSlider = document.getElementById('cards-slider');
    const cardsCost = document.getElementById('cards-cost');
    const trees = document.getElementById('trees');
    const money = document.getElementById('money');
    const employeesSlider = document.getElementById('employees-slider');
    const employeesAmount = document.getElementById('employees-amount');
  
    function treesFormula(employeesNumber, cardPrice) { return ((employeesNumber * cardPrice) * 0.00004).toFixed(1); }
    function moneyFormula(employeesNumber, cardPrice) { return employeesNumber * cardPrice; }
  
    cardsCost.innerHTML = (cardsSlider.value*currencyRate) + ' ' + currencySymbol;
    cardsSlider.oninput = function() {
      cardsCost.innerHTML = this.value + ' ' + currencySymbol;
      trees.innerHTML = treesFormula(this.value, cardsSlider.value*currencyRate);
      money.innerHTML = moneyFormula(employeesSlider.value, this.value) + ' ' + currencySymbol;
    };
  
    employeesAmount.innerHTML = employeesSlider.value;
    employeesSlider.oninput = function() {
      employeesAmount.innerHTML = this.value;
      trees.innerHTML = treesFormula(this.value, cardsSlider.value*currencyRate);
      money.innerHTML = moneyFormula(this.value, cardsSlider.value*currencyRate) + ' ' + currencySymbol;
    };
    money.innerHTML = moneyFormula(employeesSlider.value, cardsSlider.value*currencyRate) + ' ' + currencySymbol;
    trees.innerHTML = treesFormula(employeesSlider.value, cardsSlider.value*currencyRate);
    
  }, []);

  return (
    <>
        <div id="CostSavingsComponent" className="securityhero gray-container woods wf-section text-black sm:mt-[2rem] lg:mt-0 animated-scroll active">
            <div className="sm:flex m_lg:flex-col">
                <div id="costsavings" className="m_sm:px-4 sm:px-10 md:px-20 lg:w-1/2 lg:ml-20 lg:py-10" >
                    <div className="div-block-23">
                        <div className="w-full m_lg:text-center lg:text-left">
                            <h1 className="cursor-scale  heading-15 section-title lg:text-[60px]">
                                <span id="text-primary" className="block">{t("index.save_money_with_cartenfc_1")}</span>{t("index.save_money_with_cartenfc_2")}
                            </h1>
                            <p className="paragraph-12 woods-subtitle text-gray-500 text-xs">
                                {t("index.upgrade_paper_1")}
                                <br />
                                {t("index.upgrade_paper_2")}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-start mt-3">
                        <div className="w-full">
                            <p className="flex mb-7 justify-between items-center text-black lg:text-xl">
                                {t("index.employees")}
                                <span id="employees-amount" className="text-span-6 text-primary">100</span>
                            </p>
                            <div className="w-embed">
                                <div className="slidecontainer">
                                    <input type="range" min="50" max="1000" className="range w-full" defaultValue={"100"} id="employees-slider" />
                                </div>
                            </div>
                            <div className="bg-black m_sm2:px-4 sm2:px-8 py-8 text-white rounded-md text-center mt-4" style={{ borderRadius: "30px" }}>
                                <div className="text-16 eco-text text-primary">{t("index.bottom_line")}</div>
                                <h1 id="money" className="h2-48 eco-subtitle whitespace-nowrap">5000 {currencySymbol}</h1>
                                <div className="text-16">{t("index.savings_per_year")}</div>
                            </div>
                            <a data-event-category="Click" data-event-label="CostSavingsComponent" data-event-action="Buy button" href="#products"
                                style={{ background: "linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)", borderRadius: "40px", }}
                                className="lg:flex lg:items-center lg:justify-center gradient-button w-full mt-4 text-white lg:font-normal ga-event button-45 w-button whitespace-nowrap">
                                {t("index.switch_to_cartenfc")}
                            </a>
                        </div>
                        <div className="w-full m_md:pl-2 md:pl-4">
                            <p className="flex mb-7 justify-between items-center lg:text-xl whitespace-nowrap">
                                {t("index.cost_per_year")}
                                <span id="cards-cost" className="text-span-6 text-primary">50 {currencySymbol}</span>
                            </p>
                            <div className="w-embed">
                                <div className="slidecontainer">
                                    <input type="range" className="range w-full" min="50" max="200" defaultValue={"50"} id="cards-slider" />
                                </div>
                            </div>
                            <div className="bg-black m_sm2:px-4 sm2:px-8 py-8 text-white text-center rounded-md mt-4" style={{ borderRadius: "30px" }} >
                                <div className="text-16 eco-text  text-primary">
                                    {t("index.trees_saved")}
                                </div>
                                <h1 id="trees" className="h2-48 eco-subtitle">3</h1>
                                <div className="text-16 text-white whitespace-nowrap">{t("index.eco_impact_per_year")}</div>
                            </div>
                            <a data-event-category="Click" data-event-action="See our eco-friendly impact" href="#products"
                                className="gradient-button w-full mt-4 transparent-button ga-event w-button border-2 bg-none bg-none hover:bg-none cursor-pointer whitespace-nowrap" style={{ borderRadius: "40px" }}>
                                {t("index.eco_friendly_impact")}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center lg:w-1/2 lg:relative">
                    <img className="self-center md:max-w-xl xl:max-w-2xl" src={BgVr2} alt="" />
                </div>
            </div>
        </div>
        {/*<section className="relative -mt-[4rem] w-full lg:hidden anime-mq" style={{ overflow: "visible !important", background: "#fff" }}>
            <div className="w-full mx-auto flex items-center justify-center">
                <img src={Group865} alt="" />
            </div>
        </section>*/}
    </>
  );
}

export default CostSavings;
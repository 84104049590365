import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { SketchPicker  } from 'react-color';

/*
    styleTop - top margin in px
    queryDom - query dom which event we catch
    addon_queryDom - addon query dom which color need change
    type - type of query dom
*/

export function React_color({styleTop = "25px", queryDom = '', addon_queryDom = '', type = 'text', parent_method}) {
    const [color, setColor] = useState({ r: 255,  g: 255 ,b: 255, a: 1 });
    const [display, setDisplay] = useState('none');
    let interactDom = null; let addonDom = null;

    useEffect(() => { 
        if (type == "text"){
            interactDom = ReactDOM.findDOMNode(document.querySelector(queryDom));
            if (addon_queryDom != '') { addonDom = ReactDOM.findDOMNode(document.querySelector(addon_queryDom)); }
            interactDom.addEventListener('focus', function(){ setDisplay(''); });
            interactDom.addEventListener('focusout', function(){ setTimeout(function(){ ChangeColor_EventFunction(); },150); });
        }
    });

    const onChange = (color) => { ChangeColor_EventFunction(color); }
    const onChangeComplete = (color) => { ChangeColor_EventFunction(color); }
    function ChangeColor_EventFunction(color) {
        if(typeof color !== "undefined"){
            setColor(color.rgb);
            if (!document.querySelector('.js_colorpicker').contains(document.activeElement) && interactDom != null) { interactDom.focus(); }
            if (interactDom != null) { 
                interactDom.value = color.hex;
                if( typeof parent_method != "undefined" ) { parent_method(color); }
            }
            if (addonDom != null) { addonDom.style.backgroundColor = color.hex; }
        }
        if (document.activeElement !== interactDom && !document.querySelector('.js_colorpicker').contains(document.activeElement)) { setDisplay('none'); }
    }
    return (
        <div className="js_colorpicker" style={{ userSelect: 'none', position: 'absolute', zIndex: '3', top: styleTop, display: display }}>
            <SketchPicker color={color} onChange={onChange} onChangeComplete={onChangeComplete} disableAlpha={true}></SketchPicker>
        </div>
    )
}

export function React_color_simple({preColor = '#ffffff', parent_method, reRender = false}) {
    const [color, setColor] = useState(preColor);
    let tempColor = color; if (reRender) { tempColor = preColor; }

    const onChange = (color) => { ChangeColor_EventFunction(color); }
    const onChangeComplete = (color) => { ChangeColor_EventFunction(color); }
    function ChangeColor_EventFunction(color) {
        setColor(color.rgb);
        if( typeof parent_method != "undefined" ) { parent_method(color.hex, false); }
    }

    return (
        <div className="js_colorpicker" style={{ userSelect: 'none', zIndex: '3' }}>
            <SketchPicker color={tempColor} onChange={onChange} onChangeComplete={onChangeComplete} disableAlpha={true} presetColors={[]}></SketchPicker>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { api_CheckLogIn } from './Axios'

const PrivateLoginRoute = () => {
    const [islogged, setIsLogged] = useState(null);

    const checkLogIn = async () => {
        let response = await api_CheckLogIn();
        console.log(response);
        if( response.status == 200 ) { setIsLogged(true); }
        else { setIsLogged(false); }
    }

    useEffect(() => {
        if (islogged == null) { checkLogIn(); }
    });

    if (islogged == null) { return null; }
    return( islogged ? <Navigate to="/profile"/> : <Outlet/> )
}

export default PrivateLoginRoute
import React, { useEffect } from 'react';
import { api_Guest, api_Check, api_LogIn } from './lib/Axios'
import { useTranslation } from "react-i18next";
import Logo from "../assets/images/logo.svg";
import HeroImg from '../assets/images/frame-110.png'
import HeroImgMob from '../assets/images/Frame-97.png'
import Cloud from '../assets/images/cloud-3.png'
const Login = () => {
    const { t, i18n } = useTranslation();
    var AxiosWork = false;
    // -- Axios Works -- 
    const guestToken = async () => {
        let response = await api_Check();
        if( response.status == 500 || response.status == 401 ) { response = await api_Guest(); }
    }
    useEffect(() => {
        guestToken();
    });
    // -- Events -- 
    const Click_LoginButton = async () => {
        let notFilled = '';

        if (document.querySelector('#inp_email').value == '') { InputWasWrong('inp_email'); notFilled += 'e'; }
        else if (document.querySelector('#inp_email').value.indexOf('@') == -1) { InputWasWrong('inp_email', t("index.email_format_error")); notFilled += 'e'; }
        else if (document.querySelector('#inp_email').value.length < 7 || document.querySelector('#inp_email').value.length > 50) { InputWasWrong('inp_email', t("index.email_length_error")); notFilled += 'e'; }
        
        if (document.querySelector('#inp_pass').value == '') { InputWasWrong('inp_pass'); notFilled += 'p'; }
        else if (document.querySelector('#inp_pass').value.length < 6 || document.querySelector('#inp_pass').value.length > 24) { InputWasWrong('inp_pass', t("index.password_length_error")); notFilled += 'p';  }
        
        if (notFilled == ''){
            if (AxiosWork) { return; } else { 
                InputClearError('inp_pass'); document.querySelector('.js_login_login').classList.add('opacity-50'); 
                document.querySelector('.js_login_login').style.outline = 'none'; AxiosWork = true;
            }
            let response = await api_LogIn({ 
                email: document.querySelector('#inp_email').value,
                password: document.querySelector('#inp_pass').value
            });
            AxiosWork = false; document.querySelector('.js_login_login').classList.remove('opacity-50'); document.querySelector('.js_login_login').style.outline = '';
            if( response.status == 200 ) {
                localStorage.setItem('token', response.data);
                localStorage.setItem('islogged', true);
                window.location.href = "/profile";
            } else {
                if (response.status == 401) { /*NEED REFRESH TOKEN*/InputWasWrong('inp_pass', t("index.internal_error")); }
                if (response.status == 404) { InputWasWrong('inp_pass', t("index.login_error")); }
                if (response.status == 500) { InputWasWrong('inp_pass', t("index.unexpected_error")); }
            }
        }
    }
    function Change_Input_Email(e) { e.currentTarget.style.border = null; InputClearError('inp_email'); }
    function Change_Input_Password(e) { e.currentTarget.style.border = null; InputClearError('inp_pass'); }
    function Change_Input_Password(e) { e.currentTarget.style.border = null; InputClearError('inp_pass'); }
    function InputClearError(dom_id) { document.querySelector('.'+dom_id+'_error').classList.add('hidden'); }
    function InputWasWrong(dom_id, error_text = '') {
        document.querySelector('#'+dom_id).style.border = '1px solid #f56565';
        if (error_text != '') { document.querySelector('.'+dom_id+'_error').innerHTML = error_text; document.querySelector('.'+dom_id+'_error').classList.remove('hidden'); }
    }
    return (
    <section className='flex flex-col lg:flex-row w-full min-h-screen font-primary overflow-x-hidden'>
        <div className="block lg:hidden w-full ml-10 my-2" >
            <a className="cursor-pointer" href="/"><img src={Logo} alt='nfc-logo'/></a>
        </div>

        <div className='w-full lg:mt-[3rem] flex items-center justify-center lg:hidden'>
            <div className='flex w-full bg-[#F5F5F5] m-[1rem] justify-center items-center relative' style={{borderRadius:'60px'}}>
                <div className='absolute -top-[1rem] -left-[6rem] w-[10rem]'><img src={Cloud} alt=''/></div>
                <div className='flex w-full justify-center'>
                    <img className="w-full" src={HeroImg} alt='' style={{maxWidth: '504px'}}/>
                </div>
            </div>
        </div>

        <div className="flex flex-row w-full">
            <div className="w-full lg:w-1/2 flex flex-col justify-center gap-[2rem] mx-6 sm:mx-20 md:mx-40 lg:mx-8 2xl:mx-40 xl:gap-[5rem]">
                <nav className="hidden lg:block absolute top-8 left-8" style={{ width: '178px' }}>
                    <a className="cursor-pointer" href="/"><img src={Logo} alt='nfc-logo'/></a>
                </nav>
                <div className='flex flex-col w-full gap-[1rem] xl:mt-[1rem]'>
                    <div className='flex flex-col gap-[1rem] m_lg:items-center m_lg:pt-2 '>
                        <h1 className='text-[1.8rem] sm:text-[2.3rem] font-[600] 2xl:text-[3.4rem]'>{t("index.sign_in")}</h1>
                    </div>
                    <div className='flex flex-col gap-[1.4rem] w-full mt-[2rem]'>
                        <div className="flex justify-between">
                            <span className='text-[#ccc]'>{t("index.email")}</span>
                        </div>
                        <input type="text" id="inp_email" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input" 
                            onChange={(e) => Change_Input_Email(e)} placeholder=""/>
                        <span className="text-red-400 w-full text-sm hidden inp_email_error"></span>
                    </div>
                    <div className='flex flex-col gap-[1.4rem] w-full mt-[2rem]'>
                        <span className='text-[#ccc]'>{t("index.password")}</span>
                        <input type="password" id="inp_pass" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input" 
                            onChange={(e) => Change_Input_Password(e)} onKeyPress={event => { if (event.key === 'Enter') { Click_LoginButton() } }}  placeholder=""/>
                        <span className="text-red-400 w-full text-sm hidden inp_pass_error"></span>
                    </div>

                    <button className='primary-btn text-white mt-[1rem] py-3 js_login_login m_lg:mb-10' onClick={(e) => Click_LoginButton()}
                        style={{ background: 'linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)', boxShadow: '4px 16px 32px rgb(35 180 107 / 35%)', borderRadius: '40px' }}>
                        {t("index.login")}
                    </button>
                    <div className='flex items-center ml-[1rem] sm:ml-[3rem] mt-[1rem] pb-14 w-full'>
                        <span className='text-[#ccc]'>Forgot your password? <a href='/forgot' className='text-black'>Recover</a></span>
                    </div>
                </div>
            </div>
            <div className='hidden w-1/2 m-[1rem] lg:flex items-center relative' style={{borderRadius:'60px'}}>
                <div className='absolute -top-[1rem] -left-[6rem] w-[14rem]'><img src={Cloud} alt=''/></div>
                <div className='w-full'>
                    <div className='w-full bg-[#F5F5F5]' style={{borderRadius:'60px'}}><img src={HeroImg} alt='' /></div>
                </div>
            </div>
        </div>
    </section>
    )

}
export default Login;
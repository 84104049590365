import React from 'react'
import { useTranslation } from "react-i18next";
import '../../assets/css/Nav-check.css';
import logo from '../../assets/images/Frame.svg';
import stepOne from '../../assets/images/bub_step1.png';
import stepTwo from '../../assets/images/bub_step2.png';
import stepThree from '../../assets/images/bub_step3.png';
import stepFour from '../../assets/images/bub_step4.png';
import vector from '../../assets/images/Vector 2.png';


function MobHeader() {
    const { t, i18n } = useTranslation();
    return (
    <header className="nav-mob-tab mb-[1rem]">
        <div className="flex items-center justify-center py-[1rem]">
            <a href="/"><img className="min-w-[89px]" src={logo} alt="nfc-logo"/></a>
        </div>
        <nav className="flex justify-center w-full realtive flex justify-center my-2.5" >
            <div className="form-steps__item form-steps__item js_inform1_hmb_line_1">
                <div className="form-steps__item-content">
                    <span className="form-steps__item-icon js_inform1_hbub_1"> <img className="max-w-[2rem]" src={stepOne}  style={{ background: '#FFFFFFE3',borderRadius: "50%"}} alt=""  /></span>
                    <span className="form-steps__item-text font-bold">{t("index.information")}</span>
                </div>
            </div>
            <div className="form-steps__item form-steps__item js_inform1_hmb_line_2">
                <div className="form-steps__item-content">
                    <span className="form-steps__item-icon js_inform1_hbub_2"> <img className="max-w-[2rem]" src={stepTwo} style={{ background: '#FFFFFFE3',borderRadius: "50%"}} alt=""/></span>
                    <span className="form-steps__item-line"></span>
                    <span className="form-steps__item-text font-bold">{t("index.preview")}</span>
                </div>
            </div>
            <div className="form-steps__item form-steps__item js_inform1_hmb_line_3">
                <div className="form-steps__item-content">
                    <span className="form-steps__item-icon js_inform1_hbub_3"> <img  style={{ background: '#FFFFFFE3',borderRadius: "50%"}} src={stepThree} alt=""/></span>
                    <span className="form-steps__item-line"></span>
                    <span className="form-steps__item-text font-bold">{t("index.delivery_information")}</span>
                </div>
            </div>
            <div className="form-steps__item">
                <div className="form-steps__item-content">
                    <span className="form-steps__item-icon js_inform1_hbub_4"> <img  style={{ background: '#FFFFFFE3',borderRadius: "50%"}} src={stepFour} alt=""/></span>
                    <span className="form-steps__item-line"></span>
                    <span className="form-steps__item-text font-bold">{t("index.complete_your_order")}</span>
                </div>
            </div>
        </nav>
    
    </header>
  )
}

export default MobHeader
import React from 'react'
import { useTranslation } from "react-i18next";
import { BsWhatsapp, BsInstagram } from "react-icons/bs";
import { RiMessengerLine } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";


import FreameLogo from '../../assets/images/Frame-LOGO.svg' 
import WhatsApp from '../../assets/images/whatsapp.svg'
import Telegram from '../../assets/images/telegram.svg'
import Messenger from '../../assets/images/messenger.svg'
function Footer() {
    const { t, i18n } = useTranslation();
    return (
    <>
        <footer className="hidden w-full md:flex flex-col bg-black ft-align px-[5rem]">
            <div className="md:flex gap-y-[30px] footer-tab gap-x-[9rem] minus-space p-[4rem]">
                <div className="flex flex-col gap-y-[1.5rem]  w-[16rem] sm:w-[30%] space-footer-tab">
                    <div className=" w-[10rem] flex justify-center">
                        <img src={FreameLogo} alt=""/>
                    </div>
                    <div className="">
                        <p className="text-sm" style={{fontWeight: '400', lineHeight: '100%', letterSpacing: '-0.03em', color: '#A4A4A4'}}>{t("index.share_contact")} </p>
                    </div>
                    <div className="flex gap-x-[15px] pb-[30px]">
                        <div className="group w-c60 h-c60">
                            <button className="flex justify-center items-center border-2 border-solid border-gray-mute rounded-full group-hover:border-0 group-hover:bg-green_1 w-full h-full transition-all">
                                <BsWhatsapp className="fill-gray-mute group-hover:fill-white transition-all" size="30px" />
                            </button>
                        </div>
                         <div className="group w-c60 h-c60">
                            <button className="flex justify-center items-center border-2 border-solid border-gray-mute rounded-full group-hover:border-0 group-hover:bg-green_1 w-full h-full transition-all">
                                <BsInstagram className="fill-gray-mute group-hover:fill-white transition-all" size="30px" />
                            </button>
                        </div>
                         <div className="group w-c60 h-c60">
                            <button className="flex justify-center items-center border-2 border-solid border-gray-mute rounded-full group-hover:border-0 group-hover:bg-green_1 w-full h-full transition-all">
                                <FaTelegramPlane className="fill-gray-mute group-hover:fill-white transition-all" size="30px" />
                            </button>
                        </div>
                         <div className="group w-c60 h-c60">
                            <button className="flex justify-center items-center border-2 border-solid border-gray-mute rounded-full group-hover:border-0 group-hover:bg-green_1 w-full h-full transition-all">
                                <RiMessengerLine className="fill-gray-mute group-hover:fill-white transition-all" size="30px" />
                            </button>
                        </div>
                </div>
            </div>
            <div className=" sm:w-[70%] flex gap-x-[6rem]">
                <div>
                    <ul style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                    <div className="md:text-xl lg:text-2xl md:pb-[1rem] text-white">
                        <h3>{t("index.solution")}</h3>
                    </div>
                    <a href="http://" target="_blank">
                        <li className="text-white" id="footer-lg">{t("index.features")}</li>
                    </a>
                    <a href="http://" target="_blank">
                        <li className="text-gray-500" id="footer-lg">{t("index.products")}</li>
                    </a>
                    <a href="http://" target="_blank">
                        <li className="text-gray-500" id="footer-lg">{t("index.calculator")}</li>
                    </a>
                    </ul>
                </div>
                <div className="">
                    <ul style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                        <div className="md:text-xl lg:text-2xl  md:pb-[1rem] text-white">
                            <h3>{t("index.company")}</h3>
                        </div>
                        <a href="http://" target="_blank">
                            <li id="footer-lg" className="text-white">{t("index.our_teams")}</li>
                        </a>
                        <a href="http://" target="_blank">
                            <li className="text-gray-500" id="footer-lg">{t("index.support")}</li>
                        </a>
                        <a href="http://" target="_blank">
                            <li className="text-gray-500" id="footer-lg">{t("index.blog")}</li>
                        </a>
                    </ul>
                </div>
                <div>
                    <ul style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                        <div className="md:text-xl lg:text-2xl  md:pb-[1rem] text-white ">
                            <h3>{t("index.contacts")}</h3>
                        </div>
                        <a href="http://" target="_blank">
                            <li id="footer-lg" className="text-green-500">hello@cartenfc.com</li>
                        </a>
                        <li className="text-gray-500" id="footer-lg">CarteNFC Morocco B.V. poortland</li>
                        <li className="text-gray-500" id="footer-lg">66</li>
                        <li className="text-gray-500 w-full" id="footer-lg">1046 BD Amesterdam</li>
                    </ul>
                </div>
            </div>
            </div>
            <div className="border-t border-gray-400 -ml-[7rem] pt-[20px] " style={{opacity:'0.3'}}>  </div>
            <div>
                <div className="flex items-center pb-[1rem] gap-x-[2rem]">
                    <p id="footer-lg" className="text-[#A4A4A4] w-full">{t("index.copyright_notice")}</p>
                    <div className=" text-[#A4A4A4] whitespace-nowrap">{t("index.privacy_and_policy")}</div>
                    <div className="text-[#A4A4A4] whitespace-nowrap">{t("index.terms_and_conditions")}</div>
                </div>
            </div>
        </footer>
        <footer className="md:hidden w-full flex flex-col gap-y-[2rem] items-center justify-center bg-black p-[38px]">
            <div className="w-40">
                <img src={FreameLogo} alt=""/>
            </div>
            <div>
                <p className="text-sm" style={{fontWeight: '400', lineHeight: "100%", /* identical to box height, or 16px */ textAlign: 'center', letterSpacing: '-0.03em', color: '#A4A4A4'}}>Share your contact details with a tap </p>
            </div>
            <div className="flex px-[20px] gap-x-[15px] align-items justify-center  pb-[30px] footer-img-icon-w">
                <div className="group w-c60 h-c60">
                    <button className="flex justify-center items-center border-2 border-solid border-gray-mute rounded-full group-hover:border-0 group-hover:bg-green_1 w-full h-full transition-all">
                        <BsWhatsapp className="fill-gray-mute group-hover:fill-white transition-all" size="30px" />
                    </button>
                </div>
                 <div className="group w-c60 h-c60">
                    <button className="flex justify-center items-center border-2 border-solid border-gray-mute rounded-full group-hover:border-0 group-hover:bg-green_1 w-full h-full transition-all">
                        <BsInstagram className="fill-gray-mute group-hover:fill-white transition-all" size="30px" />
                    </button>
                </div>
                 <div className="group w-c60 h-c60">
                    <button className="flex justify-center items-center border-2 border-solid border-gray-mute rounded-full group-hover:border-0 group-hover:bg-green_1 w-full h-full transition-all">
                        <FaTelegramPlane className="fill-gray-mute group-hover:fill-white transition-all" size="30px" />
                    </button>
                </div>
                 <div className="group w-c60 h-c60">
                    <button className="flex justify-center items-center border-2 border-solid border-gray-mute rounded-full group-hover:border-0 group-hover:bg-green_1 w-full h-full transition-all">
                        <RiMessengerLine className="fill-gray-mute group-hover:fill-white transition-all" size="30px" />
                    </button>
                </div>
            </div>
            <div className="border-t border-gray-400 w-[100vw] py-[20px] " style={{padding:'0 !important', opacity:'0.3'}}></div>
            <p className="text-[#A4A4A4] pt-[15px] ">Cartenft 2022. All rights reserved</p>
        </footer>
    </>
  )
}

export default Footer
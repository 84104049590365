import React from 'react';
import comingSoon from "../assets/images/coming_soon.jpg";

const ComingSoon = () => {
    
    function Click_ReturnHome(e) { window.location.href = "/"; }

    return (
    <>
        <div className="flex h-screen flex-col w-full justify-center items-center sm:px-10">
            <div className="flex flex-col w-full lg:w-8/12 border border-gray-300 justify-center items-center rounded-md" style={{ boxShadow: '-5px -4px 47px 3px rgb(198 198 198 / 30%)' }}>
                <div className="w-4/12" style={{ maxWidth: "200px" }}>
                    <svg className="scale-150 pt-5 w-full h-full" width="119" height="24" viewBox="0 0 119 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_73_590)">
                            <path d="M33.7737 11.3913C33.5575 10.6759 33.1614 10.0272 32.6229 9.50676C32.0216 8.9631 31.3015 8.56623 30.5195 8.34759C29.4797 8.0593 28.4031 7.92425 27.3239 7.94675C26.8041 7.94675 26.3163 7.96818 25.8606 8.01106L26.9769 11.0399C27.0265 11.0399 27.0775 11.0399 27.1271 11.0399C27.9474 11.0399 28.6015 11.179 29.0892 11.4572C29.576 11.7339 29.8194 12.1212 29.8194 12.6148C29.8194 13.1518 29.6346 13.5377 29.268 13.774C28.9014 14.0103 28.1863 14.2018 27.1271 14.3454C26.0679 14.4889 25.0884 14.6445 24.1899 14.824C23.3769 14.9691 22.5933 15.246 21.8702 15.6436C21.2306 16.0041 20.7031 16.533 20.3453 17.1722C19.9787 17.8214 19.7949 18.6919 19.7939 19.7837C19.7939 21.0192 20.2211 22.0233 21.0755 22.7961C21.9298 23.5688 23.2765 23.9547 25.1154 23.9537C26.0886 23.9726 27.0567 23.8103 27.97 23.4751C28.7192 23.2038 29.396 22.7653 29.9486 22.1933C29.9821 22.655 30.0693 23.1112 30.2085 23.5529H34.1343V13.94C34.1456 13.0771 34.024 12.2176 33.7737 11.3913ZM29.881 17.772C29.8831 18.2177 29.7775 18.6573 29.573 19.0538C29.37 19.4498 29.0949 19.8048 28.7617 20.1008C28.4085 20.4094 28.001 20.6502 27.5598 20.8113C27.0791 20.9884 26.57 21.0771 26.0574 21.073C24.6942 21.073 24.0132 20.5066 24.0142 19.3739C24.0142 18.7158 24.241 18.2112 24.6947 17.8602C25.1485 17.5093 25.9813 17.2226 27.1932 17.0002C27.7942 16.8964 28.3894 16.7621 28.9766 16.5979C29.325 16.508 29.6475 16.3385 29.9186 16.1028L29.881 17.772Z" fill="#001428"></path>
                            <path d="M36.2886 23.4076V8.19785H40.2474V10.0211C40.5412 9.56357 40.9081 9.15687 41.3336 8.81707C41.6893 8.54164 42.0823 8.31756 42.5009 8.15148C42.8913 7.99796 43.3006 7.89735 43.7179 7.85234C44.1285 7.81146 44.5502 7.79102 44.9829 7.79102H45.5343V11.9057C45.1482 11.8444 44.7579 11.8134 44.3669 11.813C41.7938 11.813 40.5068 13.0389 40.5058 15.4909V23.4076H36.2886Z" fill="#001428"></path>
                            <path d="M56.3079 13.2266H60.2336V17.6299C60.2366 18.4242 60.1103 19.2137 59.8595 19.9677C59.617 20.7037 59.219 21.3795 58.6922 21.9495C58.1313 22.548 57.4443 23.0154 56.6805 23.3181C55.7551 23.6743 54.7683 23.8455 53.7764 23.8221C52.6095 23.8221 51.6149 23.6541 50.7926 23.3181C50.0264 23.0212 49.3343 22.5619 48.7644 21.972C48.2349 21.4067 47.8414 20.729 47.6136 19.9902C47.3719 19.2154 47.2513 18.4083 47.256 17.597V3.75732H51.4086V7.94528H60.2336V10.8395H51.4086V17.1947C51.4086 18.1799 51.5979 18.9402 51.9765 19.4756C52.3551 20.0111 52.9771 20.2748 53.8425 20.2669C54.6858 20.2669 55.3073 20.0046 55.7069 19.4801C56.1066 18.9556 56.3069 18.2113 56.3079 17.247V13.2266Z" fill="#001428"></path>
                            <path d="M66.1415 16.8508C66.2056 18.0034 66.5561 18.9198 67.1931 19.5999C67.8301 20.2799 68.6695 20.6199 69.7111 20.6199C70.3511 20.6329 70.9832 20.4782 71.5441 20.1712C71.7908 20.0429 72.0086 19.8658 72.1843 19.6508C72.3599 19.4358 72.4897 19.1874 72.5657 18.9208H76.9136C76.4128 20.4853 75.5474 21.6903 74.3175 22.5359C73.0875 23.3815 71.6172 23.8033 69.9064 23.8013C64.585 23.8013 61.9248 21.0412 61.9258 15.5211C61.9102 14.4387 62.0851 13.362 62.4426 12.3397C62.7636 11.4291 63.2758 10.597 63.945 9.89874C64.6242 9.20594 65.45 8.67251 66.3623 8.33723C67.4126 7.96091 68.5229 7.77796 69.639 7.79728C72.1049 7.79728 73.9704 8.54912 75.2354 10.0528C76.5004 11.5565 77.1329 13.8225 77.1329 16.8508H66.1415ZM72.791 14.317C72.783 13.8132 72.6811 13.3151 72.4906 12.8483C72.324 12.4523 72.0757 12.0956 71.7619 11.8013C71.4626 11.5259 71.1091 11.3156 70.7237 11.1835C70.3371 11.0507 69.9309 10.983 69.5218 10.9831C69.1164 10.978 68.714 11.0532 68.338 11.2041C67.962 11.3551 67.6198 11.5789 67.3314 11.8626C66.7154 12.4509 66.3648 13.27 66.2797 14.32L72.791 14.317Z" fill="#001428"></path>
                            <path d="M88.0731 23.457L81.2913 12.9497V23.457H78.2114V7.81494H81.721L88.031 17.8735V7.81494H91.135V23.457H88.0731Z" fill="#00CC66"></path>
                            <path d="M96.0404 16.8505V23.457H92.6465V7.78955H104.197V10.5267H96.0404V14.0894H103.171V16.8505H96.0404Z" fill="#00CC66"></path>
                            <path d="M118.8 18.077C118.638 18.925 118.35 19.7442 117.945 20.5075C117.569 21.212 117.069 21.8434 116.468 22.3711C115.862 22.8968 115.162 23.3051 114.405 23.5752C113.574 23.8684 112.698 24.0142 111.817 24.0059C110.804 24.0138 109.799 23.838 108.849 23.4869C107.949 23.1523 107.135 22.6195 106.47 21.9284C105.774 21.1919 105.237 20.3208 104.894 19.3692C104.491 18.2293 104.296 17.0267 104.32 15.8185C104.303 14.6067 104.481 13.4 104.846 12.2437C105.155 11.2531 105.667 10.3366 106.348 9.55146C107.004 8.81775 107.818 8.24144 108.729 7.86581C109.724 7.46081 110.791 7.25987 111.866 7.27501C112.8 7.25981 113.729 7.4045 114.613 7.70278C115.39 7.97048 116.11 8.38036 116.736 8.9113C117.322 9.41395 117.804 10.0269 118.152 10.7151C118.502 11.409 118.721 12.1608 118.798 12.9332H115.406C115.224 12.104 114.757 11.3638 114.087 10.8393C113.46 10.3504 112.686 10.0869 111.89 10.0914C110.474 10.0914 109.432 10.583 108.764 11.5662C108.096 12.5493 107.762 13.904 107.763 15.63C107.763 17.376 108.093 18.7326 108.752 19.6998C109.411 20.667 110.4 21.1506 111.719 21.1506C112.729 21.1506 113.538 20.8869 114.148 20.3594C114.758 19.8319 115.178 19.0731 115.406 18.0829L118.8 18.077Z" fill="#00CC66"></path>
                            <path d="M14.1751 15.343C13.9974 16.2098 13.5855 17.0118 12.9837 17.6627C12.382 18.3136 11.6132 18.7887 10.7603 19.0368C9.90738 19.2849 9.00262 19.2965 8.14353 19.0705C7.28444 18.8445 6.50358 18.3894 5.8851 17.7542C5.26663 17.1191 4.83398 16.3279 4.63378 15.466C4.43358 14.6041 4.47342 13.7042 4.74901 12.8632C5.0246 12.0222 5.52549 11.272 6.1977 10.6935C6.86992 10.1149 7.68797 9.72998 8.5637 9.58009V5.05859C6.79038 5.21482 5.09821 5.86969 3.68449 6.94686C2.27077 8.02402 1.19376 9.47908 0.579033 11.1424C-0.035693 12.8057 -0.162803 14.6087 0.212525 16.3412C0.587854 18.0737 1.45015 19.6642 2.69888 20.9274C3.94761 22.1906 5.53131 23.0744 7.26533 23.4757C8.99934 23.877 10.8122 23.7793 12.4926 23.1939C14.1729 22.6086 15.6515 21.5598 16.756 20.1697C17.8604 18.7797 18.5451 17.1057 18.7304 15.343H14.1751Z" fill="#001428"></path>
                            <path d="M17.0702 12.8752H14.5432L14.5191 12.6778C14.4241 11.9491 14.1163 11.2642 13.634 10.708C13.1517 10.1518 12.516 9.74874 11.8058 9.54877L11.6436 9.49791V6.99561L11.911 7.04646C14.7235 7.57294 16.8268 9.87034 17.0522 12.6389L17.0702 12.8752Z" fill="#001428"></path>
                            <path d="M20.4626 12.875H17.7147V12.8002L17.7057 12.6701C17.6043 11.0886 16.9668 9.58808 15.8976 8.41407C14.8283 7.24006 13.3905 6.46202 11.8193 6.2072L11.6436 6.17729V3.42969L11.9065 3.46409C14.1854 3.75271 16.2899 4.8298 17.8518 6.50697C19.4137 8.18413 20.3336 10.3546 20.4506 12.6387L20.4626 12.875Z" fill="#001428"></path>
                            <path d="M23.888 12.875H21.1492L21.1387 12.6641C20.9058 7.58918 16.9064 3.33391 11.8359 2.76555L11.6436 2.74461V0L11.8975 0.0239317C15.09 0.328652 18.0637 1.77436 20.2681 4.09353C22.4725 6.4127 23.7585 9.44833 23.888 12.6387V12.875Z" fill="#001428"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_73_590">
                                <rect width="118.8" height="24" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="font-semibold text-xs lg:text-base pt-5 px-2">Something amazing will be constructed here...</div>
                <div className="flex justify-center w-8/12 min-w-fit pt-4">
                    <svg className="w-full h-full" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox=" 0 90 500 260" style={{ maxWidth: "600px" }}>
                        <style type="text/css">{"\
                            .st0 { fill: #FFFFFF; }\
                            .st1 { fill: #6CDD6C; }\
                            .st2 { fill: #50AA50; }\
                            .st3 { fill: #106797; }\
                            .st4 { fill-rule: evenodd; clip-rule: evenodd; fill: #A3D5E9; }\
                            .st5 { fill-rule: evenodd; clip-rule: evenodd; fill: #106797; }\
                            .st6 { fill: #A3D5E9; }\
                            .st7 { fill: #405B91; }\
                            .st8 { fill: #B6D0FB; }\
                            .st9 { fill: #8AB436; }\
                            .st10 { fill: #C1E9F4; }\
                            .st11 { fill: #415B91; }\
                        "}</style>
                        <g id="BACKGROUND"><rect className="st0" width="500" height="500"></rect></g>
                        <g id="OBJECTS">
                            <g>
                                <g>
                                    <g>
                                        <g><path className="st1" d="M317.7,334.6c-0.5-1.6-1.8-3.1-3.5-3.6s-4,0.3-4.5,1.9c-1.6-1.2-4.1-0.3-5.4,1.2c-2.1,2.4-0.5,6.4-0.1,6.6c0.4,0.3,1.4,0.2,1.4,0.2l15.7-0.1c0,0,2.9-0.2,4.2-0.7c2.7-1,4.5-5.1,2.8-7.2c-1.2-1.4-3.5-1.7-5.4-1.2C320.9,332.4,319.1,333.4,317.7,334.6"></path></g>
                                        <g><path className="st2" d="M305.3,341.5c-0.4,0-1.1,0-1.5-0.3c-0.6-0.5-2.2-4.7,0-7.3c0.9-1.1,2.3-1.8,3.7-2c0.7-0.1,1.3,0,1.9,0.3c0.3-0.5,0.9-1,1.5-1.3c1-0.5,2.3-0.6,3.4-0.3c1.6,0.5,2.9,1.6,3.6,3.2c1.5-1.1,3.2-2,4.8-2.4c1.9-0.5,4.5-0.3,5.9,1.4c0.8,1,1,2.4,0.5,4c-0.6,1.8-2,3.4-3.5,4c-1.4,0.5-4.2,0.7-4.4,0.7l-15.7,0.1C305.4,341.5,305.4,341.5,305.3,341.5zM307.9,332.8c-0.1,0-0.2,0-0.3,0c-1,0.1-2.2,0.7-3,1.6c-1.8,2.1-0.5,5.7-0.2,6c0.1,0,0.6,0.1,1,0.1l15.7-0.1c0,0,2.8-0.2,4-0.6c1.2-0.4,2.4-1.8,2.9-3.3c0.4-1.3,0.3-2.4-0.3-3.2c-1.1-1.3-3.3-1.5-4.8-1c-1.6,0.5-3.4,1.4-5,2.7c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3-0.2-0.4-0.3c-0.6-1.6-1.7-2.9-3.2-3.3c-0.8-0.2-1.8-0.2-2.6,0.2c-0.7,0.3-1.1,0.8-1.3,1.3c-0.1,0.1-0.2,0.3-0.3,0.3c-0.2,0-0.3,0-0.5-0.1C308.9,332.9,308.4,332.8,307.9,332.8z"></path></g>
                                    </g>
                                    <g><g><g><path className="st2" d="M301.1,334.1c-0.4,0.8-0.6,1.6-0.5,2.5c0.1,0.4,0.8,0.4,0.8,0c-0.1-0.8,0-1.5,0.4-2.2C302,334,301.4,333.7,301.1,334.1L301.1,334.1z"></path></g></g></g>
                                    <g><g><g><path className="st2" d="M298.9,334.6c-0.2,0.8-0.3,1.6-0.1,2.4c0.1,0.4,0.9,0.3,0.8-0.2c-0.2-0.7-0.1-1.3,0.1-2C299.8,334.4,299,334.2,298.9,334.6L298.9,334.6z"></path></g></g></g>
                                    <g><g><g><path className="st2" d="M303.2,333.2c0.5,0,0.5-0.7,0-0.7C302.7,332.5,302.7,333.2,303.2,333.2L303.2,333.2z"></path></g></g></g>
                                    <g><g><g><path className="st2" d="M305.4,336.6c0,0.2,0,0.4,0,0.6c0,0.5,0.8,0.5,0.8,0c0-0.2,0-0.4,0-0.6C306.2,336.2,305.4,336.2,305.4,336.6L305.4,336.6z"></path></g></g></g>
                                    <g><g><g><path className="st2" d="M318.6,337.7c0,0.1,0,0.2,0,0.3c0,0.5,0.8,0.5,0.8,0c0-0.1,0-0.2,0-0.3C319.4,337.2,318.6,337.2,318.6,337.7L318.6,337.7z"></path></g></g></g>
                                    <g><g><g><path className="st2" d="M317.1,336.9c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.3,0.9-0.2,0.5-0.5c-0.1-0.1-0.1-0.1-0.2-0.2C317.3,336,316.8,336.5,317.1,336.9L317.1,336.9z"></path></g></g></g>
                                </g>
                                <g><path classname="st3" d="M96.1,319.2H69.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h26.3c0.6,0,1,0.5,1,1C97.2,318.8,96.7,319.2,96.1,319.2z"></path></g>
                                <g><g><path className="st0" d="M328,227.9h-0.8c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1h-0.8c0-1,0.8-1.9,1.9-1.9C327.1,226,328,226.9,328,227.9z"></path></g></g>
                                <g><g><path className="st0" d="M330.6,184.1h-0.8c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1h-0.8c0-1,0.8-1.9,1.9-1.9C329.8,182.2,330.6,183.1,330.6,184.1z"></path></g></g>
                                <g><g><g><path className="st3" d="M237,336.3h-35.8l0.6-1.4c2.3-5.6,2.6-10.3,2.2-15.8L204,318l30.2-0.1l0,1c0,3.5,1,11.5,2.4,16L237,336.3zM204.3,334.2h29.9c-1.2-4.4-1.9-10.6-2.1-14.3l-25.9,0.1C206.5,324.9,206.2,329.2,204.3,334.2z"></path></g></g></g>
                                <g><g><g><path classname="st3" d="M191,319.9c-27.2,0-49.2-0.1-50.1-0.5c-2.3-0.8-5.4-4.1-5.4-6.3c-0.1-5-0.3-11.7-0.5-19.5
                                                c-0.7-23.5-1.8-55.8-0.4-73.6c0.2-3.3,2.9-5.8,6.1-5.8c0,0,0.1,0,0.1,0l156.6,0.2c3.3,0.1,6,2.7,6,6.1
                                                c0.1,5.2,0.3,10.5,0.5,15.9c0.7,22.6,1.5,46-1,68.1c-0.1,0.9-0.1,1.8-0.2,2.8c-0.2,4.6-0.5,10.4-6.4,11.6
                                                C293.2,319.5,235.2,319.9,191,319.9z M140.6,215.9c-2.4,0-4.3,1.8-4.5,4.2c-1.3,17.8-0.3,49.9,0.4,73.4
                                                c0.2,7.8,0.5,14.6,0.5,19.5c0,1.4,2.4,4.1,4.3,4.8c3.3,0.7,150.6,0.2,154.4-0.5c4.7-0.9,4.9-5.6,5.1-10c0-1,0.1-2,0.2-2.9
                                                c2.5-22,1.7-45.3,1-67.8c-0.2-5.4-0.3-10.7-0.5-15.9c-0.1-2.5-2-4.4-4.4-4.5L140.6,215.9C140.6,215.9,140.6,215.9,140.6,215.9z">
                                            </path>
                                </g></g></g>
                                <g>
                                    <g>
                                        <path className="st4" d="M292.9,219.8l-147.6-0.2c-2.7,0-4.9,1.8-5.1,4.2c-1.7,19.8,0.6,61,0.9,77.6c0,1.6,2.7,4,4.6,4.6
                                            c2.2,0.7,142.5,0.2,145.9-0.4c6.2-1.1,5.1-7.4,5.6-11.4c2.9-22.8,1.1-47.6,0.5-69.9C297.8,221.8,295.6,219.9,292.9,219.8z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M249.8,342h-61.4c-2.4,0-3.7-1.9-3.7-3.8c0-1.9,1.3-3.8,3.7-3.8h61.4c2.4,0,3.7,1.9,3.7,3.8
                                                C253.6,340,252.3,342,249.8,342z M188.4,336c-1.6,0-2,1.3-2,2.1c0,0.8,0.4,2.1,2,2.1h61.4c1.6,0,2-1.3,2-2.1
                                                c0-0.8-0.4-2.1-2-2.1H188.4z"></path>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path className="st5" d="M145.7,312C145.3,312,145.3,312,145.7,312"></path>
                                                </g>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path className="st3" d="M145.7,314.1c2.8,0,2.8-4.3,0-4.3C142.9,309.8,142.9,314.1,145.7,314.1L145.7,314.1z"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path className="st5" d="M154.3,312C154,312,154,312,154.3,312"></path>
                                                </g>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path className="st3" d="M154.3,314.1c2.8,0,2.8-4.3,0-4.3C151.6,309.8,151.6,314.1,154.3,314.1L154.3,314.1z"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path className="st5" d="M163,312C162.6,312,162.6,312,163,312"></path>
                                                </g>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path className="st3" d="M163,314.1c2.8,0,2.8-4.3,0-4.3C160.2,309.8,160.2,314.1,163,314.1L163,314.1z"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path className="st5" d="M219.1,217.6C219,217.6,219,217.6,219.1,217.6"></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path className="st3" d="M219.1,218.6c1.3,0,1.3-2,0-2C217.8,216.6,217.8,218.6,219.1,218.6L219.1,218.6z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <polygon className="st6" points="392.3,337.9 371.2,337.9 379.3,311.2 384.2,311.2                "></polygon>
                                    </g>
                                    <g>
                                        <rect x="369.4" y="337.9" className="st6" width="24.7" height="3.1"></rect>
                                    </g>
                                    <g>
                                        <polygon className="st3" points="373.2,331.2 371.2,337.9 392.3,337.9 390.3,331.2                "></polygon>
                                    </g>
                                    <g>
                                        <polygon className="st3" points="375.2,324.5 388.2,324.5 386.2,317.8 377.3,317.8                "></polygon>
                                    </g>
                                </g>
                                <g id="XMLID_11_">
                                    <g id="XMLID_493_">
                                        <path className="st6" d="M401,340.8h4.7v-18.2h24.1v18.2h4.7v-18.2h5v-14.5h-4v-1.3h-6.8v1.3h-21.4v-1.3h-6.8v1.3h-4.4v14.5h4.9
                                            V340.8z M437.9,309.6L437.9,309.6l-2.9,11.5h-5.2l2.9-11.5H437.9z M424.4,321.1h-5.2l2.9-11.5h5.2L424.4,321.1z M413.9,321.1
                                            h-5.2l2.9-11.5h5.2L413.9,321.1z M401,309.6h5.2l-2.9,11.5h-5.2L401,309.6z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path className="st6" d="M170,163.1l1.4-1.4c0.3-0.3,0.3-0.7,0-1l-1.2-1.2c-0.3-0.3-0.4-0.8-0.2-1.2l1-2.4c0.1-0.4,0.5-0.7,0.9-0.7
                                                h1.7c0.4,0,0.7-0.3,0.7-0.7v-2c0-0.4-0.3-0.7-0.7-0.7H172c-0.4,0-0.8-0.3-0.9-0.7l-1-2.4c-0.2-0.4-0.1-0.9,0.2-1.2l1.2-1.2
                                                c0.3-0.3,0.3-0.7,0-1l-1.4-1.4c-0.3-0.3-0.7-0.3-1,0l-1.2,1.2c-0.3,0.3-0.8,0.4-1.2,0.2l-2.4-1c-0.4-0.1-0.7-0.5-0.7-0.9v-1.7
                                                c0-0.4-0.3-0.7-0.7-0.7h-2c-0.4,0-0.7,0.3-0.7,0.7v1.7c0,0.4-0.3,0.8-0.7,0.9l-2.4,1c-0.4,0.2-0.9,0.1-1.2-0.2l-1.2-1.2
                                                c-0.3-0.3-0.7-0.3-1,0l-1.4,1.4c-0.3,0.3-0.3,0.7,0,1l1.2,1.2c0.3,0.3,0.4,0.8,0.2,1.2l-1,2.4c-0.1,0.4-0.5,0.7-0.9,0.7h-1.7
                                                c-0.4,0-0.7,0.3-0.7,0.7v2c0,0.4,0.3,0.7,0.7,0.7h1.7c0.4,0,0.8,0.3,0.9,0.7l1,2.4c0.2,0.4,0.1,0.9-0.2,1.2l-1.2,1.2
                                                c-0.3,0.3-0.3,0.7,0,1l1.4,1.4c0.3,0.3,0.7,0.3,1,0l1.2-1.2c0.3-0.3,0.8-0.4,1.2-0.2l2.4,1c0.4,0.1,0.7,0.5,0.7,0.9v1.7
                                                c0,0.4,0.3,0.7,0.7,0.7h2c0.4,0,0.7-0.3,0.7-0.7v-1.7c0-0.4,0.3-0.8,0.7-0.9l2.4-1c0.4-0.2,0.9-0.1,1.2,0.2l1.2,1.2
                                                C169.3,163.3,169.8,163.3,170,163.1z M161.8,159.2c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8
                                                C167.6,156.6,165,159.2,161.8,159.2z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st6" d="M183.5,168.6l-0.4-1.2c-0.1-0.2-0.3-0.4-0.6-0.3l-1,0.3c-0.2,0.1-0.5,0-0.7-0.3l-1-1.3
                                                c-0.2-0.2-0.2-0.5-0.1-0.7l0.5-0.9c0.1-0.2,0-0.5-0.2-0.6L179,163c-0.2-0.1-0.5,0-0.6,0.2l-0.5,0.9c-0.1,0.2-0.4,0.4-0.7,0.3
                                                l-1.6-0.2c-0.2,0-0.5-0.2-0.6-0.4l-0.3-1c-0.1-0.2-0.3-0.4-0.6-0.3l-1.2,0.4c-0.2,0.1-0.4,0.3-0.3,0.6l0.3,1
                                                c0.1,0.2,0,0.5-0.3,0.7l-1.3,1c-0.2,0.2-0.5,0.2-0.7,0.1l-0.9-0.5c-0.2-0.1-0.5,0-0.6,0.2l-0.6,1.1c-0.1,0.2,0,0.5,0.2,0.6
                                                l0.9,0.5c0.2,0.1,0.4,0.4,0.3,0.7l-0.2,1.6c0,0.2-0.2,0.5-0.4,0.6l-1,0.3c-0.2,0.1-0.4,0.3-0.3,0.6l0.4,1.2
                                                c0.1,0.2,0.3,0.4,0.6,0.3l1-0.3c0.2-0.1,0.5,0,0.7,0.2l1,1.3c0.2,0.2,0.2,0.5,0.1,0.7l-0.5,0.9c-0.1,0.2,0,0.5,0.2,0.6l1.1,0.6
                                                c0.2,0.1,0.5,0,0.6-0.2l0.5-0.9c0.1-0.2,0.4-0.4,0.7-0.3l1.6,0.2c0.3,0,0.5,0.2,0.6,0.4l0.3,1c0.1,0.2,0.3,0.4,0.6,0.3l1.2-0.4
                                                c0.2-0.1,0.4-0.3,0.3-0.6l-0.3-1c-0.1-0.2,0-0.5,0.2-0.7l1.3-1c0.2-0.2,0.5-0.2,0.7-0.1l0.9,0.5c0.2,0.1,0.5,0,0.6-0.2l0.6-1.1
                                                c0.1-0.2,0-0.5-0.2-0.6l-0.9-0.5c-0.2-0.1-0.4-0.4-0.3-0.7l0.2-1.6c0-0.2,0.2-0.5,0.4-0.6l1-0.3
                                                C183.4,169,183.6,168.8,183.5,168.6z M179,171.9c-0.9,1.8-3.1,2.4-4.9,1.5c-1.8-0.9-2.4-3.1-1.5-4.9c0.9-1.8,3.1-2.4,4.9-1.5
                                                C179.3,168,179.9,170.2,179,171.9z"></path>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <path className="st3" d="M242.4,191.3h-32.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h32.6c0.6,0,1,0.5,1,1
                                        C243.4,190.9,243,191.3,242.4,191.3z"></path>
                                </g>
                                <g>
                                    <path className="st3" d="M176,206.1h-52.7c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1H176c0.6,0,1,0.5,1,1C177.1,205.7,176.6,206.1,176,206.1z
                                        "></path>
                                </g>
                                <g>
                                    <path className="st3" d="M428.5,234.2h-52.7c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h52.7c0.6,0,1,0.5,1,1
                                        C429.5,233.7,429.1,234.2,428.5,234.2z"></path>
                                </g>
                                <g>
                                    <path className="st3" d="M449.2,343H50.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h398.4c0.6,0,1,0.5,1,1C450.2,342.5,449.8,343,449.2,343z"></path>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path className="st6" d="M303.1,178.3c-2.2,0-4.3,0.9-5.9,2.4c-2.7,2.7-3.2,6.9-1.3,10.1l0.1,0.2l-8.5,8.5c0,0-0.4,0.4-0.4,1
                                                c0,0.4,0.2,0.9,0.6,1.3c0.5,0.5,0.9,0.7,1.4,0.7c0.6,0,1-0.3,1-0.3l8.4-8.5l0.2,0.1c1.3,0.8,2.7,1.1,4.2,1.1
                                                c2.2,0,4.3-0.9,5.9-2.4c3.3-3.3,3.3-8.6,0-11.8C307.4,179.2,305.3,178.3,303.1,178.3z M303.1,192.5c-3.2,0-5.9-2.6-5.9-5.9
                                                c0-3.2,2.6-5.9,5.9-5.9l0,0c3.2,0,5.9,2.6,5.9,5.9C308.9,189.9,306.3,192.5,303.1,192.5z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st6" d="M300,187.9h6.1v-1.6H300V187.9z"></path>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st3" d="M147.7,341.1c-0.6,0-1-0.5-1-1v-79.9c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1V340
                                            C148.8,340.6,148.3,341.1,147.7,341.1z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,341.1c-0.6,0-1-0.5-1-1v-79.9c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1V340
                                            C167.3,340.6,166.9,341.1,166.3,341.1z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,266.7h-18.6c-0.6,0-1-0.5-1-1s0.5-1,1-1h18.6c0.6,0,1,0.5,1,1S166.9,266.7,166.3,266.7z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,275.8h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                            C167.3,275.3,166.9,275.8,166.3,275.8z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,284.9h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                            C167.3,284.4,166.9,284.9,166.3,284.9z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,294h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1C167.3,293.6,166.9,294,166.3,294z
                                            "></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,303.2h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                            C167.3,302.7,166.9,303.2,166.3,303.2z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,312.3h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                            C167.3,311.8,166.9,312.3,166.3,312.3z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,321.4h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                            C167.3,320.9,166.9,321.4,166.3,321.4z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M166.3,330.5h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                            C167.3,330.1,166.9,330.5,166.3,330.5z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st3" d="M122.5,264c-0.6,0-1-0.5-1-1v-4c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1v4C123.5,263.6,123,264,122.5,264z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M124.5,262h-4c-0.6,0-1-0.5-1-1s0.5-1,1-1h4c0.6,0,1,0.5,1,1S125.1,262,124.5,262z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st3" d="M215.7,119.6c-0.6,0-1-0.5-1-1v-4c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1v4C216.8,119.1,216.3,119.6,215.7,119.6z
                                            "></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M217.8,117.6h-4c-0.6,0-1-0.5-1-1s0.5-1,1-1h4c0.6,0,1,0.5,1,1S218.3,117.6,217.8,117.6z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st3" d="M248.4,328.7c-0.1,0-0.2,0-0.3,0l-3.9-1.1c-0.6-0.2-0.9-0.7-0.7-1.3c0.2-0.6,0.7-0.9,1.3-0.7l3.9,1.1
                                            c0.6,0.2,0.9,0.7,0.7,1.3C249.3,328.4,248.9,328.7,248.4,328.7z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M245.9,330c-0.1,0-0.2,0-0.3,0c-0.6-0.2-0.9-0.7-0.7-1.3l1.1-3.9c0.2-0.6,0.7-0.9,1.3-0.7
                                            c0.6,0.2,0.9,0.7,0.7,1.3l-1.1,3.9C246.8,329.8,246.4,330,245.9,330z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st3" d="M228,206.7c-0.1,0-0.2,0-0.3,0l-3.9-1.1c-0.6-0.2-0.9-0.7-0.7-1.3c0.2-0.6,0.7-0.9,1.3-0.7l3.9,1.1
                                            c0.6,0.2,0.9,0.7,0.7,1.3C228.9,206.4,228.5,206.7,228,206.7z"></path>
                                    </g>
                                    <g>
                                        <path className="st3" d="M225.5,208.1c-0.1,0-0.2,0-0.3,0c-0.6-0.2-0.9-0.7-0.7-1.3l1.1-3.9c0.2-0.6,0.7-0.9,1.3-0.7
                                            c0.6,0.2,0.9,0.7,0.7,1.3l-1.1,3.9C226.4,207.8,226,208.1,225.5,208.1z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <circle className="st6" cx="225.3" cy="168.5" r="14.4"></circle>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <circle className="st0" cx="225.3" cy="168.5" r="10.8"></circle>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path className="st3" d="M225.3,179.7c-6.2,0-11.2-5-11.2-11.2s5-11.2,11.2-11.2s11.2,5,11.2,11.2S231.5,179.7,225.3,179.7z
                                                        M225.3,158.1c-5.7,0-10.4,4.7-10.4,10.4s4.7,10.4,10.4,10.4c5.7,0,10.4-4.7,10.4-10.4S231,158.1,225.3,158.1z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path className="st3" d="M225.3,168.9c-0.2,0-0.4-0.2-0.4-0.4V163c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v5.5
                                                    C225.7,168.7,225.5,168.9,225.3,168.9z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st3" d="M231.7,168.9h-6.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h6.4c0.2,0,0.4,0.2,0.4,0.4
                                                    C232.2,168.7,232,168.9,231.7,168.9z"></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <circle className="st7" cx="225.3" cy="158.9" r="0.4"></circle>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -46.3674 211.4673)" className="st7" cx="232.1" cy="161.7" rx="0.4" ry="0.4"></ellipse>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <circle className="st7" cx="234.9" cy="168.5" r="0.4"></circle>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <ellipse transform="matrix(0.7072 -0.707 0.707 0.7072 -55.9446 215.4091)" className="st7" cx="232.1" cy="175.2" rx="0.4" ry="0.4"></ellipse>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <circle className="st7" cx="225.3" cy="178" r="0.4"></circle>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <ellipse transform="matrix(0.707 -0.7072 0.7072 0.707 -59.9044 205.8822)" className="st7" cx="218.5" cy="175.2" rx="0.4" ry="0.4"></ellipse>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <circle className="st7" cx="215.7" cy="168.5" r="0.4"></circle>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -50.333 201.8934)" className="st7" cx="218.5" cy="161.7" rx="0.4" ry="0.4"></ellipse>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path className="st7" d="M246.4,163h-6c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h6c0.2,0,0.4,0.2,0.4,0.4
                                                    C246.8,162.8,246.6,163,246.4,163z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st7" d="M246.4,174.8h-6c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h6c0.2,0,0.4,0.2,0.4,0.4
                                                    C246.8,174.6,246.6,174.8,246.4,174.8z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st7" d="M253.9,168.9h-11.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h11.3c0.2,0,0.4,0.2,0.4,0.4
                                                    C254.3,168.7,254.1,168.9,253.9,168.9z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <rect x="266.9" y="324.5" className="st3" width="6.7" height="6.7"></rect>
                                        <path className="st3" d="M274.1,331.7h-7.7V324h7.7V331.7z M267.5,330.7h5.6V325h-5.6V330.7z"></path>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path className="st3" d="M282.1,326h-6c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6c0.2,0,0.4,0.2,0.4,0.4S282.3,326,282.1,326z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st3" d="M284.4,330.6h-8.2c-0.3,0-0.6-0.2-0.6-0.4c0-0.2,0.3-0.4,0.6-0.4h8.2c0.3,0,0.6,0.2,0.6,0.4
                                                    C284.9,330.4,284.7,330.6,284.4,330.6z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st3" d="M287.3,328.1H276c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h11.3c0.2,0,0.4,0.2,0.4,0.4
                                                    C287.7,328,287.6,328.1,287.3,328.1z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <rect x="120.2" y="226.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 247.1112 459.6004)" className="st0" width="6.7" height="6.7"></rect>
                                        <path className="st3" d="M127.4,233.7h-7.7v-7.7h7.7V233.7z M120.7,232.6h5.6V227h-5.6V232.6z"></path>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path className="st3" d="M111.8,227.9h6c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-6c-0.2,0-0.4,0.2-0.4,0.4
                                                    C111.3,227.7,111.5,227.9,111.8,227.9z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st3" d="M109.5,232.5h8.2c0.3,0,0.6-0.2,0.6-0.4s-0.3-0.4-0.6-0.4h-8.2c-0.3,0-0.6,0.2-0.6,0.4
                                                    S109.2,232.5,109.5,232.5z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st3" d="M106.5,230.1h11.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-11.3c-0.2,0-0.4,0.2-0.4,0.4
                                                    C106.1,229.9,106.3,230.1,106.5,230.1z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <rect x="324" y="251.6" className="st3" width="2.1" height="89.2"></rect>
                                    </g>
                                    <g>
                                        <path className="st6" d="M325.3,262.4h-0.5c-3.4,0-6.2-2.8-6.2-6.2V248c0-3.4,2.8-6.2,6.2-6.2h0.5c3.4,0,6.2,2.8,6.2,6.2v8.2
                                            C331.4,259.6,328.7,262.4,325.3,262.4z"></path>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M333.4,256.4c0.7,0.1,1.5,0.2,2.2,0.4c0.5,0.1,0.7-0.7,0.2-0.7c-0.7-0.1-1.5-0.2-2.2-0.4
                                                C333.1,255.6,332.9,256.3,333.4,256.4L333.4,256.4z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M333.3,251.6c0.6-0.1,1.1-0.1,1.7-0.1c0.5,0,0.5-0.7,0-0.8c-0.6-0.1-1.3,0-1.9,0.1
                                                C332.6,251,332.8,251.7,333.3,251.6L333.3,251.6z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M333.1,247.3c0.6-0.2,1.1-0.4,1.7-0.7c0.5-0.2,0.3-0.9-0.2-0.8c-0.6,0.2-1.1,0.4-1.7,0.7
                                                C332.4,246.7,332.6,247.5,333.1,247.3L333.1,247.3z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M314,257.1c0.6-0.1,1.1-0.2,1.7-0.4c0.5-0.2,0.3-0.9-0.2-0.8c-0.5,0.2-1.1,0.3-1.7,0.4
                                                C313.3,256.4,313.5,257.2,314,257.1L314,257.1z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M313,251.6c0.7,0,1.4,0.1,2.1,0.3c0.5,0.1,0.7-0.6,0.2-0.8c-0.8-0.2-1.5-0.3-2.3-0.4
                                                C312.5,250.8,312.5,251.6,313,251.6L313,251.6z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M313.7,245.2c0.6,0.2,1.1,0.4,1.7,0.5c0.5,0.2,0.7-0.6,0.2-0.8c-0.6-0.2-1.1-0.4-1.7-0.5
                                                C313.4,244.3,313.2,245,313.7,245.2L313.7,245.2z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <polygon className="st3" points="320.2,342 321.6,330 328.3,330 329.9,341.8              "></polygon>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path className="st1" d="M129.9,284.9c-0.3-1.9-1.6-6.8-4.6-5.2c-4.8,2.5,1,12-4.8,13.7c-0.3-3.8-1.1-6.4-2.8-6.2
                                                    c-3.5,0.3-2.6,8.5-2.7,10.4c-0.1,1.8-0.1,3.7-0.2,5.6c-4.2,0.7-3.3-10.1-5.9-11.6c-3.9-2.3-4.8,2.9-4.7,5.3
                                                    c0.2,4.1,1.3,9,5.1,11.1c1.3,0.7,3.5,1.5,5.2,1.4c0,3.3,0.1,6.5,0.7,9.6c0,0,0.1,0,0.1,0c0,0,0,0,0,0l2.3,0.1c0,0,0,0,0,0
                                                    c0,1,1.6,1,1.6,0c0-2,1.2-11.6,1.4-19.8c1.9,0.3,4.2-0.6,5.5-1.6C130.1,294.8,130.6,289.4,129.9,284.9z"></path>
                                            </g>
                                            <g>
                                                <path className="st2" d="M118.4,320.3c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.4-0.4-0.5-0.7l-1.7-0.1l-0.6,0l-0.1-0.4
                                                    c-0.5-2.6-0.7-5.6-0.7-9.2c-1.9,0-4-0.9-5-1.5c-4.1-2.3-5.2-7.5-5.3-11.5c-0.1-1.6,0.3-4.9,2.1-6c0.9-0.5,2-0.4,3.3,0.3
                                                    c1.2,0.7,1.7,2.9,2.2,5.5c0.7,3.5,1.4,6,3,6l0-0.9c0.1-1.5,0.1-2.9,0.2-4.4c0-0.3,0-0.7,0-1.2c-0.1-3.2-0.3-9.3,3.1-9.6
                                                    c0.5-0.1,1,0.1,1.4,0.5c0.9,0.8,1.6,2.6,1.8,5.6c2.1-1,2-3.4,1.9-6.3c-0.1-2.9-0.3-5.9,2.3-7.2c0.8-0.4,1.6-0.5,2.3-0.1
                                                    c2.1,1.1,2.8,5.2,2.9,5.7l0,0c0.6,3.5,0.8,9.8-3.9,13.2c-1.3,1-3.5,1.8-5.4,1.7c-0.1,5.4-0.7,11.2-1,15.2
                                                    c-0.2,2-0.3,3.5-0.3,4.2c0,0.5-0.2,0.8-0.6,1C118.9,320.2,118.7,320.3,118.4,320.3z M115.6,318.5l2.5,0.1l0,0.4
                                                    c0,0.2,0.1,0.3,0.1,0.3c0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.2-0.1,0.2-0.3c0-0.7,0.1-2.2,0.3-4.2c0.4-4,0.9-10.2,1-15.6l0-0.5
                                                    l0.5,0.1c1.7,0.3,4-0.6,5.2-1.5c4.3-3.2,4.1-9.1,3.5-12.3l0,0c-0.2-1.4-1-4.3-2.4-5c-0.5-0.2-1-0.2-1.5,0.1
                                                    c-2,1.1-1.9,3.6-1.8,6.4c0.1,3,0.3,6.4-3.1,7.4l-0.5,0.2l0-0.5c-0.3-3.8-1-5.1-1.6-5.6c-0.2-0.2-0.5-0.3-0.8-0.3
                                                    c-2.6,0.2-2.4,5.9-2.3,8.7c0,0.6,0,1,0,1.3c0,1.4-0.1,2.9-0.2,4.4l-0.1,1.6l-0.3,0.1c-2.8,0.5-3.6-3.3-4.4-6.7
                                                    c-0.5-2.2-0.9-4.4-1.8-4.9c-1-0.6-1.8-0.7-2.4-0.3c-1.3,0.8-1.7,3.6-1.6,5.2c0.2,3.7,1.2,8.7,4.9,10.8c1.3,0.7,3.4,1.4,5,1.3
                                                    l0.5,0l0,0.5C115,313,115.2,315.9,115.6,318.5z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path className="st3" d="M125.3,341h-16.9c-0.9,0-1.6-0.6-1.8-1.5l-3.8-19.7l1.6-0.3l3.8,19.7c0,0.2,0.2,0.2,0.2,0.2h16.9
                                                        c0,0,0.2,0,0.2-0.2l3.8-19.7l1.6,0.3l-3.8,19.7C126.9,340.4,126.2,341,125.3,341z"></path>
                                                </g>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path className="st6" d="M103,313.5h28c0.5,0,0.9,0.5,0.9,1.2v4.3c0,0.6-0.4,1.2-0.9,1.2h-28c-0.5,0-0.9-0.5-0.9-1.2v-4.3
                                                        C102.1,314,102.5,313.5,103,313.5z"></path>
                                                </g>
                                                <g>
                                                    <path className="st7" d="M131,320.5h-28c-0.7,0-1.3-0.7-1.3-1.6v-4.3c0-0.9,0.6-1.6,1.3-1.6h28c0.7,0,1.3,0.7,1.3,1.6v4.3
                                                        C132.3,319.8,131.7,320.5,131,320.5z M103,313.9c-0.2,0-0.4,0.3-0.4,0.7v4.3c0,0.4,0.2,0.7,0.4,0.7h28c0.2,0,0.4-0.3,0.4-0.7
                                                        v-4.3c0-0.4-0.2-0.7-0.4-0.7H103z"></path>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <path className="st8" d="M131,320.5h-28c-0.7,0-1.2-0.7-1.2-1.5v-4.3c0-0.9,0.6-1.5,1.2-1.5h28c0.7,0,1.2,0.7,1.2,1.5v4.3
                                                        C132.2,319.8,131.7,320.5,131,320.5z M103,313.8c-0.2,0-0.5,0.3-0.5,0.8v4.3c0,0.5,0.3,0.8,0.5,0.8h28c0.2,0,0.5-0.3,0.5-0.8
                                                        v-4.3c0-0.5-0.3-0.8-0.5-0.8H103z"></path>
                                                </g>
                                                <g>
                                                    <path className="st3" d="M131,320.9h-28c-0.9,0-1.7-0.9-1.7-2v-4.3c0-1.1,0.8-2,1.7-2h28c0.9,0,1.7,0.9,1.7,2v4.3
                                                        C132.7,320,131.9,320.9,131,320.9z M103,314.3c0,0-0.1,0.2-0.1,0.4v4.3c0,0.2,0.1,0.4,0.1,0.4l28,0c0,0,0.1-0.1,0.1-0.4v-4.3
                                                        c0-0.2-0.1-0.4-0.1-0.4L103,314.3z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M106,296.7c0.5,0.1,0.9,0.1,1.4,0.2c0.5,0.1,0.5-0.7,0-0.8c-0.5-0.1-0.9-0.1-1.4-0.2
                                                C105.5,295.9,105.5,296.7,106,296.7L106,296.7z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M108,290.6c0,0.2,0,0.5,0,0.7c0,0.5,0.8,0.5,0.8,0c0-0.2,0-0.5,0-0.7C108.8,290.1,108,290.1,108,290.6
                                                L108,290.6z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M110.7,297.8c0.3-0.2,0.7-0.4,1-0.6c0.4-0.2,0-0.9-0.4-0.7c-0.3,0.2-0.7,0.4-1,0.6
                                                C109.9,297.3,110.3,298,110.7,297.8L110.7,297.8z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M106.7,306.7c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0-1-0.4-0.7c-0.4,0.3-0.8,0.6-1.2,0.9
                                                C105.9,306.3,106.3,307,106.7,306.7L106.7,306.7z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M103.8,300c0.3,0.1,0.6,0.2,0.9,0.3c0.5,0.2,0.7-0.6,0.2-0.8c-0.3-0.1-0.6-0.2-0.9-0.3
                                                C103.6,299.1,103.4,299.8,103.8,300L103.8,300z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M109.5,303.6c0.2,0,0.3,0.1,0.4,0.2c0.3,0.4,0.9-0.2,0.6-0.5c-0.2-0.3-0.6-0.4-1-0.5
                                                C109,302.8,109,303.6,109.5,303.6L109.5,303.6z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M111.2,307.3c-0.1,0.5,0,1,0.2,1.5c0.2,0.5,0.9,0.1,0.7-0.4c-0.1-0.3-0.2-0.6-0.1-0.9
                                                C112,307,111.3,306.8,111.2,307.3L111.2,307.3z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M115.2,307.4c0.2,0.1,0.5,0.1,0.7,0.2c0.5,0.1,0.7-0.6,0.2-0.8c-0.2-0.1-0.5-0.1-0.7-0.2
                                                C115,306.6,114.8,307.3,115.2,307.4L115.2,307.4z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M117.7,299.8c0,0.4,0.1,0.8,0.5,1.1c0.4,0.3,0.9-0.2,0.6-0.5c-0.2-0.2-0.3-0.3-0.2-0.6
                                                C118.6,299.3,117.8,299.3,117.7,299.8L117.7,299.8z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st9" d="M116.7,294.2c-0.2,0.3-0.3,0.6-0.2,1c0.1,0.5,0.8,0.3,0.8-0.2c0-0.2,0-0.3,0.1-0.4
                                                C117.7,294.2,117,293.8,116.7,294.2L116.7,294.2z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M121,296.5c0,0.2,0,0.5,0,0.7c0,0.5,0.8,0.5,0.8,0c0-0.2,0-0.5,0-0.7C121.8,296,121,296,121,296.5
                                                L121,296.5z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M113.8,295.3c0.2,0.1,0.3,0.2,0.5,0.3c0.4,0.3,0.8-0.4,0.4-0.7c-0.2-0.1-0.3-0.2-0.5-0.3
                                                C113.7,294.3,113.4,295,113.8,295.3L113.8,295.3z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M115.1,288.8c0.2,0.2,0.2,0.4,0.2,0.6c0,0.5,0.8,0.5,0.8,0c0-0.4-0.2-0.8-0.5-1.1
                                                C115.3,287.9,114.8,288.5,115.1,288.8L115.1,288.8z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M117,286.5c0,0.3,0,0.7,0,1c0,0.5,0.8,0.5,0.8,0c0-0.3,0-0.7,0-1C117.8,286,117,286,117,286.5L117,286.5z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M121.8,291.1c0.1,0.1,0.2,0.2,0.3,0.4c0.3,0.4,0.9-0.2,0.5-0.6c-0.1-0.1-0.2-0.2-0.3-0.4
                                                C122.1,290.1,121.5,290.7,121.8,291.1L121.8,291.1z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M122.6,286.4c0.3,0,0.7,0,1,0.1c0.5,0,0.5-0.7,0-0.8c-0.3,0-0.7,0-1-0.1
                                                C122.1,285.6,122.1,286.4,122.6,286.4L122.6,286.4z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M123.7,280.2c0.2,0.2,0.5,0.5,0.7,0.7c0.4,0.4,0.9-0.2,0.5-0.5c-0.2-0.2-0.5-0.5-0.7-0.7
                                                C123.9,279.3,123.4,279.8,123.7,280.2L123.7,280.2z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M126.7,278.2c0,0.4,0,0.8,0,1.2c0,0.5,0.8,0.5,0.8,0c0-0.4,0-0.8,0-1.2
                                                C127.5,277.7,126.7,277.7,126.7,278.2L126.7,278.2z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M128.1,285.5c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.4-0.4-0.8-0.7-0.4c-0.1,0.2-0.2,0.3-0.3,0.5
                                                C127.2,285.5,127.9,285.9,128.1,285.5L128.1,285.5z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M125.5,293.1c0.3-0.1,0.6-0.2,0.9-0.3c0.5-0.2,0.3-0.9-0.2-0.8c-0.3,0.1-0.6,0.2-0.9,0.3
                                                C124.8,292.5,125,293.3,125.5,293.1L125.5,293.1z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M128.9,292c0.3-0.1,0.6-0.1,0.9-0.2c0.5-0.1,0.3-0.9-0.2-0.8c-0.3,0.1-0.6,0.1-0.9,0.2
                                                C128.2,291.4,128.4,292.1,128.9,292L128.9,292z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M126.7,295.6c0,0.1,0,0.1,0,0.2c0,0.5,0.8,0.5,0.8,0c0-0.1,0-0.1,0-0.2
                                                C127.5,295.1,126.7,295.1,126.7,295.6L126.7,295.6z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M120,307.6c0.2-0.1,0.3-0.2,0.5-0.3c0.4-0.3,0-1-0.4-0.7c-0.2,0.1-0.3,0.2-0.5,0.3
                                                C119.2,307.2,119.6,307.8,120,307.6L120,307.6z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M119.6,310.4c0.3-0.3,0.6-0.5,1.1-0.6c0.5-0.1,0.3-0.9-0.2-0.8c-0.5,0.1-1,0.4-1.4,0.8
                                                C118.7,310.2,119.2,310.8,119.6,310.4L119.6,310.4z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M116.4,310.9c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.9,0.1,0.7-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
                                                C116.8,310,116.2,310.4,116.4,310.9L116.4,310.9z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M113.4,311.7c0.4,0.2,0.9,0.3,1.3,0.2c0.5-0.1,0.3-0.8-0.2-0.8c-0.3,0.1-0.5,0-0.7-0.1
                                                C113.4,310.8,113,311.5,113.4,311.7L113.4,311.7z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M119.8,304.3c0.5-0.1,1-0.1,1.6-0.2c0.5-0.1,0.5-0.8,0-0.8c-0.5,0.1-1,0.1-1.6,0.2
                                                C119.4,303.6,119.4,304.4,119.8,304.3L119.8,304.3z"></path>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path className="st10" d="M189.5,270.3c8.4-7.6,16.7-15.1,25.1-22.7c1.2-1.1-0.6-3-1.8-1.8c-8.4,7.6-16.7,15.1-25.1,22.7
                                                C186.4,269.5,188.3,271.4,189.5,270.3L189.5,270.3z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st10" d="M217.9,281.8c8.4-7.6,16.7-15.1,25.1-22.7c1.2-1.1-0.6-3-1.8-1.8c-8.4,7.6-16.7,15.1-25.1,22.7
                                                C214.8,281.1,216.7,283,217.9,281.8L217.9,281.8z"></path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st10" d="M187.1,290.4c17.5-17.1,35.1-34.2,52.6-51.3c1.2-1.2-0.6-3-1.8-1.8c-17.5,17.1-35.1,34.2-52.6,51.3
                                                C184,289.8,185.9,291.6,187.1,290.4L187.1,290.4z"></path>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <rect x="266.4" y="130.8" className="st3" width="1.1" height="72.4"></rect>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <circle className="st3" cx="266.9" cy="203.3" r="4.6"></circle>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <path className="st3" d="M267,218.7c-0.1,0-1.6,0-2.7-1.1c-0.7-0.7-1.1-1.7-1.1-3c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6
                                                        c0,0.9,0.3,1.7,0.7,2.2c0.7,0.7,1.8,0.8,1.8,0.8c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1c-0.3,0-0.6-0.3-0.6-0.6v-3.4
                                                        c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v2.8c2.1,0.3,3.7,2.1,3.7,4.3C271.3,216.7,269.4,218.7,267,218.7
                                                        C267,218.7,267,218.7,267,218.7z"></path>
                                                </g>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path className="st3" d="M242.9,221.5c-0.2,0-0.4-0.2-0.5-0.5c0-0.3,0.2-0.6,0.5-0.6l24.9-3.7c0.3,0,0.6,0.2,0.6,0.5
                                                        c0,0.3-0.2,0.6-0.5,0.6l-24.9,3.7C243,221.5,242.9,221.5,242.9,221.5z"></path>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <path className="st3" d="M290.6,229.8c-0.1,0-0.1,0-0.2-0.1l-22.9-11.9c-0.3-0.1-0.4-0.5-0.2-0.8c0.1-0.3,0.5-0.4,0.8-0.2
                                                        l22.9,11.9c0.3,0.1,0.4,0.5,0.2,0.8C291.1,229.7,290.9,229.8,290.6,229.8z"></path>
                                                </g>
                                            </g>
                                            <g>
                                                <path className="st0" d="M288.8,277.6l-61-10.6c-3.2-0.6-5.3-3.6-4.8-6.8l6.1-35.1c0.6-3.2,3.6-5.3,6.8-4.8l61,10.6
                                                    c3.2,0.6,5.3,3.6,4.8,6.8l-6.1,35.1C295,276,292,278.1,288.8,277.6z"></path>
                                                <path className="st3" d="M289.6,278.4c-0.4,0-0.8,0-1.2-0.1l-60.5-10.5c-1.8-0.3-3.4-1.3-4.4-2.8c-1.1-1.5-1.5-3.3-1.1-5.1l6-34.7
                                                    c0.6-3.7,4.2-6.2,7.9-5.6l60.5,10.5c1.8,0.3,3.4,1.3,4.4,2.8c1.1,1.5,1.5,3.3,1.2,5.1l-6,34.7
                                                    C295.8,276,292.9,278.4,289.6,278.4z M235.1,221c-2.5,0-4.8,1.8-5.2,4.4l-6,34.7c-0.2,1.4,0.1,2.8,0.9,4
                                                    c0.8,1.2,2,1.9,3.4,2.2l60.5,10.5c2.9,0.5,5.6-1.4,6.1-4.3l6-34.7c0.2-1.4-0.1-2.8-0.9-4c-0.8-1.2-2-1.9-3.4-2.2L236,221.1
                                                    C235.7,221.1,235.4,221,235.1,221z"></path>
                                            </g>
                                            <g>
                                                <path className="st6" d="M288.3,273.8l-58.8-10.2c-2-0.4-3.4-2.3-3.1-4.3l5.6-32c0.4-2,2.3-3.4,4.3-3.1l58.8,10.2
                                                    c2,0.4,3.4,2.3,3.1,4.3l-5.6,32C292.3,272.8,290.3,274.1,288.3,273.8z"></path>
                                                <path className="st3" d="M288.8,274.6c-0.3,0-0.5,0-0.8-0.1l-58.5-10.2c-1.2-0.2-2.3-0.9-3-1.9c-0.7-1-1-2.3-0.8-3.5l5.5-31.8
                                                    c0.4-2.5,2.9-4.2,5.4-3.8l58.5,10.2c2.5,0.4,4.2,2.9,3.8,5.4l-5.5,31.8c-0.2,1.2-0.9,2.3-1.9,3
                                                    C290.7,274.3,289.8,274.6,288.8,274.6z M288.3,273c0.8,0.1,1.6,0,2.3-0.5c0.7-0.5,1.1-1.2,1.3-2l5.5-31.8
                                                    c0.3-1.7-0.8-3.3-2.5-3.6l-58.5-10.2c-1.7-0.3-3.3,0.8-3.6,2.5l-5.5,31.8c-0.1,0.8,0,1.6,0.5,2.3c0.5,0.7,1.2,1.1,2,1.3
                                                    L288.3,273z"></path>
                                            </g>
                                            <g>
                                                <polygon className="st0" points="260.4,268.9 275.6,255 284,273                      "></polygon>
                                                <path className="st11" d="M284.9,273.7l-25.6-4.5l16.5-15.1L284.9,273.7z M261.5,268.6l21.6,3.8l-7.7-16.5L261.5,268.6z"></path>
                                            </g>
                                            <g>
                                                <polygon className="st0" points="230.3,263.7 257.3,239 272.2,271                        "></polygon>
                                                <path className="st11" d="M273.1,271.7l-43.9-7.6l28.3-25.9L273.1,271.7z M231.5,263.4l39.9,6.9l-14.2-30.5L231.5,263.4z"></path>
                                            </g>
                                            <g>
                                                <ellipse transform="matrix(0.1712 -0.9852 0.9852 0.1712 -2.6783 482.8634)" className="st0" cx="285.7" cy="243" rx="3.8" ry="3.8"></ellipse>
                                                <path className="st11" d="M285.7,247.3c-0.2,0-0.5,0-0.7-0.1c-2.3-0.4-3.9-2.6-3.5-5c0.2-1.1,0.8-2.1,1.8-2.8
                                                    c0.9-0.7,2.1-0.9,3.2-0.7c1.1,0.2,2.1,0.8,2.8,1.8c0.7,0.9,0.9,2.1,0.7,3.2c-0.2,1.1-0.8,2.1-1.8,2.8
                                                    C287.4,247,286.5,247.3,285.7,247.3z M285.7,239.8c-0.7,0-1.3,0.2-1.9,0.6c-0.7,0.5-1.2,1.2-1.3,2.1c-0.1,0.9,0,1.7,0.5,2.4
                                                    c0.5,0.7,1.2,1.2,2.1,1.3c0.9,0.1,1.7,0,2.4-0.5c0.7-0.5,1.2-1.2,1.3-2.1l0,0c0.3-1.8-0.9-3.5-2.6-3.8
                                                    C286,239.8,285.8,239.8,285.7,239.8z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <rect x="257" y="128.4" className="st6" width="56.5" height="6.2"></rect>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path className="st3" d="M362.7,257h-18.8V91.1h18.8V257z M345.8,255.1h15.1V92.9h-15.1V255.1z"></path>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,109.3h-18.3V91h18.3V109.3z M346,107.4h14.5V92.9H346V107.4z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="88.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 32.6823 279.153)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="99.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 32.6828 279.1539)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,125.7h-18.3v-18.3h18.3V125.7z M346,123.8h14.5v-14.5H346V123.8z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="104.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 21.0762 283.9615)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="115.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 21.0762 283.9615)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,142.1h-18.3v-18.3h18.3V142.1z M346,140.2h14.5v-14.5H346V140.2z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="121.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 9.4686 288.7671)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="132" transform="matrix(0.7071 -0.7071 0.7071 0.7071 9.4754 288.7821)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,158.5h-18.3v-18.3h18.3V158.5z M346,156.6h14.5v-14.5H346V156.6z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="137.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1376 293.5757)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="148.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1371 293.5768)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,174.9h-18.3v-18.3h18.3V174.9z M346,173h14.5v-14.5H346V173z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="154.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.7438 298.3845)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="164.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.7388 298.3983)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,191.3h-18.3V173h18.3V191.3z M346,189.5h14.5v-14.5H346V189.5z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="170.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -25.3512 303.1898)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="181.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -25.3459 303.2063)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,207.8h-18.3v-18.3h18.3V207.8z M346,205.9h14.5v-14.5H346V205.9z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="187" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.9571 307.9997)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="197.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.953 308.0144)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,224.2h-18.3v-18.3h18.3V224.2z M346,222.3h14.5v-14.5H346V222.3z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="203.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -48.564 312.8061)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="214.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -48.5601 312.8225)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,240.6h-18.3v-18.3h18.3V240.6z M346,238.7h14.5v-14.5H346V238.7z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="219.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -60.1704 317.615)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="230.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -60.1671 317.6305)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M362.5,257h-18.3v-18.3h18.3V257z M346,255.1h14.5v-14.5H346V255.1z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="352.4" y="236.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -71.7776 322.4187)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="341.7" y="246.9" transform="matrix(0.7072 -0.707 0.707 0.7072 -71.791 322.3427)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M395.1,125.4h-18.3v-18.3h18.3V125.4z M378.7,123.6h14.5V109h-14.5V123.6z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="374.4" y="115.4" transform="matrix(0.707 -0.7072 0.7072 0.707 30.8533 307.0509)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="385" y="104.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 30.8168 306.983)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M378.7,125.4h-18.3v-18.3h18.3V125.4z M362.3,123.6h14.5V109h-14.5V123.6z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="358" y="115.4" transform="matrix(0.707 -0.7072 0.7072 0.707 26.0435 295.4421)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="368.6" y="104.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 26.0087 295.3754)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <path className="st3" d="M362.3,125.4H344v-18.3h18.3V125.4z M345.9,123.6h14.5V109h-14.5V123.6z"></path>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M345.9,125.4h-18.3v-18.3h18.3V125.4z M329.5,123.6H344V109h-14.5V123.6z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="325.1" y="115.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 16.3945 272.1641)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="335.8" y="104.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 16.3936 272.1622)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M329.5,125.4h-18.3v-18.3h18.3V125.4z M313,123.6h14.5V109H313V123.6z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="308.7" y="115.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 11.5864 260.5565)" className="st3" width="23.2" height="1.9"></rect>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="319.4" y="104.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 11.5873 260.5583)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M313,125.4h-18.3v-18.3H313V125.4z M296.6,123.6h14.5V109h-14.5V123.6z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path className="st3" d="M295.7,125.4h-16.4c-0.4,0-0.7-0.2-0.9-0.6c-0.1-0.4-0.1-0.8,0.2-1l16.4-16.4l1.3,1.3l-14.8,14.8
                                                                        h13.8l16.1-16.1l1.3,1.3l-16.4,16.4C296.2,125.3,295.9,125.4,295.7,125.4z"></path>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="303" y="104.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 6.7784 248.949)" className="st3" width="1.9" height="23.2"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path className="st3" d="M362.7,275.3h-18.8V257h18.8V275.3z M345.9,273.4h14.9v-14.5h-14.9V273.4z"></path>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <polygon className="st3" points="361.1,275 344.2,258.6 345.6,257.3 362.5,273.7                                  "></polygon>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <polygon className="st3" points="345.6,275 344.2,273.7 361.1,257.3 362.5,258.6                                  "></polygon>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path className="st3" d="M362.7,340.9h-18.8v-18.3h18.8V340.9z M345.9,339.1h14.9v-14.5h-14.9V339.1z"></path>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <polygon className="st3" points="361.1,340.7 344.2,324.2 345.6,322.9 362.5,339.3                                    "></polygon>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <polygon className="st3" points="345.6,340.7 344.2,339.3 361.1,322.9 362.5,324.2                                    "></polygon>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path className="st3" d="M362.7,290.1h-18.8v-18.3h18.8V290.1z M345.9,288.3h14.9v-14.5h-14.9V288.3z"></path>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon className="st3" points="361.1,289.9 344.2,273.4 345.6,272.1 362.5,288.5                                "></polygon>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon className="st3" points="345.6,289.9 344.2,288.5 361.1,272.1 362.5,273.4                                "></polygon>
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path className="st3" d="M362.7,306.5h-18.8v-18.3h18.8V306.5z M345.9,304.7h14.9v-14.5h-14.9V304.7z"></path>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon className="st3" points="361.1,306.3 344.2,289.9 345.6,288.5 362.5,304.9                                "></polygon>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon className="st3" points="345.6,306.3 344.2,304.9 361.1,288.5 362.5,289.9                                "></polygon>
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path className="st3" d="M362.7,323h-18.8v-18.3h18.8V323z M345.9,321.1h14.9v-14.5h-14.9V321.1z"></path>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon className="st3" points="361.1,322.7 344.2,306.3 345.6,304.9 362.5,321.4                                "></polygon>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon className="st3" points="345.6,322.7 344.2,321.4 361.1,304.9 362.5,306.3                                "></polygon>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <path className="st3" d="M152.9,189"></path>
                                    <g>
                                        <path className="st3" d="M152.9,190.5c2,0,2-3.1,0-3.1C150.9,187.4,150.9,190.5,152.9,190.5L152.9,190.5z"></path>
                                    </g>
                                </g>
                                <g>
                                    <path className="st3" d="M321.6,156.5"></path>
                                    <g>
                                        <path className="st3" d="M321.6,158c2,0,2-3.1,0-3.1C319.6,154.9,319.6,158,321.6,158L321.6,158z"></path>
                                    </g>
                                </g>
                                <g>
                                    <path className="st3" d="M391.4,267.8c0.2,0,0.5,0,0.7,0"></path>
                                    <g>
                                        <path className="st3" d="M391.4,269.4c0.2,0,0.5,0,0.7,0c0.8,0,1.6-0.7,1.6-1.6c0-0.8-0.7-1.6-1.6-1.6c-0.2,0-0.5,0-0.7,0
                                            c-0.8,0-1.6,0.7-1.6,1.6C389.9,268.6,390.5,269.4,391.4,269.4L391.4,269.4z"></path>
                                    </g>
                                </g>
                                <g>
                                    <path className="st3" d="M91.3,290.6"></path>
                                    <g>
                                        <path className="st3" d="M91.3,292.2c2,0,2-3.1,0-3.1C89.3,289.1,89.3,292.2,91.3,292.2L91.3,292.2z"></path>
                                    </g>
                                </g>
                                <g>
                                    <path className="st3" d="M240,141.3"></path>
                                    <g>
                                        <path className="st3" d="M240,142.8c2,0,2-3.1,0-3.1C238,139.7,238,142.8,240,142.8L240,142.8z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="flex w-4/12 min-w-fit py-4 justify-center">
                    <img src={comingSoon} style={{ width: '200px' }} />
                </div>
            </div>
            <div className="flex justify-center sm:justify-start w-full lg:w-8/12 pt-2">
                <span className="text-gray-400">Powered by Z-TECHNOLOGY</span>
            </div>
        </div>
    </>
    )

}
export default ComingSoon;
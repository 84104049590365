import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateLoginRoute from './components/lib/PrivateLoginRoute'
import PrivateUnLoginRoute from './components/lib/PrivateUnLoginRoute'
import Index from "./components/Index.jsx";
import Products from "./components/Products.jsx";
import Success from "./components/Success.jsx";
import Social from "./components/Settings-Social.jsx";
import Profile from "./components/Settings-Profile.jsx";
import Account from "./components/Settings-Account.jsx";
import Activate from "./components/ActiveAccount.jsx";
import Login from "./components/Login.jsx";
import ForgotPassword from "./components/ForgotPassword.jsx";
import PublicProfile from "./components/PublicProfile.jsx";
import Affiliate from "./components/Settings-Affiliate.jsx";
import Page404 from "./components/404.jsx";
import ComingSoon from "./components/ComingSoon.jsx";
import DemoRegister from "./components/DemoRegister.jsx";
import ProductDesigner from "./components/AdminPanel/ProductDesigner.jsx";


function App() {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<Page404 />} />
                <Route path="/404" element={<Page404 />} />
                <Route path="/" element={<Index />} exact/>
                <Route path="/products/card/" element={<Products productType={'card'} />} />
                <Route path="/products/card/:pmaterial" element={<Products productType={'card'} />} />
                <Route path="/products/keychain/" element={<Products productType={'keychain'} />} />
                <Route path="/products/keychain/:pmaterial" element={<Products productType={'keychain'} />} />
                <Route path="/products/tag/" element={<Products productType={'tag'} />} />
                <Route path="/products/tag/:pmaterial" element={<Products productType={'tag'} />} />
                <Route path="/products/bigtag/" element={<Products productType={'bigtag'} />} />
                <Route path="/products/bigtag/:pmaterial" element={<Products productType={'bigtag'} />} />
                <Route path="/success/:orderid" element={<Success />} />
                <Route path="/demo/:linkcode" element={<DemoRegister />} />
                <Route path="/activate" element={<Activate />} />
                <Route path="/comingsoon" element={<ComingSoon />} exact/>
                <Route element={<PrivateUnLoginRoute />}>
                    <Route path="/login" element={<Login/>} />
                    <Route path="/forgot" element={<ForgotPassword/>} />
                </Route>
                <Route element={<PrivateLoginRoute />}>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/social" element={<Social />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/affiliate" element={<Affiliate />} />
                </Route>
                <Route path="/:username_link" element={<PublicProfile />} exact/>
                <Route path="/adminpdesigner" element={<ProductDesigner />} exact/>
            </Routes>
        </Router>
    );
}

export default App;
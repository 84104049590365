import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GrContactInfo, GrShieldSecurity } from 'react-icons/gr';
import { RiShieldUserLine } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import Logo from "../../assets/images/logo.svg";
import Logout from "../../assets/images/Logout.svg";
import PersonalInfo from "../../assets/images/personal-info.png";
import PersonalIcon from "../../assets/images/PersonalIcon.png";
import ProfileIcon2 from "../../assets/images/Personal-icon-svg.svg";
import ProfileImg from "../../assets/images/ProfileImg2.png";

function ProfileNavBar({ id_menu_active }) {
    const { t, i18n } = useTranslation();

    let active_menu_icon = ['cicon_gray', 'cicon_gray', 'cicon_gray']; active_menu_icon[id_menu_active] = 'cicon_green';
    let active_menu_text = ['text-[#A4A4A4]', 'text-[#A4A4A4]', 'text-[#A4A4A4]']; active_menu_text[id_menu_active] = 'text-[#00CC66]';

    function Click_ToggleNavBar() {
        if (document.querySelector('.accordion-item').classList.contains('hidden')){ document.querySelector('.accordion-item').classList.remove('hidden'); document.querySelector('.accordion-item').classList.add('flex'); }
        else { document.querySelector('.accordion-item').classList.remove('flex'); document.querySelector('.accordion-item').classList.add('hidden');  }  
    }

    function Click_LogOut(e) {
        localStorage.removeItem('token');
        localStorage.removeItem('islogged');
        window.location.href = "/";
    }

    window.addEventListener('resize', page_resize);
    function page_resize() {
        let w = window.innerWidth;
        if (w < 1024 && document.querySelector('.accordion-item').classList.contains('flex')) { Click_ToggleNavBar(); }
        if (w >= 1024 && document.querySelector('.accordion-item').classList.contains('hidden')) { Click_ToggleNavBar(); }
    }
    useEffect(() => {
        page_resize();
    });
  
    return (
    <div className="flex w-full justify-between m_lg:flex-row lg:flex-col lg:w-1/4 lg:h-screen overflow-hidden" style={{ minWidth: '250px' }}>
        <div className="flex w-full m_lg:flex-row flex-col justify-between">
            <a href="/" className="flex items-center ml-4 lg:mt-8 lg:mt-4 cursor-pointer">
                <img className="m_2sm:w-36" src={Logo} alt="" />
            </a>

            <div className="text-lg mx-4 my-4 lg:mt-8 xl:text-xl">
                <div className="accordion-title" onClick={(e) => Click_ToggleNavBar()}>
                    <div className="flex gap-[1rem] items-center text-[1.2rem]">
                        <img src={ProfileIcon2} className="h-6 w-6 m_2sm:hidden" alt="" />{t("index.my_profile")}
                    </div>
                </div>
                <div className="text-[1rem] accordion-item m_lg:absolute m_2sm:-ml-20 2sm:-ml-7 flex">
                    <div className="accordion-content mt-[1rem] w-full z-10">
                        <div className="flex flex-col m_lg:items-center m_lg:rounded-md m_lg:border m_lg:p-6 lg:items-center m_lg:bg-[#F5FAF5]">
                            <div className="flex w-full items-center justify-between pb-3 gap-[.3rem] lg:hidden">
                                <div className="flex w-full items-center mx-[.5rem]">
                                    <div className="flex w-full gap-[.7rem] items-center">
                                        <div className="flex w-full flex-col gap-1">
                                            <div className="flex flex-row items-center justify-between">
                                                <span className="text-sm font-[600] js_pnav_fullname">...</span>
                                                <div className="-mt-[.3rem] cursor-pointer" onClick={(e) => Click_LogOut(e)}><IoLogOutOutline size="25px" className="cicon_gray" /></div>
                                            </div>
                                            <span className="font-[500] js_pnav_email" style={{ color: "#a1afa8", fontSize: ".8rem", }}>...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border w-full mx-auto lg:hidden" style={{color: "#989898", opacity: ".8"}}></div>
                            <a href="/profile" className="flex gap-[1rem] my-4 items-center cursor-pointer min-w-200" style={{ textDecoration: 'none' }}>
                                <GrContactInfo size="30px" className={active_menu_icon[0]} />
                                <h4 className={`${active_menu_text[0]} text-lg`}>{t("index.personal_info")}</h4>
                            </a>
                            <a href="/social" className="flex gap-[1rem] mb-4 items-center cursor-pointer min-w-200" style={{ textDecoration: 'none' }}>
                                <RiShieldUserLine size="30px" className={active_menu_icon[1]} />
                                <h4 className={`${active_menu_text[1]} text-lg`}>{t("index.social_media")}</h4>
                            </a>
                            <a href="/account" className="flex gap-[1rem] items-center cursor-pointer whitespace-nowrap min-w-200" style={{ textDecoration: 'none' }}>
                                <GrShieldSecurity size="30px" className={active_menu_icon[2]} />
                                <h4 className={`${active_menu_text[2]} text-lg`}>{t("index.account_settings")}</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="bottom-0 flex flex-col m_lg:hidden">
            <div className="border w-full mx-auto my-6" style={{color: "#989898", opacity: ".8"}}></div>
            <div className="flex w-full items-center justify-center pb-3 gap-[.3rem]">
                <div className="flex w-full items-center mx-[.5rem]">
                    <div className="flex w-full gap-[.7rem] items-center">
                        <img src={ProfileImg} className="inp_p_leftavatar_img rounded-full object-cover" alt=""
                            style={{ minWidth: '64px', minHeight: '64px', width: '64px', height: '64px'}} />
                        <div className="flex w-full flex-col gap-[.6rem] ">
                            <div className="flex w-full flex-row items-center justify-between">
                                <span className="text-sm font-[600] js_pnav_fullname">...</span>
                                <div className="-mt-2 mr-2 cursor-pointer" onClick={(e) => Click_LogOut(e)}>
                                    <IoLogOutOutline size="25px" className="cicon_gray" />
                                </div>
                            </div>
                            <span className="font-[500] js_pnav_email" style={{ color: "#a1afa8", fontSize: ".8rem", }}>...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
 
}
export default ProfileNavBar
import React from 'react'
import {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';
import { api_GetProfile, api_GetCouponHistory, api_GetWithdrawHistory, api_WithdrawProcess } from './lib/Axios'
import ProfileNavBar from "./Settings_page/ProfileNavBar";

import Logo from "../assets/images/logo.svg";
import ProfileIcon from "../assets/images/ProfileIcon.png";
import SuperiorRotate from "../assets/images/Superior-Rotate.png";
import PersonalInfo from "../assets/images/personal-info.png";
import PersonalIcon from "../assets/images/PersonalIcon.png";
import ProfileIcon2 from "../assets/images/Personal-icon-svg.svg";
import Superior from "../assets/images/SuperiorIcon.png";
import GreenGrayCircle from "../assets/images/green-gray-circle.png";
import ProfileImg from "../assets/images/ProfileImg.png";
import Edit1 from "../assets/images/Edit-1.png";
import EmailIcon from "../assets/images/Email-Icon.svg";
import PhoneIcon from "../assets/images/Phone-Green-Icon.png";
import PlusIcon from "../assets/images/Plus-Icon.png";
import Website from "../assets/images/Website.svg";
import Location from "../assets/images/Location.svg";
import AddLocation from "../assets/images/Add-Location.svg";
import OpenIcon from '../assets/images/Open-Icon.svg'

const Account = () => {
    const { t, i18n } = useTranslation();
    let currencySymbol = process.env.REACT_APP_CURR_SYMB;
    let currencyRate = process.env.REACT_APP_CURR_RATE;

    var AxiosWork = false;
    let notFilled = '';
    let js_CouponHistory = null; let js_WithdrawHistory = null;

    const getProfileData = async (e) => {
        let response = await api_GetProfile();
        if( response.status == 200 ) {
            document.querySelectorAll('.js_pnav_fullname').forEach(function(el){ el.innerHTML = response.data.fullname; });
            document.querySelectorAll('.js_pnav_email').forEach(function(el){ el.innerHTML = response.data.email; });
            if (response.data.avatar != ''){ document.querySelector('.inp_p_leftavatar_img').setAttribute('src', process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/static/avatar/'+response.data.avatar); response.data.avatar = ''; }
        } else {
            if (response.status == 401 || response.status == 404) { window.location.href = "/login"; }
            if (response.status == 500) { window.location.href = "/"; }
        }
    }
    const getCouponHistory = async (e) => {
        let response = await api_GetCouponHistory();
        if( response.status == 200 ) {
            let tdiv = [];
            document.querySelector('.js_affiliate_couponhistory_count').innerHTML = response.data.length;
            response.data.forEach(function(sc, i){
                let tstatus = '';
                if (sc.status == 'Payment Checking' || sc.status  == 'Processing') {
                    tstatus = <div className="w-6 h-6 rounded-[11px] grid place-items-center text-white mx-auto" style={{background: 'rgb(239, 193, 32)'}}>
                        <i className="before-block flex icon-clock-round"></i>
                    </div>;
                } else if (sc.status == 'Shipped' || sc.status == 'Delivered'){
                    tstatus = <div className="w-6 h-6 rounded-[11px] grid place-items-center text-white mx-auto" style={{background: 'rgb(8, 199, 104)'}}>
                        <i className="before-block flex icon-done-round"></i>
                    </div>;
                } else {
                    tstatus = <div className="w-6 h-6 rounded-[11px] grid place-items-center text-white mx-auto" style={{background: 'rgb(243, 96, 96)'}}>
                        <i className="before-block flex icon-close-round"></i>
                    </div>;
                }
                tdiv.push(<tr key={'tr_history'+i} className="h-16 font-medium text-center">
                            <td className="pl-6 text-gray-mute">{i}</td>
                            <td className="text-gray-mute">{sc.total} {currencySymbol}</td>
                            <td className="">{sc.coupon}</td>
                            <td className="">{sc.commision_proc}%</td>
                            <td>{(sc.commision_usd).toFixed(2)} {currencySymbol}</td>
                            <th className="">{tstatus}</th>
                        </tr>);
            });
            await js_CouponHistory.render(tdiv);
        } else {
            if (response.status == 401 || response.status == 404) { window.location.href = "/login"; }
            if (response.status == 500) { window.location.href = "/"; }
        }
    }
    const getWithdrawHistory = async (e) => {
        let response = await api_GetWithdrawHistory();
        console.log(response);
        if( response.status == 200 ) {
            let tdiv = [];
            document.querySelector('.js_affiliate_userbalance').innerHTML = response.data.balance + ' ' + currencySymbol;
            response.data = response.data.arr;
            document.querySelector('.js_affiliate_withdrawhistory_count').innerHTML = response.data.length;
            response.data.forEach(function(sc, i){
                let tstatus = '';
                if (sc.status  == 'Processing') {
                    tstatus = <div className="w-6 h-6 rounded-[11px] grid place-items-center text-white ml-auto" style={{background: 'rgb(218, 218, 218)'}}>
                                <i className="before-block flex icon-done-round"></i>
                            </div>;
                } else if (sc.status == 'Payed'){
                    tstatus = <div className="w-6 h-6 rounded-[11px] grid place-items-center text-white ml-auto" style={{background: 'rgb(8, 199, 104)'}}>
                        <i className="before-block flex icon-done-round"></i>
                    </div>;
                } else {
                    tstatus = <div className="w-6 h-6 rounded-[11px] grid place-items-center text-white ml-auto" style={{background: 'rgb(243, 96, 96)'}}>
                        <i className="before-block flex icon-close-round"></i>
                    </div>;
                }
                let tdate = new Date(sc.date * 1000);
                let text_date = tdate.getDate()+'.'+(tdate.getMonth()+1)+'.'+tdate.getFullYear();
                tdiv.push(<tr key={'wd_history'+i} className="h-16 font-medium text-center">
                            <td className="pl-6  text-gray-mute">{text_date}</td>
                            <td className="">{sc.payment_type}</td>
                            <td className="text-gray-mute font-semibold">{sc.amount} {currencySymbol}</td>
                            <td className="text-end pr-6">{tstatus}</td>
                        </tr>);
            });
            await js_WithdrawHistory.render(tdiv);
        } else {
            if (response.status == 401 || response.status == 404) { window.location.href = "/login"; }
            if (response.status == 500) { window.location.href = "/"; }
        }
    }

    useEffect(() => {
        js_CouponHistory = ReactDOM.createRoot(document.querySelector('.js_affiliate_couponhistory'));
        js_WithdrawHistory = ReactDOM.createRoot(document.querySelector('.js_affiliate_withdrawhistory'));
        getProfileData();
        getCouponHistory();
        getWithdrawHistory();
    });

    function Change_SearchCoupon(e) {
        document.querySelectorAll('.js_affiliate_couponhistory tr').forEach(function(el){
            if (el.innerText.toLowerCase().indexOf(e.currentTarget.value.toLowerCase()) == -1) { el.classList.add('hidden'); }
            else { el.classList.remove('hidden'); }
        });
    }
    const Click_WithdrawProcess = async (e) => {
        if (AxiosWork) { document.querySelector('.js_affiliate_withbutton').classList.add('disabled'); return; } else { AxiosWork = true; }
        let response = await api_WithdrawProcess();
        AxiosWork = false; document.querySelector('.js_affiliate_withbutton').classList.remove('disabled');
        
        if( response.status == 200 ) { getWithdrawHistory(); } 
        else {
            if (response.status == 401 || response.status == 404) { window.location.href = "/login"; }
            if (response.status == 500) { window.location.href = "/"; }
        }
    }
    

    return (  
    <>
        <section id="ProfilePage" className="w-full min-h-screen flex flex-col lg:flex-row font-primary overflow-hidden">
            <ProfileNavBar id_menu_active={-1} />

            <div className="w-full bg-[#F5FAF5]  pl-[2rem] pr-[2rem] lg:px-[2rem] py-[3rem] h-screen overflow-y-auto">
                <div className="flex flex-col gap-[2rem]">
                    <div className=" flex items-center gap-[1rem] ">
                        <span className="text-[#A4A4A4]">{t("index.my_profile")}</span>
                        <span>
                            <img src={Superior} alt="" />
                        </span>
                        <span className="font-[600]">Affiliate Page</span>
                    </div>
                </div>


                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }} ></div>
                <div className="flex flex-col gap-8 mt-8">
                    <div className="flex m_xl:flex-col xl:flex-row w-full gap-8">
                        <div className="col-span-1 m_xl:w-full xl:w-4/12 xl:min-w-396 relative overflow-hidden bg-gray-light rounded-xl">
                            <svg width="382" height="375" viewBox="0 0 382 375" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute left-2/3 rotate-[30deg] origin-top-left"><circle opacity="0.4" cx="191" cy="191" r="154.619" transform="rotate(-180 191 191)" stroke="url(#paint0_linear_1203_2758)" strokeWidth="72.7619"></circle><defs><linearGradient id="paint0_linear_1203_2758" x1="191" y1="0" x2="191" y2="382" gradientUnits="userSpaceOnUse"><stop stopColor="white"></stop><stop offset="1" stopColor="white" stopOpacity="0"></stop></linearGradient></defs></svg>
                            <svg width="382" height="375" viewBox="0 0 382 375" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-2/3 rotate-12 origin-top-right"><circle opacity="0.4" cx="191" cy="191" r="154.619" transform="rotate(-180 191 191)" stroke="url(#paint0_linear_1203_2758)" strokeWidth="72.7619"></circle><defs><linearGradient id="paint0_linear_1203_2758" x1="191" y1="0" x2="191" y2="382" gradientUnits="userSpaceOnUse"><stop stopColor="white"></stop><stop offset="1" stopColor="white" stopOpacity="0"></stop></linearGradient></defs></svg>

                            <div className="p-4 md:p-10 text-white space-y-10">
                                <h2 className="text-xl md:text-2xl font-medium">{t("index.balance")}</h2>
                                <div className="text-center">
                                    <h1 className="text-5xl md:text-7xl font-semibold js_affiliate_userbalance">0 {currencySymbol}</h1>
                                    <span className="">{t("index.available")}</span>
                                </div>

                                <button className="w-full active text-lg font-medium  cursor-default btn-gradient bg-green_1 text-white rounded-full h-14 px-8 block text-xl btn btn-ripple disabled:cursor-default disabled:opacity-50 js_affiliate_withbutton"
                                        onClick={(e) => Click_WithdrawProcess(e)}>
                                    Withdraw
                                </button>
                            </div>
                        </div>

                        <div className="m_xl:w-full xl:w-8/12 col-span-2 bg-white rounded-xl">
                            <div className="flex flex-col md:flex-row p-4 md:p-10 m_md:gap-4 justify-between items-center">
                                <div className="flex space-x-3 items-center">
                                    <h2 className="text-2xl font-medium">{t("index.last_withdraw")}</h2>
                                    <span className="px-3 py-2 bg-blue-base/10 text-blue-base rounded-xl text-sm font-medium leading-none grid place-items-center js_affiliate_withdrawhistory_count">0</span>
                                </div>

                                {/*<button className="border text-gray-mute flex items-center h-12 px-4 rounded-full font-medium active:opacity-50 ">
                                    <i className="before-block flex icon-calender text-xl mr-3"></i>
                                    <span>12 Sep 2022</span>
                                    <span className="mx-1">-</span>
                                    <span>27 Sep 2022</span>
                                </button>*/}
                            </div>

                            <div className="max-h-52 overflow-auto">

                                <table className="w-full" style={{ minWidth: '540px' }}>
                                    <thead className="bg-green-light h-12 sticky top-0 text-center">
                                        <tr className="text-sm text-gray-mute font-medium">
                                            <td className="pl-6">{t("index.date")}</td>
                                            <td>{t("index.payment_method")}</td>
                                            <td>{t("index.amount")}</td>
                                            <td className="w-2/6 text-end"><span className="pr-6">{t("index.status")}</span></td>
                                        </tr>
                                    </thead>
                                    <tbody className="border-t tr-border-t js_affiliate_withdrawhistory">
                                        
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div className="col-span-3 bg-white rounded-xl">
                        <div className="flex flex-col lg:flex-row p-4 lg:p-10 m_lg:gap-4 justify-between items-center">
                            <div className="flex space-x-3 items-center">
                                <h2 className="text-2xl font-medium w-min whitespace-nowrap">{t("index.coupon_history")}</h2>
                                <span className="px-3 py-2 bg-blue-base/10 text-blue-base rounded-xl text-sm font-medium leading-none grid place-items-center js_affiliate_couponhistory_count">0</span>
                            </div>

                            <div className="flex flex-col md:flex-row m_md:gap-4 space-x-3">
                                {/*<button className="border text-gray-mute flex items-center h-12 px-4 rounded-full font-medium active:opacity-50 ">
                                    <i className="before-block flex icon-calender text-xl mr-3"></i>
                                    <span>12 Sep 2022</span>
                                    <span className="mx-1">-</span>
                                    <span>27 Sep 2022</span>
                                </button>*/}

                                <div className="border rounded-full h-12 overflow-hidden max-w-xs relative">
                                    <i className="before-block flex icon-search absolute top-1/2 -translate-y-1/2 left-4 text-gray-mute"></i>
                                    <input
                                        className="h-full pr-6 ml-10 border-transparent focus:border-transparent focus:ring-0 !outline-none"
                                        placeholder="search..." onChange={(e) => Change_SearchCoupon(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="max-h-[600px] overflow-auto">

                            <table className="w-full" style={{ minWidth: '914px' }}>
                                <thead className="bg-green-light h-12 sticky top-0 ">
                                    <tr className="text-sm text-gray-mute font-medium text-center">
                                        <td className="pl-6 w-[10%]">#</td>
                                        <td>{t("index.coupon_history")}</td>
                                        <td>{t("index.coupon")}</td>
                                        <td>Commision in %</td>
                                        <td>Comission in USD</td>
                                        <td>{t("index.order_status")}</td>
                                    </tr>
                                </thead>
                                <tbody className="border-t tr-border-t js_affiliate_couponhistory">
  
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>


            </div>
        </section>
    </>
    );
};


export default Account;
import React from 'react';
import { useTranslation } from "react-i18next";
import logo from '../../assets/images/Frame.svg';
import stepOne from '../../assets/images/bub_step1.png';
import stepTwo from '../../assets/images/bub_step2.png';
import stepThree from '../../assets/images/bub_step3.png';
import stepFour from '../../assets/images/bub_step4.png';
import vector from '../../assets/images/Vector 2.png';


function DesktopHeader() {
    const { t, i18n } = useTranslation();
    return (
    <>
        <nav className="hidden lg:flex p-[3rem] gap-[1rem] items-center bg-white" style={{letterSpacing: "-0.03em", fontFamily: 'Poppins, sans-serif'}}>
            <div className="">
                <a href="/"><img className="min-w-[89px]" src={logo} alt="" /></a>
            </div>
            <div className="flex gap-x-[10px] ml-[20rem] rml items-center js_inform1_hbub_1">
                <img className="w-7 h-7" src={stepOne} />
                <h3 className="font-bold cursor-scale small">{t("index.information")}</h3>
            </div>
            <div className="flex gap-x-[10px] js_inform1_hpc_line_1">
                <img className="vector" src={vector} style={{ height: '2px', width: '6rem' }} />
            </div>
            <div className="flex gap-x-[10px] items-center js_inform1_hbub_2">
                <img className="w-7 h-7" src={stepTwo} />
                <h3 className="font-bold cursor-scale small">{t("index.preview")}</h3>
            </div>
            <div className="flex gap-x-[10px] js_inform1_hpc_line_2">
                <img className="vector " src={vector} style={{ height: '2px', width: '6rem' }} />
            </div>
            <div className="flex gap-x-[10px] items-center js_inform1_hbub_3">
                <img className="w-7 h-7" src={stepThree} />
                <h3 className="font-bold cursor-scale small">{t("index.delivery_information")}</h3>
            </div>
            <div className="flex gap-x-[10px] js_inform1_hpc_line_3">
                <img className="vector " src={vector} style={{ height: '2px', width: '6rem' }} />
            </div>
            <div className="flex gap-x-[10px] items-center js_inform1_hbub_4">
                <img className="w-7 h-7" src={stepFour} />
                <h3 className="font-bold cursor-scale small">{t("index.complete_your_order")}</h3>
            </div>
        </nav>
    </>
  )
}

export default DesktopHeader



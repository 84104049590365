import React from 'react';
import { useTranslation } from "react-i18next";
import jpg404 from "../assets/images/404.jpg";

const Page404 = () => {
    const { t, i18n } = useTranslation();
    function Click_ReturnHome(e) { window.location.href = "/"; }

    return (
    <div className="flex h-screen flex-col w-full justify-center items-center">
        <img src= {jpg404} className="w-10/12 xl:w-6/12" />
        <div className="flex flex-col w-8/12 xl:w-4/12">
            <div className="font-bold text-3xl text-center">{t("index.we_cant_find_that_page")}</div>
            <div className="pt-2 text-md text-center">{t("index.page_not_found")}</div>
        </div>
        <div className="flex justify-start w-8/12 xl:w-4/12">
            <button onClick={(e) => Click_ReturnHome(e)}
                className="rounded-2xl mt-4 mx-auto border text-black py-2 px-16 2sm:px-32 block text-xl btn bg-green_1 text-white hover:opacity-80 uppercase">{t("index.home")}</button>
        </div>
    </div>
    )

}
export default Page404;
import React, { useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom/client';
import { api_getProducts } from '../lib/Axios'
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import BgCircle from "../../assets/images/big-circles.png";
import BgHalfCircle from "../../assets/images/bg-half-circle.png";
import Product2 from "../../assets/images/product-2.png";
import Product1 from "../../assets/images/product-1.png";
import GrayCard from "../../assets/images/gray-card.png";
import Product3 from "../../assets/images/product-3.png";
import Group894 from "../../assets/images/Group 894.png";
import Group893 from "../../assets/images/Group 893.png";
import Arrow5 from "../../assets/images/Arrow 5.svg";
import Arrow4 from "../../assets/images/Arrow-4.svg";

function ProductsSectionNew() {
    const { t, i18n } = useTranslation();
    
    //Variables
    let js_ProductsList = null;
    let img_folder = process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/static/product/';

    //Axios
    const getProducts = async () => {
        let response = await api_getProducts();
        if( response.status == 500 || response.status == 401 ) {}
        else {
            let tempDom = [];
            response.data.forEach(function(el){
                console.log(el);
                if (el.status == 'Enable') {
                    /*let tTitle = ''; let tDesc = '';
                    if (el.title == "Classic") { tTitle = t("index.classic"); tDesc = t("index.recyclable_plastic"); }
                    else if (el.title == "Wood") { tTitle = t("index.wood"); tDesc = t("index.wood_card_desc"); }*/

                    tempDom.push(
                        <div key={`product`+el.id} className="text-center flex flex-col justify-between m_sm:mt-20 shadow py-8 rounded-3xl px-6
                                sm:mb-20 sm:pt-4 2xl:mb-20 2xl:pt-12 3xl:mb-32 3xl:pt-24">
                            <img src={img_folder+el.id+'.png'} alt="" className="object-contain max-h-64 max-w-full mx-auto pb-4"
                                style={{ marginTop: '-25%'}}/>
                            <div className="">
                                <h2 className="text-4xl">{el.title}</h2>
                                <p className="my-2 text-md text-gray-500">{el.desc}</p>
                            </div>
                            <a className="hover:no-underline" href={'/products/'+el.type.toLowerCase().replace(/ /g, '')+'/'+el.smalltitle}>
                                <button className="rounded-2xl mt-2 mx-auto border text-black py-2 px-8 block text-xl btn bg-green_1 text-white hover:opacity-80">
                                    {t("index.get_yours_now")}
                                </button>
                            </a>
                        </div>
                    );
                }
            });
            js_ProductsList.render(tempDom);
        }
    }
    useEffect(() => {
        js_ProductsList = ReactDOM.createRoot(document.querySelector('.js_ProductsList'));
        getProducts();
    });

    return (
        <section id="ProductComponent" className="animated-scroll pb-[3rem] bg-white px-[20px] flex flex-col items-center justify-center w-full relative active" >

            <div id="products" className="flex flex-col justify-center items-center w-full pt-16 pb-32">
                <h2 className="text-4xl lg:text-[3rem] pb-[10px]">{t("index.products")}</h2>
                <p className="text-gray-500 text-center text-sm lg:text-[1rem]">{t("index.all_custom")}</p>
                <div className="absolute top-0 right-0 w-[70px]">
                    <img src={BgCircle} alt="" />
                </div>
                <div className="absolute top-[180px] -left-[30px] w-[100px]" style={{ transform: "rotate(-57.91deg)" }} >
                    <img src={BgHalfCircle} alt="" />
                </div>
            </div>

            {/*<Slider className=" w-full" {...{infinite: true, draggable: true, speed: 500, slidesToShow: slidesToShow, slidesToScroll: 1, centerMode: true, centerPadding: '5%'}} ref={slider2}>

                <div className="text-center flex flex-col justify-between shadow py-8 rounded-3xl px-6" style={{ marginTop: '15%'}} >
                    <img src="https://res.cloudinary.com/dicqc1ntj/image/upload/v1658505802/et620gbiphaijpyxnw0z.png" alt="" className="object-contain max-h-64 max-w-full my-10 mx-auto"
                        style={{ marginTop: '-25%'}}/>
                    <div className="">
                        <h2 className="text-4xl">Classic</h2>
                        <p className="my-2 text-md text-gray-500">Made from durable 8 times recyclable plastic. Designed for longevity and produced with precision</p>
                    </div>
                    <button className="rounded-2xl mt-2 mx-auto border text-black py-2 px-8 block text-xl btn hover:bg-green_1 hover:text-white">
                        Get Yours Now#1
                    </button>
                </div>
                <div className="text-center flex flex-col justify-between shadow py-8 rounded-3xl px-6">
                    <img src="https://res.cloudinary.com/dicqc1ntj/image/upload/v1658505802/et620gbiphaijpyxnw0z.png" alt="" className="object-contain max-h-64 max-w-full my-10 mx-auto"
                        style={{ marginTop: '-25%'}}/>
                    <div className="">
                        <h2 className="text-4xl">Classic</h2>
                        <p className="my-2 text-md text-gray-500">Made from durable 8 times recyclable plastic. Designed for longevity and produced with precision</p>
                    </div>
                    <button className="rounded-2xl mt-2 mx-auto border text-black py-2 px-8 block text-xl btn hover:bg-green_1 hover:text-white">
                        Get Yours Now#2
                    </button>
                </div>
                <div className="text-center flex flex-col justify-between shadow py-8 rounded-3xl px-6">
                    <img src="https://res.cloudinary.com/dicqc1ntj/image/upload/v1658505802/et620gbiphaijpyxnw0z.png" alt="" className="object-contain max-h-64 max-w-full my-10 mx-auto"
                        style={{ marginTop: '-25%'}}/>
                    <div className="">
                        <h2 className="text-4xl">Classic</h2>
                        <p className="my-2 text-md text-gray-500">Made from durable 8 times recyclable plastic. Designed for longevity and produced with precision</p>
                    </div>
                    <button className="rounded-2xl mt-2 mx-auto border text-black py-2 px-8 block text-xl btn hover:bg-green_1 hover:text-white">
                        Get Yours Now#3
                    </button>
                </div>
                <div className="text-center flex flex-col justify-between shadow py-8 rounded-3xl px-6">
                    <img src="https://res.cloudinary.com/dicqc1ntj/image/upload/v1658505802/et620gbiphaijpyxnw0z.png" alt="" className="object-contain max-h-64 max-w-full my-10 mx-auto"
                        style={{ marginTop: '-25%'}}/>
                    <div className="">
                        <h2 className="text-4xl">Classic</h2>
                        <p className="my-2 text-md text-gray-500">Made from durable 8 times recyclable plastic. Designed for longevity and produced with precision</p>
                    </div>
                    <button className="rounded-2xl mt-2 mx-auto border text-black py-2 px-8 block text-xl btn hover:bg-green_1 hover:text-white">
                        Get Yours Now#4
                    </button>
                </div>
                <div className="text-center flex flex-col justify-between shadow py-8 rounded-3xl px-6">
                    <img src="https://res.cloudinary.com/dicqc1ntj/image/upload/v1658505802/et620gbiphaijpyxnw0z.png" alt="" className="object-contain max-h-64 max-w-full my-10 mx-auto"
                        style={{ marginTop: '-25%'}}/>
                    <div className="">
                        <h2 className="text-4xl">Classic</h2>
                        <p className="my-2 text-md text-gray-500">Made from durable 8 times recyclable plastic. Designed for longevity and produced with precision</p>
                    </div>
                    <button className="rounded-2xl mt-2 mx-auto border text-black py-2 px-8 block text-xl btn hover:bg-green_1 hover:text-white">
                        Get Yours Now#5
                    </button>
                </div>
            </Slider>
            <div className="py-20"></div>*/}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 m_lg:px-5 lg:px-20 3xl:py-10 4xl:py-20 js_ProductsList">
                {/*js_ProductsList Dom*/}
            </div>
        </section>
    );
}

export default ProductsSectionNew;
import React from 'react'
import {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from "react-i18next";
import { api_GetProfile, api_SetProfile } from './lib/Axios';
import { AiOutlineLink } from "react-icons/ai";
import { FaAtlas, FaBriefcase, FaIdCard } from "react-icons/fa";
import { MdDescription } from "react-icons/md";
import { IoBusiness } from "react-icons/io5";
import '../assets/scss/upload.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import ProfileNavBar from "./Settings_page/ProfileNavBar";
import { AvatarModal } from './Settings_page/Avatar_modal';
import { CompanyImgModal } from './Settings_page/Company_modal';

import Logo from "../assets/images/logo.svg";
import ProfileIcon from "../assets/images/ProfileIcon.png";
import EditBtnIcon from "../assets/images/EditIcon.png";
import SuperiorRotate from "../assets/images/Superior-Rotate.png";
import PersonalInfo from "../assets/images/personal-info.png";
import PersonalIcon from "../assets/images/PersonalIcon.png";
import ProfileIcon2 from "../assets/images/Personal-icon-svg.svg";
import Superior from "../assets/images/SuperiorIcon.png";
import GreenGrayCircle from "../assets/images/green-gray-circle.png";
import ProfileImg from "../assets/images/ProfileImg2.png";
import CompanyImg from "../assets/images/ProfileImg3.png";
import Edit1 from "../assets/images/Edit-1.png";
import EmailIcon from "../assets/images/Email-Icon.svg";
import PhoneIcon from "../assets/images/Phone-Green-Icon.png";
import PlusIcon from "../assets/images/Plus-Icon.png";
import Website from "../assets/images/Website.svg";
import Location from "../assets/images/Location.svg";
import AddLocation from "../assets/images/Add-Location.svg";
import OpenIcon from '../assets/images/Open-Icon.svg'

const Profile = () => {
    const { t, i18n } = useTranslation();
    let notFilled = ''; let startChanges = false;
    var AxiosWork = false;
    let profile_obj = {
        address: "",
        city: "",
        company: "",
        companyice: "",
        country: "",
        email: "",
        emailshow: "",
        username: "",
        fullname: "",
        job: "",
        desc: "",
        phone: "",
        website: "",
        avatar: '',
        companyimg: ''
    };
    let js_ProfilePhoneDiv = null; let js_ProfileEmailDiv = null; let js_ProfileAddressDiv = null;

    const getProfileData = async (e) => {
        let response = await api_GetProfile();
        console.log(response);
        if( response.status == 200 ) {
            profile_obj = response.data;
            document.querySelectorAll('.js_pnav_fullname').forEach(function(el){ el.innerHTML = response.data.fullname; });
            document.querySelectorAll('.js_pnav_email').forEach(function(el){ el.innerHTML = response.data.email; });
            
            document.querySelector('#inp_p_fullname').value = response.data.fullname; clearPlaceHolders('#inp_p_fullname');

            RenderPhoneInputs();
            RenderEmailInputs();
            RenderAddressInputs();

            //if (response.data.email != '') { document.querySelector('#inp_p_email').value = response.data.email; clearPlaceHolders('#inp_p_email'); }
            if (response.data.username != '') { document.querySelector('#inp_p_username').value = response.data.username; } ////clearPlaceHolders('#inp_p_username');
            document.querySelector('.js_profile_username_span').innerHTML = response.data.username;
            document.querySelector('.js_profile_username_url').setAttribute('href','/'+response.data.username);
            if (response.data.company != '') { document.querySelector('#inp_p_company').value = response.data.company; clearPlaceHolders('#inp_p_company'); }
            if (response.data.companyice != '') { document.querySelector('#inp_p_companyice').value = response.data.companyice; clearPlaceHolders('#inp_p_companyice'); }
            if (response.data.job != '') { document.querySelector('#inp_p_job').value = response.data.job; clearPlaceHolders('#inp_p_job'); }
            if (response.data.desc != '') { document.querySelector('#inp_p_desc').value = response.data.desc; clearPlaceHolders('#inp_p_desc'); }
            if (response.data.website != '') { document.querySelector('#inp_p_website').value = response.data.website; clearPlaceHolders('#inp_p_website'); }
            if (response.data.country != '') { document.querySelector('#inp_p_country').value = response.data.country; }
            if (response.data.city != '') { document.querySelector('#inp_p_city').value = response.data.city; clearPlaceHolders('#inp_p_city'); }
            if (response.data.avatar != ''){
                document.querySelector('.inp_p_avatar_img').setAttribute('src', process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/static/avatar/'+response.data.avatar);
                document.querySelector('.inp_p_leftavatar_img').setAttribute('src', process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/static/avatar/'+response.data.avatar); response.data.avatar = '';
            }
            if (response.data.companyimg != ''){ document.querySelector('.inp_p_companyimg_img').setAttribute('src', process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/static/company/'+response.data.companyimg); response.data.companyimg = ''; }
        } else {
            if (response.status == 401 || response.status == 404) { /*window.location.href = "/login";*/ }
            if (response.status == 500) { /*window.location.href = "/";*/ }
        }
    }
    // -- All render phone inputs
    const RenderPhoneInputs = async (man_rerender = false) => {
        let tdiv = [];
        profile_obj.phone.split(',').forEach(function(tph, i){
            let input_title = ''; let add_padding = ''; let interactButton = '';
            if (i == 0) { input_title = t("index.mobile"); } else if (i == 1) { input_title = t("index.working_mobile"); } else { input_title = t("index.additional_mobile"); }
            if (i != 0) { add_padding = 'pt-4'; }
            if (i == 0) { interactButton = <PhonePlusButton />; } else { interactButton = <PhoneMinusButton si={i} />; }

            tdiv.push(
                <div key={`key_ph`+i} className={`flex flex-col w-full xl:w-10/12 2xl:w-8/12 ${add_padding}`}>
                    <div className="flex items-center gap-[.6rem] pb-2">
                        <h4 className="text-[#989898] text-[.9rem] pl-4">{input_title}</h4>
                        <img src={Superior} alt="" />
                    </div>
                    <div className="flex flex-row gap-x-10">
                        <div className="flex flex-col gap-[1rem] w-full">
                            <div className="relative w-full">
                                <input id={`inp_p_phone`+(i+1)} onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_Phone_profile2(e)} maxLength="16" defaultValue={tph}
                                    type="text" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem] input-styles profile-inputs js_profile_phoneinputs" placeholder=" " />
                                <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName" style={{display: 'none'}}>
                                    +2126XXXXXXX
                                </label>
                                <span className={`text-red-400 text-sm inp_p_phone`+(i+1)+`_error hidden`}></span>
                            </div>
                        </div>
                        <div className="flex px-1 items-center">
                            {interactButton}
                        </div>
                    </div>
                </div>
            );
        });
        await js_ProfilePhoneDiv.render(tdiv);
        if (man_rerender){
            profile_obj.phone.split(',').forEach(function(tph, i){
                document.querySelector("#inp_p_phone"+(i+1)).value = tph;
            });
        }
    }
    function PhonePlusButton() {
        return(
            <button className="flex items-center justify-center add-btn-style w-8 h-8 hover:opacity-80" style={{ outline: 'none' }}
                    onClick={(e) => Click_PlusNewPhone(e)} >
                <img src={PlusIcon} atl="" />
            </button>
        )
    }
    function PhoneMinusButton({si}) {
        return(
            <button className="flex items-center justify-center add-btn-style w-8 h-8 hover:opacity-80" style={{ outline: 'none' }}
                    onClick={(e) => Click_MinusOldPhone(e, si)}>
                <svg className="w-6 h-6 text-[#fff]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"></path>
                </svg>
            </button>
        )
    }
    function Click_PlusNewPhone(e) {
        profile_obj.phone += ','; RenderPhoneInputs();
    }
    function Click_MinusOldPhone(e, si) {
        let tphoneArray = profile_obj.phone.split(',');
        tphoneArray.splice(si, 1);
        profile_obj.phone = tphoneArray.join(','); RenderPhoneInputs(true);
    }
    // -- All render email inputs
    const RenderEmailInputs = async (man_rerender = false) => {
        let tdiv = [];
        profile_obj.emailshow.split(',').forEach(function(tph, i){
            let input_title = ''; let add_padding = ''; let interactButton = '';
            let input_desc = '';
            if (i == 0) { input_title = t("index.email"); } else if (i == 1) { input_title = t("index.working_email"); } else { input_title = t("index.additional_email"); }
            if (i != 0) { add_padding = 'pt-4'; }
            if (i == 0) { 
                interactButton = <EmailPlusButton />;
                input_desc = <div className="text-center"><h4 className="text-gray-400 text-[.9rem] pl-4">({t("index.email_change_1")}<br />{t("index.email_change_2")})</h4></div>;
            } else { interactButton = <EmailMinusButton si={i} />; }

            tdiv.push(
                <div key={`key_em`+i} className={`flex flex-col w-full xl:w-10/12 2xl:w-8/12 ${add_padding}`}>
                    {input_desc}
                    <div className="flex items-center gap-[.6rem] pb-2">
                        <h4 className="text-[#989898] text-[.9rem] pl-4">{input_title}</h4>
                        <img src={Superior} alt="" />
                    </div>
                    <div className="flex flex-row gap-x-10">
                        <div className="flex flex-col gap-[1rem] w-full">
                            <div className="relative w-full">
                                <input id={`inp_p_email`+(i+1)} onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_Email_profile2(e)} maxLength="50" defaultValue={tph}
                                    type="text" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem] input-styles profile-inputs js_profile_emailinputs" placeholder=" " />
                                <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName" style={{display: 'none'}}>
                                    Username@CarteNFC.ma
                                </label>
                                <span className={`text-red-400 text-sm inp_p_email`+(i+1)+`_error hidden`}></span>
                            </div>
                        </div>
                        <div className="flex px-1 items-center">
                            {interactButton}
                        </div>
                    </div>
                </div>
            );
        });
        await js_ProfileEmailDiv.render(tdiv);
        if (man_rerender){
            profile_obj.emailshow.split(',').forEach(function(tph, i){ 
                document.querySelector("#inp_p_email"+(i+1)).value = tph;
            });
        }
    }
    function EmailPlusButton() {
        return(
            <button className="flex items-center justify-center add-btn-style w-8 h-8 hover:opacity-80" style={{ outline: 'none' }}
                    onClick={(e) => Click_PlusNewEmail(e)} >
                <img src={PlusIcon} atl="" />
            </button>
        )
    }
    function EmailMinusButton({si}) {
        return(
            <button className="flex items-center justify-center add-btn-style w-8 h-8 hover:opacity-80" style={{ outline: 'none' }}
                    onClick={(e) => Click_MinusOldEmail(e, si)}>
                <svg className="w-6 h-6 text-[#fff]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"></path>
                </svg>
            </button>
        )
    }
    function Click_PlusNewEmail(e) {
        profile_obj.emailshow += ','; RenderEmailInputs();
    }
    function Click_MinusOldEmail(e, si) {
        let temailArray = profile_obj.emailshow.split(',');
        temailArray.splice(si, 1);
        profile_obj.emailshow = temailArray.join(','); RenderEmailInputs(true);
    }

    // -- All render address inputs
    const RenderAddressInputs = async (man_rerender = false) => {
        let tdiv = [];
        profile_obj.address.split(',').forEach(function(tph, i){
            let input_title = ''; let add_padding = ''; let interactButton = '';
            if (i == 0) { input_title = 'Address'; } else if (i == 1) { input_title = 'Working Address'; } else { input_title = 'Additional Address'; }
            if (i != 0) { add_padding = 'pt-4'; }
            if (i == 0) { interactButton = <AddressPlusButton />; } else { interactButton = <AddressMinusButton si={i} />; }

            tdiv.push(
                <div key={`key_adr`+i} className={`flex flex-col w-full xl:w-10/12 2xl:w-8/12 ${add_padding}`}>
                    <div className="flex items-center gap-[.6rem] pb-2">
                        <h4 className="text-[#989898] text-[.9rem] pl-4">{input_title}</h4>
                        <img src={Superior} alt="" />
                    </div>
                    <div className="flex flex-row gap-x-10">
                        <div className="flex flex-col gap-[1rem] w-full">
                            <div className="relative w-full">
                                <input id={`inp_p_address`+(i+1)} onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_Address_profile2(e)} maxLength="200" defaultValue={tph}
                                    type="text" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem] input-styles profile-inputs js_profile_addressinputs" placeholder=" " />
                                <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName" style={{display: 'none'}}>
                                    Location...
                                </label>
                                {/*<button className="absolute top-[1rem] right-[1.4rem] w-[1rem]" style={{ outline: 'none' }}>
                                    <img src={AddLocation} alt="" />
                                </button>*/}
                                <span className={`text-red-400 text-sm inp_p_address`+(i+1)+`_error hidden`}></span>
                            </div>
                        </div>
                        <div className="flex px-1 items-center">
                            {interactButton}
                        </div>
                    </div>
                </div>
            );
        });
        await js_ProfileAddressDiv.render(tdiv);
        if (man_rerender){
            profile_obj.address.split(',').forEach(function(taddr, i){
                document.querySelector("#inp_p_address"+(i+1)).value = taddr;
            });
        }
    }
    function AddressPlusButton() {
        return(
            <button className="flex items-center justify-center add-btn-style w-8 h-8 hover:opacity-80" style={{ outline: 'none' }}
                    onClick={(e) => Click_PlusNewAddress(e)} >
                <img src={PlusIcon} atl="" />
            </button>
        )
    }
    function AddressMinusButton({si}) {
        return(
            <button className="flex items-center justify-center add-btn-style w-8 h-8 hover:opacity-80" style={{ outline: 'none' }}
                    onClick={(e) => Click_MinusOldAddress(e, si)}>
                <svg className="w-6 h-6 text-[#fff]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"></path>
                </svg>
            </button>
        )
    }
    function Click_PlusNewAddress(e) {
        profile_obj.address += ','; RenderAddressInputs();
    }
    function Click_MinusOldAddress(e, si) {
        let taddressArray = profile_obj.address.split(',');
        taddressArray.splice(si, 1);
        profile_obj.address = taddressArray.join(','); RenderAddressInputs(true);
    }
    //Changes functions
    function Change_Input_FullName_profile(e) { profile_obj.fullname = e.currentTarget.value; startChanges = true; }
    function Change_Input_Phone_profile2(e) {
        let tphones = '';
        document.querySelectorAll('.js_profile_phoneinputs').forEach(function(el){ tphones += el.value+','; });
        tphones = tphones.slice(0, -1);
        profile_obj.phone = tphones; startChanges = true;
    }
    function Change_Input_Email_profile2(e) {
        let temail = '';
        document.querySelectorAll('.js_profile_emailinputs').forEach(function(el){ temail += el.value+','; });
        temail = temail.slice(0, -1);
        profile_obj.emailshow = temail; startChanges = true;
    }
    function Change_Input_Address_profile2(e) {
        let taddress = '';
        document.querySelectorAll('.js_profile_addressinputs').forEach(function(el){ taddress += el.value+','; });
        taddress = taddress.slice(0, -1);
        profile_obj.address = taddress; startChanges = true;
    }
    function Change_Input_Username_profile(e) {
        profile_obj.username = e.currentTarget.value; 
        document.querySelector('.js_profile_username_span').innerHTML = e.currentTarget.value;
        document.querySelector('.js_profile_username_url').setAttribute('href','/'+e.currentTarget.value);
    }
    function Change_Input_Company_profile(e) { profile_obj.company = e.currentTarget.value; startChanges = true; }
    function Change_Input_CompanyIce_profile(e) { profile_obj.companyice = e.currentTarget.value; startChanges = true; }
    function Change_Input_Job_profile(e) { profile_obj.job = e.currentTarget.value; startChanges = true; }
    function Change_Input_Desc_profile(e) { profile_obj.desc = e.currentTarget.value; startChanges = true; }
    function Change_Input_WebSite_profile(e) { profile_obj.website = e.currentTarget.value; startChanges = true; }
    function Change_Input_Country_profile(e) { profile_obj.country = e.currentTarget.value; startChanges = true; }
    function Change_Input_City_profile(e) { profile_obj.city = e.currentTarget.value; startChanges = true; }
    function Change_Input_Address_profile(e) { profile_obj.address = e.currentTarget.value; startChanges = true; }
    function FileChange_UploadAvatar(e) {
        profile_obj.avatar = null; InputClearError('inp_p_avatar');
        if (!e.currentTarget.files || !e.currentTarget.files[0]) { InputWasWrong('inp_p_avatar', t("index.file_upload_error")); return; }
        if (!['image/jpg', 'image/jpeg', 'image/png'].includes(e.currentTarget.files[0].type)) { InputWasWrong('inp_p_avatar', t("index.invalid_file_type_error")); return; }
        if (e.currentTarget.files[0].size > 10485760) { InputWasWrong('inp_p_avatar', t("index.invalid_file_size_error")); return; }
        
        var reader = new FileReader();
        reader.onload = function(e2) {
            modalAvatarImg = e2.target.result;
            document.querySelector('.js_avatar_modal').click();
            e.target.value = ''; e.target.type = 'text'; e.target.type = 'file';
        }
        reader.readAsDataURL(e.currentTarget.files[0]);
        startChanges = true;
    }
    function FileChange_UploadCompany(e) {
        profile_obj.companyimg = null; InputClearError('inp_p_companyimg');
        if (!e.currentTarget.files || !e.currentTarget.files[0]) { InputWasWrong('inp_p_companyimg', t("index.file_upload_error")); return; }
        if (!['image/jpg', 'image/jpeg', 'image/png'].includes(e.currentTarget.files[0].type)) { InputWasWrong('inp_p_companyimg', t("index.invalid_file_type_error")); return; }
        if (e.currentTarget.files[0].size > 10485760) { InputWasWrong('inp_p_companyimg', t("index.invalid_file_size_error")); return; }

        var reader = new FileReader();
        reader.onload = function(e2) {
            modalCompanyImg = e2.target.result;
            document.querySelector('.js_companyimg_modal').click();
            e.target.value = ''; e.target.type = 'text'; e.target.type = 'file';
        }
        reader.readAsDataURL(e.currentTarget.files[0]);
        startChanges = true;
        
        /*profile_obj.companyimg = e.currentTarget.files[0];
        var reader = new FileReader();
        reader.onload = function(e2) {
            document.querySelector('.inp_p_companyimg_img').setAttribute('src', e2.target.result);
            e.target.value = ''; e.target.type = 'text'; e.target.type = 'file';
        }
        reader.readAsDataURL(e.currentTarget.files[0]);
        startChanges = true;*/
    }

    function clearPlaceHolders(ndiv) { document.querySelector(ndiv).nextSibling.style.display = 'none'; }
    function onFocus(e) { e.currentTarget.nextSibling.style.display = 'none'; InputClearError(e.currentTarget.id); }
    function onBlur(e) { if (e.currentTarget.value.length != 0) { return; } e.currentTarget.nextSibling.style.display = ''; }

    useEffect(() => {
        js_ProfilePhoneDiv = ReactDOM.createRoot(document.querySelector('.js_profile_phonediv'));
        js_ProfileEmailDiv = ReactDOM.createRoot(document.querySelector('.js_profile_emaildiv'));
        js_ProfileAddressDiv = ReactDOM.createRoot(document.querySelector('.js_profile_addressdiv'));
        getProfileData();
    });

    function Click_SaveProfileInfo(e) {
        notFilled = '';
        if (profile_obj.fullname == '') { InputWasWrong('inp_p_fullname'); }
        else if (profile_obj.fullname.length < 2 || profile_obj.fullname.length > 30) { InputWasWrong('inp_p_fullname', t("index.full_name_length_error")); }
        
        profile_obj.phone.split(',').forEach(function(tph, i){
            if (tph.length > 16) { InputWasWrong('inp_p_phone'+(i+1), t("index.phone_length_error")); }
        });
        profile_obj.emailshow.split(',').forEach(function(tem, i){
            if (tem.indexOf('@') == -1 && tem != '') { InputWasWrong('inp_p_email'+(i+1), t("index.email_format_error")); }
            else if ((tem.length < 7 || tem.length > 50) && tem != '') { InputWasWrong('inp_p_email'+(i+1), t("index.email_length_error")); }
        });
        /*if (profile_obj.email == '') { InputWasWrong('inp_p_email'); }
        else if (profile_obj.email.indexOf('@') == -1) { InputWasWrong('inp_p_email', t("index.email_format_error")); }
        else if (profile_obj.email.length < 7 || profile_obj.email.length > 50) { InputWasWrong('inp_p_email', t("index.email_length_error")); }*/

        if (profile_obj.username == '') { InputWasWrong('inp_p_username'); }
        else if (profile_obj.username.length < 2 || profile_obj.username.length > 24) { InputWasWrong('inp_p_username', t("index.username_length_error")); }

        if (profile_obj.company.length > 50) { InputWasWrong('inp_p_company', t("index.company_name_length_error")); }
        if (profile_obj.companyice.length > 20) { InputWasWrong('inp_p_companyice', t("index.company_ice_length_error")); }

        if (profile_obj.job.length > 64) { InputWasWrong('inp_p_job', t("index.job_length_error")); }
        if (profile_obj.desc.length > 100) { InputWasWrong('inp_p_desc', t("index.profile_description_length_error")); }
        if (profile_obj.website.length > 100) { InputWasWrong('inp_p_website', t("index.website_length_error")); }
        if (profile_obj.city.length > 24) { InputWasWrong('inp_p_city', t("index.city_length_error")); }
        if (profile_obj.address.length > 600) { InputWasWrong('inp_p_address', t("index.address_length_error")); }

        if (notFilled != '') { document.querySelector('#'+notFilled).scrollIntoView(); }
        else { Click_SaveProfileInfo_2(e); }
    }
    const Click_SaveProfileInfo_2 = async (e) => {
        if (AxiosWork) { return; } else { document.querySelector('.js_profile_submit').classList.add('opacity-50'); AxiosWork = true; }
        console.log(profile_obj);
        let response = await api_SetProfile(profile_obj);
        AxiosWork = false; document.querySelector('.js_profile_submit').classList.remove('opacity-50');
        console.log(response);
        if( response.status == 200 ) {
            document.querySelectorAll('.inp_profile_error').forEach(function(el){el.classList.remove('text-red-400');}); document.querySelectorAll('.inp_profile_error').forEach(function(el){el.classList.add('text-green-400');});
            document.querySelectorAll('.inp_profile_error').forEach(function(el){el.innerHTML = 'Saved successfuly';}); document.querySelectorAll('.inp_profile_error').forEach(function(el){el.classList.remove('hidden');});
            startChanges = false;
        } else {
            if (response.status == 400) {
                if (typeof response.data.fullname !== 'undefined') { InputWasWrong('inp_p_fullname', response.data.fullname); }
                //if (typeof response.data.email !== 'undefined') { InputWasWrong('inp_p_email', response.data.email); }

                if (response.data.email) {
                    response.data.email.split(',').forEach(function(tph, i){
                        if (typeof response.data['email'+(i+1)] !== 'undefined') { InputWasWrong('inp_p_email'+(i+1), response.data['email'+(i+1)]); }
                    });
                }
                if (response.data.phone) {
                    response.data.phone.split(',').forEach(function(tph, i){
                        if (typeof response.data['phone'+(i+1)] !== 'undefined') { InputWasWrong('inp_p_phone'+(i+1), response.data['phone'+(i+1)]); }
                    });
                }

                if (typeof response.data.company !== 'undefined') { InputWasWrong('inp_p_company', response.data.company); }
                if (typeof response.data.companyice !== 'undefined') { InputWasWrong('inp_p_companyice', response.data.companyice); }
                if (typeof response.data.job !== 'undefined') { InputWasWrong('inp_p_job', response.data.job); }
                if (typeof response.data.desc !== 'undefined') { InputWasWrong('inp_p_desc', response.data.desc); }
                if (typeof response.data.website !== 'undefined') { InputWasWrong('inp_p_website', response.data.website); }
                if (typeof response.data.city !== 'undefined') { InputWasWrong('inp_p_city', response.data.city); }
                if (typeof response.data.address !== 'undefined') { InputWasWrong('inp_p_address', response.data.address); }
            } else if (response.status == 401) {
                window.location.href = "/login";
            } else if (response.status == 404 || response.status == 500) {
                document.querySelectorAll('.inp_profile_error').forEach(function(el){el.classList.remove('text-green-400');}); document.querySelectorAll('.inp_profile_error').forEach(function(el){el.classList.add('text-red-400');});
                document.querySelectorAll('.inp_profile_error').forEach(function(el){el.innerHTML = t("index.request_error");});
                document.querySelectorAll('.inp_profile_error').forEach(function(el){el.classList.remove('hidden');});
            }
        }
    }
    function ReloadPage(e) { window.location.reload(); }
    function GoToUsernamePage(e) { window.open('/'+profile_obj.username, '_blank'); }

    function InputWasWrong(dom_id, error_text = '') { 
        console.log(dom_id, error_text);
        if (document.querySelector('#'+dom_id) != null) { notFilled = dom_id; document.querySelector('#'+dom_id).style.border = '1px solid #f56565'; }
        if (error_text != '') { document.querySelector('.'+dom_id+'_error').innerHTML = error_text; document.querySelector('.'+dom_id+'_error').classList.remove('hidden'); }
    }
    function InputClearError(dom_id) {
        if (document.querySelector('#'+dom_id) != null) { document.querySelector('#'+dom_id).style.border = null; }
        document.querySelectorAll('.'+dom_id+'_error').forEach(function(el){el.classList.add('hidden');});
        document.querySelectorAll('.inp_profile_error').forEach(function(el){el.classList.add('hidden');});
    }

    window.onbeforeunload = function(){
        if (startChanges) { return 'Changes you made may not be saved?'; }
    };

    const contentStyle = { width: 'auto', maxWidth: '100%' };
    let modalAvatarImg = '';
    let modalCompanyImg = '';
    function finishCrop(imgType, canvas) {
        canvas.toBlob(function(blob) {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                var base64data = reader.result;
                document.querySelector('.inp_p_'+imgType+'_img').setAttribute('src', reader.result);
            };
            profile_obj[imgType] = new File( [ blob ], imgType+'.png', {type: 'image/png'} );
        });
    }

    return (  
    <>
        <section id="ProfilePage" className="w-full min-h-screen flex flex-col lg:flex-row font-primary overflow-hidden">
            <ProfileNavBar id_menu_active={0} />

            <div className="w-full bg-[#F5FAF5]  pl-[2rem] pr-[2rem] lg:px-[2rem] py-[3rem] h-screen overflow-y-auto">
                <div className="flex flex-col gap-[2rem]">
                    <div className="flex flex-row justify-between">
                        <h2 className="text-2xl lg:text-3xl font-[600]">{t("index.edit_profile")}</h2>
                        <button className="flex items-center min-w-[7.625rem] gap-2 hover:opacity-75" onClick={(e) => GoToUsernamePage(e)} style={{ padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px"}}>
                            <AiOutlineLink size="26px" color="#000" /> {t("index.view_profile")}
                        </button>
                    </div>
                    <div className=" flex items-center gap-[1rem] ">
                        <span className="text-[#A4A4A4]">{t("index.my_profile")}</span>
                        <span>
                            <img src={Superior} alt="" />
                        </span>
                        <span className="font-[600]">{t("index.personal_info")}</span>
                    </div>
                    <div className="hidden md:flex items-center justify-between gap-[2rem] sm:w-[90%] lg:w-full">
                        <div className="flex flex-col gap-[.7rem]">
                            <h2 className="lg:text-2xl font-[600]">{t("index.personal_info")}</h2>
                            <p className="text-[#A4A4A4] text-[.9rem]">{t("index.update_photo")}</p>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center justify-center m_md:hidden gap-[1rem]">
                                <button className="min-w-[7.625rem]" onClick={(e) => ReloadPage(e)}
                                        style={{ padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px"}}>
                                    {t("index.cancel")}
                                </button>
                                <button className="min-w-[7.625rem] text-white js_profile_submit" onClick={(e) => Click_SaveProfileInfo(e)}
                                        style={{ padding: "0.875rem 1.875rem", background: "linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)", boxShadow: "4px 16px 32px rgba(35, 180, 107, 0.35)", borderRadius: "40px"}} >
                                    {t("index.save")}
                                </button>
                            </div>
                            <span className="text-red-400 text-sm pt-2 pl-4 inp_profile_error hidden"></span>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }} ></div>
                <div className="flex flex-col sm:flex-row gap-[1rem] lg:gap-[3rem]">
                    <div className="flex flex-col justify-center">
                        <h3 className="whitespace-nowrap font-[500] pb-[.6rem] lg:text-[1.2rem] lg:w-16 xl:w-48">{t("index.your_photo")}</h3>
                        <p className="whitespace-nowrap text-[#A4A4A4] lg:w-16 xl:w-48">{t("index.profile_display")}</p>
                    </div>
                    <div className="w-full">
                        <div className="flex justify-center w-full xl:w-10/12 2xl:w-8/12">
                            <div className="flex flex-col relative">
                                <div id="file-upload-form" className="uploader max-h-[12rem]">
                                    <input id="file-upload-avatar" type="file" name="fileUploadAvatar" accept=".jpeg, .jpg, .png" onChange={(e) => FileChange_UploadAvatar(e)} />
                                    <label htmlFor="file-upload-avatar" id="file-drag">
                                        <img id="file-image" src="#" alt="Preview" className="hidden h-[12rem] w-[12rem]" style={{height: '11rem', width: '12rem', borderRadius: '50%'}} />
                                        <div id="start" style={{height: '11rem', width: '12rem', borderRadius: '50%'}}>
                                            <div id="notimage" className="hidden">Please select an image</div>
                                            <div>
                                                <img className="inp_p_avatar_img rounded-full object-cover" src={ProfileImg} id="img-default-hide" alt=""
                                                    style={{ width: '180px', height: '180px'}} />
                                            </div>
                                            <span id="file-upload-btn" className="absolute bottom-0 -right-[1.4rem] cursor-pointer">
                                                <img src={EditBtnIcon} alt="" />
                                            </span>
                                        </div>
                                    </label>
                                </div>
                                <span className="text-red-400 text-sm pt-2 inp_p_avatar_error hidden"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }} ></div>
                <div className="flex flex-col lg:flex-row items-baseline gap-[1rem] lg:gap-[4rem]" >
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <FaIdCard className="mr-2" size="24px" color="#00cc66" />
                        <h3 className="flex font-[600] lg:w-10 xl:w-44 items-center">{t("index.full_name")}</h3>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-row gap-[1rem] lg:gap-[3rem] w-full xl:w-10/12 2xl:w-8/12">
                            <div className="flex flex-col gap-[1rem] w-full">
                                <div className="relative w-full">
                                    <input id="inp_p_fullname" onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_FullName_profile(e)} maxLength="30"
                                        type="text" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem] input-styles profile-inputs" placeholder=" " />
                                    <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                        John Doe
                                    </label>
                                </div>
                            </div>
                        </div>
                        <span className="text-red-400 text-sm inp_p_fullname_error hidden"></span>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col lg:flex-row items-center gap-[1rem] lg:gap-[4rem]">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <img className="pr-2" src={PhoneIcon} alt="" />
                        <h3 className="font-[600] lg:w-16 xl:w-48 xl:mr-2">{t("index.phone")}</h3>
                    </div>
                    <div className="w-full js_profile_phonediv">

                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col lg:flex-row items-center gap-[1rem] lg:gap-[4rem]">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <img className="pr-2" src={EmailIcon} alt="" />
                        <h3 className="font-[600] lg:w-16 xl:w-48 xl:mr-2">{t("index.show_email")}</h3>
                    </div>
                    <div className="w-full js_profile_emaildiv">

                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col lg:flex-row items-baseline gap-[1rem] lg:gap-[4rem]">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <AiOutlineLink className="mr-2" size="24px" color="#00cc66" />
                        <h3 className="flex font-[600] lg:w-10 xl:w-44 items-center">{t("index.username")}</h3>
                    </div>

                    <div className="w-full">
                        <div className="flex flex-row gap-[1rem] lg:gap-[3rem] w-full xl:w-10/12 2xl:w-8/12">
                            <div className="flex flex-col gap-2 w-full">
                                <div className="relative w-full">
                                    <input id="inp_p_username" maxLength="24"
                                        type="text" className="w-full cursor-default focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem] input-styles profile-inputs bg-green_readonly" placeholder=" " readOnly />
                                    {/*onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_Username_profile(e)}*/}
                                    {/*<label className="flex gap-[1rem] field-placeholder items-center" htmlFor="inputName">
                                        <AiOutlineLink size="26px" color="#00cc66" /> Enter Your Username               
                                    </label>
                                    <span className="text-red-400 text-sm inp_p_username_error hidden"></span>*/}
                                </div>
                                <a href="/" className="pl-4 text-sm js_profile_username_url" style={{ textDecoration: 'none' }}>https://cartenfc.ma/<span className="text-green_1 js_profile_username_span"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col sm:flex-row w-full gap-[1rem] lg:gap-[4rem] items-center company-tab-size">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <IoBusiness className="mr-2" size="24px" color="#00cc66" />
                        <h3 className="flex font-[600] lg:w-10 xl:w-44 items-center">{t("index.company")}</h3>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col sm:flex-row gap-[1rem] lg:gap-[3rem] w-full xl:w-10/12 2xl:w-8/12">

                            <div className="flex justify-center w-full">
                                <div className="flex flex-col relative">
                                    <div id="file-upload-form" className="uploader max-h-[12rem]">
                                        <input id="file-upload-company" type="file" name="fileUploadCompany" accept=".jpeg, .jpg, .png, .gif" onChange={(e) => FileChange_UploadCompany(e)} />
                                        <label htmlFor="file-upload-company" id="file-drag">
                                            <img id="file-image" src="#" alt="Preview" className="hidden h-[12rem] w-[12rem]" style={{height: '11rem', width: '12rem', borderRadius: '50%'}} />
                                            <div id="start" style={{height: '11rem', width: '12rem', borderRadius: '50%'}}>
                                                <div id="notimage" className="hidden">Please select an image</div>
                                                <div>
                                                    <img className="inp_p_companyimg_img rounded-full object-cover" src={CompanyImg} id="img-default-hide" alt=""
                                                        style={{ width: '180px', height: '180px'}} />
                                                </div>
                                                <span id="file-upload-btn" className="absolute bottom-0 -right-[1.4rem] cursor-pointer">
                                                    <img src={EditBtnIcon} alt="" />
                                                </span>
                                            </div>
                                        </label>
                                    </div>
                                    <span className="text-red-400 text-sm pt-2 inp_p_companyimg_error hidden"></span>
                                </div>
                            </div>

                            <div className="flex flex-col w-full justify-center items-center gap-4">
                                <div className="relative w-full">
                                    <input id="inp_p_company" type="text" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem]  input-styles profile-inputs"
                                        placeholder=" " onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_Company_profile(e)} maxLength="50"
                                    />
                                    <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                        CarteNFC...
                                    </label>
                                    <span className="text-red-400 text-sm inp_p_company_error hidden"></span>
                                </div>
                                <div className="relative w-full">
                                    <input id="inp_p_companyice" type="text" className="w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem]  input-styles profile-inputs"
                                        placeholder=" " onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_CompanyIce_profile(e)} maxLength="20"
                                    />
                                    <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                        Company ICE
                                    </label>
                                    <span className="text-red-400 text-sm inp_p_companyice_error hidden"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col items-baseline lg:flex-row gap-[1rem] lg:gap-[4rem] lg:items-center">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <FaBriefcase className="mr-2" size="24px" color="#00cc66" />
                        <h3 className="flex font-[600] lg:w-10 xl:w-44 items-center">{t("index.job")}</h3>
                    </div>

                    <form className="flex flex-col lg:flex-row w-full gap-[1rem] lg:gap-[3rem]">
                        <div className="flex flex-col gap-[1rem] w-full xl:w-10/12 2xl:w-8/12">
                            <div className="relative">
                                <input id="inp_p_job" onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_Job_profile(e)} maxLength="64"
                                    type="text" className="input-styles w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem]" placeholder=" "/>
                                <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                    Doctor...
                                </label>
                                <span className="text-red-400 text-sm inp_p_job_error hidden"></span>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col items-baseline lg:flex-row gap-[1rem] lg:gap-[4rem] lg:items-center">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <MdDescription className="mr-2" size="24px" color="#00cc66" />
                        <h3 className="flex font-[600] lg:w-10 xl:w-44 items-center">{t("index.profile_description")}</h3>
                    </div>

                    <form className="flex flex-col lg:flex-row w-full gap-[1rem] lg:gap-[3rem]">
                        <div className="flex flex-col gap-[1rem] w-full xl:w-10/12 2xl:w-8/12">
                            <div className="relative">
                                <input id="inp_p_desc" onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_Desc_profile(e)} maxLength="100"
                                    type="text" className="input-styles w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem]" placeholder=" "/>
                                <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                    We thrive to offer success to the world with long term business relationship.
                                </label>
                                <span className="text-red-400 text-sm inp_p_desc_error hidden"></span>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col items-baseline lg:flex-row gap-[1rem] lg:gap-[4rem] lg:items-center">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <img className="pr-2" src={Website} alt="" />
                        <h3 className="font-[600] lg:w-16 xl:w-48 xl:mr-2">{t("index.website")}</h3>
                    </div>

                    <div className="flex flex-col lg:flex-row w-full gap-[1rem] lg:gap-[3rem]">
                        <div className="flex flex-col gap-[1rem] w-full xl:w-10/12 2xl:w-8/12">
                            <div className="relative">
                                <input id="inp_p_website" onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_WebSite_profile(e)} maxLength="100"
                                    type="text" className="input-styles w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem]" placeholder=" "/>
                                <label className="flex gap-[1rem] field-placeholder" htmlFor="inputName">
                                    {t("index.website")}...
                                </label>
                                <span className="text-red-400 text-sm inp_p_website_error hidden"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col items-baseline lg:flex-row gap-[1rem] lg:gap-[4rem] lg:items-center">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <FaAtlas className="mr-2" size="24px" color="#00cc66" />
                        <h3 className="flex font-[600] lg:w-10 xl:w-44 items-center">{t("index.country")}</h3>
                    </div>

                    <div className="flex lg:flex-row w-full gap-[1rem] lg:gap-[3rem]">
                        <div className="flex gap-[1rem] w-full xl:w-10/12 2xl:w-8/12">
                            <div className="relative w-full">
                                <select id="inp_p_country" onChange={(e) => Change_Input_Country_profile(e)}
                                        className="input-styles w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem]" name="inp_country" defaultValue="morocco">
                                    <option value="morocco" className="text-black">Morocco</option>
                                    <option value="senegal" className="text-black">Senegal</option>
                                    <option value="nigeria" className="text-black">Nigeria</option>
                                    <option value="ivorycoast" className="text-black">Ivory Coast</option>
                                    <option value="ghana" className="text-black">Ghana</option>
                                    <option value="spain" className="text-black">Spain</option>
                                    <option value="france" className="text-black">France</option>
                                    <option value="unitedkingdom" className="text-black">United Kingdom</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col items-baseline lg:flex-row gap-[1rem] lg:gap-[4rem] lg:items-center">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <img className="pr-2" src={Location} alt="" />
                        <h3 className="font-[600] lg:w-16 xl:w-48 xl:mr-2">{t("index.city")}</h3>
                    </div>

                    <div className="flex lg:flex-row w-full gap-[1rem] lg:gap-[3rem]">
                        <div className="flex gap-[1rem] w-full xl:w-10/12 2xl:w-8/12">
                            <div className="relative w-full">
                                <input id="inp_p_city" onFocus={onFocus} onBlur={onBlur} onChange={(e) => Change_Input_City_profile(e)} maxLength="24"
                                    type="text" className="input-styles w-full focus:outline-none focus:ring-2 focus:ring-green-400 pl-[2rem]" placeholder=" "/>
                                <label className="flex gap-[1rem] field-placeholder " htmlFor="inputName">
                                    {t("index.city")}...
                                </label>
                                <span className="text-red-400 text-sm inp_p_city_error hidden"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border w-full my-[1.5rem]" style={{ color: "#989898", opacity: ".2" }}></div>

                <div className="flex flex-col lg:flex-row items-center gap-[1rem] lg:gap-[4rem]">
                    <div className="flex m_lg:w-full m_lg:justify-center">
                        <img className="pr-2" src={Location} alt="" />
                        <h3 className="font-[600] lg:w-16 xl:w-48 xl:mr-2">{t("index.address")}</h3>
                    </div>
                    <div className="w-full js_profile_addressdiv">

                    </div>
                </div>

                <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center gap-[1rem] pt-[3rem] md:hidden">
                        <button className="min-w-[7.625rem]" onClick={(e) => ReloadPage(e)}
                                style={{padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px" }} >
                            {t("index.cancel")}
                        </button>
                        <button className="min-w-[7.625rem] text-white" onClick={(e) => Click_SaveProfileInfo(e)}
                                style={{ padding: "0.875rem 1.875rem", background: "linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)", boxShadow: "4px 16px 32px rgba(35, 180, 107, 0.35)", borderRadius: "40px" }} >
                            {t("index.save")}
                        </button>
                    </div>
                    <span className="text-red-400 text-xl pt-2 pl-4 inp_profile_error hidden"></span>
                </div>
            </div>
            <Popup
                trigger={ <div className="js_avatar_modal"></div> }
                modal
                lockScroll
                {...{ contentStyle }}
            >
                {close => ( <AvatarModal close={close} modalAvatarImg={modalAvatarImg} finishCrop={finishCrop} /> )}
            </Popup>
            <Popup
                trigger={ <div className="js_companyimg_modal"></div> }
                modal
                lockScroll
                {...{ contentStyle }}
            >
                {close => ( <CompanyImgModal close={close} modalCompanyImg={modalCompanyImg} finishCrop={finishCrop} /> )}
            </Popup>
        </section>
    </>
    );
};


export default Profile;
import React from 'react';
import Header_Desktop from './Header_Desktop';
import Header_Mobile from './Header_Mobile';



function Header() {
  return (
    <div>
       <Header_Desktop />    
       <Header_Mobile />
       <div className="border w-full"></div>
    </div>
  )
}

export default Header;
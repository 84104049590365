import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';


import stCost from './assets/css/cost.css';
import stOut from './assets/css/output.css';
import stStyle from './assets/css/style.css';
import stBIndex from './assets/css/bundle_index.css';

import stBProduct from './assets/css/bundle_products.css';
import stBCheck from './assets/css/bundle_checkout.css';
import stIndex from './assets/css/index.css';
import stCustom from './assets/css/g_custom.css';

import './assets/icons/icons.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './assets/scss/profile-styles.scss';

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import BlackHalfCircle from '../../assets/images/black-half-circle.png' 
import Ellipse13 from '../../assets/images/Ellipse 13.png' 
import Group877 from '../../assets/images/Group 877.png'

function Faq({}) {
    const { t, i18n } = useTranslation();

    //#Faq Methods
    function Click_ToggleFaqArea(e) {
        
        document.querySelectorAll('.js_index_faq_title').forEach(function(el){ el.classList.remove('active'); });
        document.querySelectorAll('.js_index_faq_desk').forEach(function(el){ el.classList.add('content'); });
        e.currentTarget.classList.toggle('active');
        e.currentTarget.nextSibling.classList.toggle('content');
    }
  
    return (
    <section id="faq" className="flex m_md:flex-col justify-center lg:items-center animated-scroll pt-[3rem] pb-24 lg:py-[7rem] relative active">
        <div className="hidden lg:block absolute 
            right-[25px]
            top-[15px]  w-[13rem]">
            <img src={BlackHalfCircle} alt=""/>
        </div>
        <div className="hidden lg:block absolute 
            right-[105px]
            top-[145px] w-[5rem]">
            <img src={Ellipse13} alt=""/>
        </div>

        <div className="flex md:hidden text-center justify-center pb-6 text-6xl">
            <h2 className="z-10">{t("index.have_a_questions_1")} <span className="text-primary">{t("index.have_a_questions_2")}</span>?</h2>
        </div>

        <div className="p-3 m_md:mb-8 md:ml-16 lg:ml-32 rounded-2xl space-y-3 h-fit" style={{ backgroundColor: '#f5faf4' }}>
            <button className="active w-full text-black rounded-full block whitespace-nowrap faqhover py-3 px-6 text-xl lg:py-6 lg:px-12 lg:text-2xl">
                Digital Business Card
            </button>
            <button className="w-full text-black rounded-full block faqhover py-3 px-6 text-xl lg:py-6 lg:px-12 lg:text-2xl">
                Card Design
                <span className="ripple" style={{top: '-24.8469px', left: '270.7px', backgroundColor: 'rgba(255, 255, 255, 0.5)', width: '61.6px', height: '61.6px', opacity: '0'}}></span>
            </button>
            <button className="w-full text-black rounded-full block faqhover py-3 px-6 text-xl lg:py-6 lg:px-12 lg:text-2xl">
                Special Options
                <span className="ripple" style={{top: '17.2688px', left: '130.8px', backgroundColor: 'rgba(255, 255, 255, 0.5)', width: '27.4px', height: '27.4px', opacity: '0'}}></span>
            </button>
        </div>

        <div className="flex-1 lg:mb-[3rem] m_md:mr-8 m_lg:ml-8 md:mr-16 lg:ml-16 lg:mr-32">
            <div className="flex m_md:hidden text-center m_md:justify-center pb-12 text-4xl lg:text-7xl xl:text-9xl">
                <h2 className="z-10">{t("index.have_a_questions_1")} <span className="text-primary">{t("index.have_a_questions_2")}</span>?</h2>
            </div>
            <div className="accordion text-xl lg:text-2xl xl:text-4xl">
                <div className="accordion-item">
                    <a className="flex flex-col relative w-full m_lg:py-3 m_lg:pl-3 m_lg:pr-8 lg:p-6 xl:px-7 xl:py-12 text-black js_index_faq_title"
                        onClick={(e) => Click_ToggleFaqArea(e)} style={{borderBottom: '1px solid #e5e5e5'}}>{t("index.card_phone")}</a>
                    <div className="content py-2 lg:py-4 xl:py-8 text-gray-500 text-lg lg:text-xl xl:text-2xl js_index_faq_desk">
                        <p className="border-l-4 border-green_1 pl-3 text-left">{t("index.smartphone_compat")}</p>
                    </div>
                </div>
                <div className="accordion-item">
                    <a className="flex flex-col relative w-full m_lg:py-3 m_lg:pl-3 m_lg:pr-8 lg:p-6 xl:px-7 xl:py-12 text-black js_index_faq_title"
                        onClick={(e) => Click_ToggleFaqArea(e)} style={{borderBottom: '1px solid #e5e5e5'}}>{t("index.materials")}</a>
                    <div className="content py-2 lg:py-4 xl:py-8 text-gray-500 text-lg lg:text-xl xl:text-2xl js_index_faq_desk">
                        <p className="border-l-4 border-green_1 pl-3 text-left">{t("index.classic_cards")}</p>
                    </div>
                </div>
                <div className="accordion-item">
                    <a className="flex flex-col relative w-full m_lg:py-3 m_lg:pl-3 m_lg:pr-8 lg:p-6 xl:px-7 xl:py-12 text-black js_index_faq_title"
                        onClick={(e) => Click_ToggleFaqArea(e)} style={{borderBottom: '1px solid #e5e5e5'}}>{t("index.can_i_order_multiple_cards")}</a>
                    <div className="content py-2 lg:py-4 xl:py-8 text-gray-500 text-lg lg:text-xl xl:text-2xl js_index_faq_desk">
                        <p className="border-l-4 border-green_1 pl-3 text-left">{t("index.order_cards")}</p>
                    </div>
                </div>
                <div className="accordion-item">
                    <a className="flex flex-col relative w-full m_lg:py-3 m_lg:pl-3 m_lg:pr-8 lg:p-6 xl:px-7 xl:py-12 text-black js_index_faq_title"
                        onClick={(e) => Click_ToggleFaqArea(e)} style={{borderBottom: '1px solid #e5e5e5'}}>{t("index.how_do_cartenfc_cards_work")}</a>
                    <div className="content py-2 lg:py-4 xl:py-8 text-gray-500 text-lg lg:text-xl xl:text-2xl js_index_faq_desk">
                        <p className="border-l-4 border-green_1 pl-3 text-left">{t("index.card_function")}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="hidden lg:flex absolute bottom-0 left-0 w-[9rem]">
            <img src={Group877} alt=""/>
        </div>
    </section>
  )
 
}
export default Faq
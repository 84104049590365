import React from 'react';
import { React_color_simple } from '../React_color';
import { useTranslation } from "react-i18next";

export function LeftMenuTemplates({}) {
    return (
        <>
            <div className="flex pl-8 pb-2 text-base font-semibold">Frontward Templates</div>
            <div className="grid gap-2" style={{gridTemplateRows: '50px 50px 50px', padding: '0px 2rem' }}>
                test
            </div>
            <div className="flex pl-8 pb-2 text-base font-semibold">Backward Tempaltes</div>
            <div className="grid gap-2" style={{gridTemplateRows: '50px 50px 50px', padding: '0px 2rem' }}>
                test
            </div>
        </>
    )
}
export function LeftMenuText({Click_AddNewText, Click_ToggleLeftMenu}) {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="flex h-14 items-center font-bold text-lg justify-between px-8" style={{boxShadow: 'rgb(0 0 0 / 50%) 1px 0px 1px'}}>
                <div className="">{t("index.text_2")}</div>
                <div className="flex cursor-pointer" onClick={(e) => Click_ToggleLeftMenu(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24">
                        <path d="M6.35592 11.7386L13.5034 4.59108L11.9123 3L4.76559 10.1475C4.27534 10.6386 4 11.3042 4 11.9981C4 12.6921 4.27534 13.3576 4.76559 13.8488L11.9123 21L13.5034 19.4089L6.35592 12.2577C6.3218 12.2236 6.29474 12.1832 6.27628 12.1386C6.25781 12.0941 6.24831 12.0463 6.24831 11.9981C6.24831 11.9499 6.25781 11.9022 6.27628 11.8576C6.29474 11.8131 6.3218 11.7726 6.35592 11.7386Z" fill="currentColor"></path>
                        <path d="M20.9618 4.59108L19.3707 3L11.1677 11.2022C11.0632 11.3067 10.9802 11.4308 10.9236 11.5673C10.867 11.7039 10.8379 11.8503 10.8379 11.9981C10.8379 12.146 10.867 12.2923 10.9236 12.4289C10.9802 12.5655 11.0632 12.6895 11.1677 12.794L19.3707 21L20.9618 19.4089L13.5547 11.9981L20.9618 4.59108Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <div className="flex px-8 py-2 text-base font-semibold">{t("index.click_text_5")}</div>
            <div className="grid gap-2" style={{gridTemplateRows: '50px 50px 50px', padding: '0px 2rem' }}>
                <div className="flex pl-4 items-center cursor-pointer" onClick={(e) => Click_AddNewText(e, 48, 'bold')} style={{fontSize: '1.66rem', background: 'rgba(0, 0, 0, 0.043)', fontWeight: '700' }}>Add a heading</div>
                <div className="flex pl-4 items-center cursor-pointer" onClick={(e) => Click_AddNewText(e, 24, 'normal')} style={{fontSize: '1.12rem', background: 'rgba(0, 0, 0, 0.043)', fontWeight: '500' }}>Add a subheading</div>
                <div className="flex pl-4 items-center cursor-pointer" onClick={(e) => Click_AddNewText(e, 12, 'normal')} style={{fontSize: '0.76rem', background: 'rgba(0, 0, 0, 0.043)', fontWeight: '300' }}>Add a litle bit of body text</div>
            </div>
        </>
    )
}
export function LeftMenuImages({Parent_methods}) {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="flex h-14 items-center font-bold text-lg justify-between px-8" style={{boxShadow: 'rgb(0 0 0 / 50%) 1px 0px 1px'}}>
                <div className="">{t("index.images_3")}</div>
                <div className="flex cursor-pointer" onClick={(e) => Parent_methods.Click_ToggleLeftMenu(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24">
                        <path d="M6.35592 11.7386L13.5034 4.59108L11.9123 3L4.76559 10.1475C4.27534 10.6386 4 11.3042 4 11.9981C4 12.6921 4.27534 13.3576 4.76559 13.8488L11.9123 21L13.5034 19.4089L6.35592 12.2577C6.3218 12.2236 6.29474 12.1832 6.27628 12.1386C6.25781 12.0941 6.24831 12.0463 6.24831 11.9981C6.24831 11.9499 6.25781 11.9022 6.27628 11.8576C6.29474 11.8131 6.3218 11.7726 6.35592 11.7386Z" fill="currentColor"></path>
                        <path d="M20.9618 4.59108L19.3707 3L11.1677 11.2022C11.0632 11.3067 10.9802 11.4308 10.9236 11.5673C10.867 11.7039 10.8379 11.8503 10.8379 11.9981C10.8379 12.146 10.867 12.2923 10.9236 12.4289C10.9802 12.5655 11.0632 12.6895 11.1677 12.794L19.3707 21L20.9618 19.4089L13.5547 11.9981L20.9618 4.59108Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <div className="flex px-8 py-2 text-base font-semibold">{t("index.input_image_url_6")}</div>
            <div className="flex flex-col px-8">
                <label className="" htmlFor="inp_text_text active:border-black">
                    <input className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none" type="file"
                        onChange={(e) => Parent_methods.FileChange_UploadNewImage(e)} />
                </label>
                <span className="pb-1 text-base font-semibold"> or </span>
                <div data-baseweb="input" className="flex box-border overflow-hidden w-full border-2 border-solid rounded-none text-base leading-6 border-neutral-200 bg-neutral-200 pl-3.5 pr-0" style={{ transitionProperty: 'border', transitionDuration: '200ms', transitionTimingFunction: 'cubic-bezier(0.2, 0.8, 0.4, 1)'}}>
                    <div data-baseweb="base-input" className="flex w-full text-base leading-6 text-black bg-neutral-200" style={{ transitionProperty: 'background-color', transitionDuration: '200ms', transitionTimingFunction: 'cubic-bezier(0.2, 0.8, 0.4, 1)'}}>
                        <input type="text" name="" placeholder="Enter link of your image" defaultValue=""
                            onFocus={(e) => Parent_methods.Focus_BlackBorder(e,true)} onBlur={(e) => Parent_methods.Focus_BlackBorder(e,false)} onChange={(e) => Parent_methods.Change_SetImgLink_ForImgIEditorElement(e)}
                            className="box-border bg-transparent border-0 border-none outline-0 w-full m-0 py-2.5 px-3.5 text-base leading-6 text-black caret-black focus:outline-none" style={{ boxShadow: 'none' }} />
                    </div>
                </div>
            </div>

            
        </>
    )
}
export function LeftMenuColors({Parent_methods, preColor, reRender = false}) {
    const { t, i18n } = useTranslation();
    return (
        <div style={{ position: 'absolute', inset: '0px', overflow: 'scroll', marginRight: '-15px', marginBottom: '-15px' }}>
            <div className="flex h-14 items-center font-bold text-lg justify-between px-8" style={{boxShadow: 'rgb(0 0 0 / 50%) 1px 0px 1px'}}>
                <div className="">Text</div>
                <div className="flex cursor-pointer" onClick={(e) => Parent_methods.Click_ToggleLeftMenu(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24">
                        <path d="M6.35592 11.7386L13.5034 4.59108L11.9123 3L4.76559 10.1475C4.27534 10.6386 4 11.3042 4 11.9981C4 12.6921 4.27534 13.3576 4.76559 13.8488L11.9123 21L13.5034 19.4089L6.35592 12.2577C6.3218 12.2236 6.29474 12.1832 6.27628 12.1386C6.25781 12.0941 6.24831 12.0463 6.24831 11.9981C6.24831 11.9499 6.25781 11.9022 6.27628 11.8576C6.29474 11.8131 6.3218 11.7726 6.35592 11.7386Z" fill="currentColor"></path>
                        <path d="M20.9618 4.59108L19.3707 3L11.1677 11.2022C11.0632 11.3067 10.9802 11.4308 10.9236 11.5673C10.867 11.7039 10.8379 11.8503 10.8379 11.9981C10.8379 12.146 10.867 12.2923 10.9236 12.4289C10.9802 12.5655 11.0632 12.6895 11.1677 12.794L19.3707 21L20.9618 19.4089L13.5547 11.9981L20.9618 4.59108Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <div style={{ padding: '0px 2rem 2rem' }}>
                <div style={{ padding: '0.5rem 0px', cursor: 'default', fontSize: '0.96rem' }}>{t("index.document_colors_7")}</div>
                <React_color_simple preColor={preColor} parent_method={Parent_methods.Change_ChangeFontColor} reRender={reRender} />
            </div>
            <div style={{ padding: '0px 2rem' }}>
                <div style={{ cursor: 'default', padding: '0.5rem 0px', fontSize: '0.96rem' }}>Default colors</div>
            </div>
            <div style={{ display: 'grid', gap: '0.5rem', padding: '0px 2rem 2rem', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr' }}>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#f19066', true)} style={{ height: '42px', background: 'rgb(241, 144, 102)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#f5cd79', true)} style={{ height: '42px', background: 'rgb(245, 205, 121)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#546de5', true)} style={{ height: '42px', background: 'rgb(84, 109, 229)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#e15f41', true)} style={{ height: '42px', background: 'rgb(225, 95, 65)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#c44569', true)} style={{ height: '42px', background: 'rgb(196, 69, 105)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#574b90', true)} style={{ height: '42px', background: 'rgb(87, 75, 144)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#f78fb3', true)} style={{ height: '42px', background: 'rgb(247, 143, 179)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#3dc1d3', true)} style={{ height: '42px', background: 'rgb(61, 193, 211)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#e66767', true)} style={{ height: '42px', background: 'rgb(230, 103, 103)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#303952', true)} style={{ height: '42px', background: 'rgb(48, 57, 82)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#000000', true)} style={{ height: '42px', background: 'rgb(0, 0, 0)', borderRadius: '4px', cursor: 'pointer' }}></div>
                <div onClick={(e) => Parent_methods.Change_ChangeFontColor('#ffffff', true)} style={{ height: '42px', background: 'rgb(255, 255, 255)', borderRadius: '4px', cursor: 'pointer', border: '1px dashed rgb(125 125 125 / 50%)' }}></div>
            </div>
        </div>
    )
}
export function LeftMenuSaveLoad({Parent_methods, currTemplate, allTemplates}) {
    const { t, i18n } = useTranslation();
    let selectDiv = [];
    allTemplates.forEach((el, i) => { selectDiv.push(<option key={'slopt_'+i} value={el.name} className="text-black">{el.name}</option>); });
    return (
        <>
            <div className="flex h-14 items-center font-bold text-lg justify-between px-8" style={{boxShadow: 'rgb(0 0 0 / 50%) 1px 0px 1px'}}>
                <div className="">Save / Load</div>
                <div className="flex cursor-pointer" onClick={(e) => Parent_methods.Click_ToggleLeftMenu(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24">
                        <path d="M6.35592 11.7386L13.5034 4.59108L11.9123 3L4.76559 10.1475C4.27534 10.6386 4 11.3042 4 11.9981C4 12.6921 4.27534 13.3576 4.76559 13.8488L11.9123 21L13.5034 19.4089L6.35592 12.2577C6.3218 12.2236 6.29474 12.1832 6.27628 12.1386C6.25781 12.0941 6.24831 12.0463 6.24831 11.9981C6.24831 11.9499 6.25781 11.9022 6.27628 11.8576C6.29474 11.8131 6.3218 11.7726 6.35592 11.7386Z" fill="currentColor"></path>
                        <path d="M20.9618 4.59108L19.3707 3L11.1677 11.2022C11.0632 11.3067 10.9802 11.4308 10.9236 11.5673C10.867 11.7039 10.8379 11.8503 10.8379 11.9981C10.8379 12.146 10.867 12.2923 10.9236 12.4289C10.9802 12.5655 11.0632 12.6895 11.1677 12.794L19.3707 21L20.9618 19.4089L13.5547 11.9981L20.9618 4.59108Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <div className="flex flex-col px-8 py-2">
                <div className="flex text-base font-medium flex-nowrap text-gray-500">Current template :</div>
                <div className="font-bold js_leftmenu_currtemp">{currTemplate}</div>
            </div>
            <hr />
            <div className="flex flex-col px-8 py-2">
                <div className="flex py-2 text-base font-semibold">Save current design as new template</div>
                <div data-baseweb="input" className="flex box-border overflow-hidden w-full border-2 border-solid rounded-none text-base leading-6 border-neutral-200 bg-neutral-200 pl-3.5 pr-0" style={{ transitionProperty: 'border', transitionDuration: '200ms', transitionTimingFunction: 'cubic-bezier(0.2, 0.8, 0.4, 1)'}}>
                    <div data-baseweb="base-input" className="flex w-full text-base leading-6 text-black bg-neutral-200" style={{ transitionProperty: 'background-color', transitionDuration: '200ms', transitionTimingFunction: 'cubic-bezier(0.2, 0.8, 0.4, 1)'}}>
                        <input type="text" name="" placeholder="Enter name for new template" defaultValue=""
                            onFocus={(e) => Parent_methods.Focus_BlackBorder(e,true)} onBlur={(e) => Parent_methods.Focus_BlackBorder(e,false)} onChange={(e) => Parent_methods.ClearErrorMsg('save')}
                            className="box-border bg-transparent border-0 border-none outline-0 w-full m-0 py-2.5 px-3.5 text-base leading-6 text-black caret-black focus:outline-none" style={{ boxShadow: 'none' }} />
                    </div>
                </div>
                <button type="submit" className="my-2 cursor-scale py-2 px-6 text-white w-full font-[500] text-xl hover:opacity" onClick={(e) => Parent_methods.Click_SaveTemplate(e, e.currentTarget.previousSibling.firstChild.firstChild.value, 'save')}
                    style={{background: '#329B67' }}>Save</button>
                <span className="text-red-400 text-sm save_error"></span>
            </div>
            <hr />
            <div className="flex flex-col px-8 py-2">
                <div className="flex py-2 text-base font-semibold">Update alredy created template with current design</div>
                <select className="block w-full rounded-lg appearance-none shadow-none focus:outline-none"
                    style={{ height: '59.19px', border: '1px solid #a5abb5', marginTop: '-5px', color: '#96a0b5' }} onChange={(e) => Parent_methods.ClearErrorMsg('update')}>
                    {selectDiv}
                </select>
                <button type="submit" className="my-2 cursor-scale py-2 px-6 text-white w-full font-[500] text-xl hover:opacity" onClick={(e) => Parent_methods.Click_SaveTemplate(e, e.currentTarget.previousSibling.value, 'update')}
                    style={{background: '#329B67' }}>Update</button>
                <span className="text-red-400 text-sm update_error"></span>
            </div>
            <hr />
            <hr />
            <div className="flex flex-col px-8 py-2">
                <div className="flex py-2 text-base font-semibold">Load template from list</div>
                <select className="block w-full rounded-lg appearance-none shadow-none focus:outline-none"
                    style={{ height: '59.19px', border: '1px solid #a5abb5', marginTop: '-5px', color: '#96a0b5' }} onChange={(e) => Parent_methods.ClearErrorMsg('load')} >
                    {selectDiv}
                </select>
                <button type="submit" className="my-2 cursor-scale py-2 px-6 text-white w-full font-[500] text-xl hover:opacity" onClick={(e) => Parent_methods.Click_LoadTemplate(e)}
                    style={{background: '#329B67' }}>Load</button>
                <span className="text-red-400 text-sm load_error"></span>
            </div>
            <hr />
            
        </>
    )
}
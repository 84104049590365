import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { IoCopyOutline } from "react-icons/io5";

export function SocialModal({ close }) {
    const { t, i18n } = useTranslation();
    const [socialLinks, setSocialLinks] = useState([]);
    
    let curr_link = encodeURIComponent(window.location.href);
    let curr_text = encodeURIComponent('Hello, This is my CarteNFC Social link: ');
    let share_link = [];

    useEffect(() => {
        if (socialLinks.length == 0){
            share_link.push('https://www.facebook.com/sharer.php?t='+curr_text+'&u='+curr_link);
            share_link.push('https://web.whatsapp.com/send?text='+curr_text+'+'+curr_link);
            share_link.push('https://www.linkedin.com/shareArticle?title='+curr_text+'&url='+curr_link);
            share_link.push('https://t.me/share/url?url='+curr_link+'&text='+curr_text+'&to=');
            share_link.push('https://snapchat.com/scan?attachmentUrl='+curr_text+'+'+curr_link+'&utm_source=cartenfc');
            setSocialLinks(share_link);
        }
    });

    function Click_CopyText(e) {
        navigator.clipboard.writeText('Hello, This is my CarteNFC Social link: '+window.location.href);
        document.querySelector('.js_copied').style.opacity = "1";
        setTimeout(() => { document.querySelector('.js_copied').style.opacity = null; }, 1500);
    }

    return (
        <div className="relative">
            <div className="flex justify-between items-start p-4 rounded-t">
                <button type="button" className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" style={{ outline: 'none' }}
                     onClick={(e) => close()} data-modal-toggle="defaultModal">
                    <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="flex flex-col justify-center px-4 pb-4">
                <h1 className="text-center text-3xl font-semibold text-gray-mute">Share</h1>
                <div className="grid grid-cols-6 gap-2 mt-4">
                    <div className="flex absolute top-24 bg-green-100 border border-green-400 text-green-700 px-2 py-1 rounded z-20 opacity-0 transition-opacity duration-300 js_copied">
                        <span className="block sm:inline">{t("index.copied_9")}</span>
                    </div>
                    <div className="w-full">
                        <button onClick={(e) => Click_CopyText(e)} className="flex-1 flex hover group justify-center items-center p-6 aspect-square bg-gray-100 w-full btn-gradient text-white rounded-md block text-xl btn btn-ripple disabled:pointer-events-none disabled:opacity-75">
                            <IoCopyOutline className="text-gray-mute group-hover:text-white transition-colors duration-500" size="24px" style={{minWidth: '24px'}} />
                        </button>
                    </div>
                    <div className="w-full">
                        <a href={socialLinks[0]} target="_blank" className="flex-1 flex hover group justify-center items-center p-6 aspect-square bg-gray-100 w-full btn-gradient text-white rounded-md block text-xl btn btn-ripple disabled:pointer-events-none disabled:opacity-75">
                            <i className="before-block flex icon-facebook text-gray-mute text-2xl group-hover:text-white transition-colors duration-300"></i>
                        </a>
                    </div>
                    <div className="w-full">
                        <a href={socialLinks[1]} target="_blank" className="flex-1 flex hover group justify-center items-center p-6 aspect-square bg-gray-100 w-full btn-gradient text-white rounded-md block text-xl btn btn-ripple disabled:pointer-events-none disabled:opacity-75">
                            <i className="before-block flex icon-whatsapp text-gray-mute text-2xl group-hover:text-white transition-colors duration-300"></i>
                        </a>
                    </div>
                    <div className="w-full">
                        <a href={socialLinks[2]} target="_blank" className="flex-1 flex hover group justify-center items-center p-6 aspect-square bg-gray-100 w-full btn-gradient text-white rounded-md block text-xl btn btn-ripple disabled:pointer-events-none disabled:opacity-75">
                            <i className="before-block flex icon-linkdin text-gray-mute text-2xl group-hover:text-white transition-colors duration-300"></i>
                        </a>
                    </div>
                    <div className="w-full">
                        <a href={socialLinks[3]} target="_blank" className="flex-1 flex hover group justify-center items-center p-6 aspect-square bg-gray-100 w-full btn-gradient text-white rounded-md block text-xl btn btn-ripple disabled:pointer-events-none disabled:opacity-75">
                            <i className="before-block flex icon-telegram text-gray-mute text-2xl group-hover:text-white transition-colors duration-300"></i>
                        </a>
                    </div>
                    <div className="w-full">
                        <a href={socialLinks[4]} target="_blank" className="flex-1 flex hover group justify-center items-center p-6 aspect-square bg-gray-100 w-full btn-gradient text-white rounded-md block text-xl btn btn-ripple disabled:pointer-events-none disabled:opacity-75">
                            <i className="before-block flex icon-snapchat text-gray-mute text-2xl group-hover:text-white transition-colors duration-300"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { gform, gProducts } from '../Products';
import {canvas} from './Product_step2_editor_new';
import { api_getProductMocks } from '../lib/Axios'
import { useTranslation } from "react-i18next";

import BackArrowImg from '../../assets/images/Arrow-back.svg';
import RightArrowImg from '../../assets/images/Arrow_right.png';
import Group857Img from '../../assets/images/Group-857.png';
import ProductPageCardImg from '../../assets/images/cards/product-page-card.png';
import GreenCardImg from '../../assets/images/cards/card-green.png';
import PuprpleCardImg from '../../assets/images/cards/card-purple.png';
import BrownCardImg from '../../assets/images/cards/card-brown.png';
import LightblueCardImg from '../../assets/images/cards/card-lightblue.png';
import Image9Img from '../../assets/images/image 9.png';
import Image10Img from '../../assets/images/image 10.png';
import Image11Img from '../../assets/images/image 11.png';


function Product_step1_information({Step1_methods, pmaterial}) {
    const { t, i18n } = useTranslation();
    let currencySymbol = process.env.REACT_APP_CURR_SYMB;
    let currencyRate = process.env.REACT_APP_CURR_RATE;
    let img_folder = process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/static/productmock/';
    let img_product_folder = process.env.REACT_APP_SSL + process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/static/product/';
    let mockImgs = [];

    
    const loadProductMocks = async (product_id) => {
        console.log('load mocks - '+product_id);
        let response = await api_getProductMocks(product_id);
        if( response.status == 200 ) {
            mockImgs = response.data; console.log(mockImgs);
            let mocks_div = [];
            document.querySelector('#ProductImg').setAttribute('src', img_folder+mockImgs[0]);
            document.querySelector('.js_productpreview_step3').setAttribute('src', img_product_folder+mockImgs[0][0]+".png");
            mockImgs.forEach(function(el, i){
                let tempActive = ''; if (i == 0) { tempActive = 'card'; }
                mocks_div.push( <div key={'pmocsk-'+i} className={'rounded-2xl flex items-center justify-center h-28 js_img_diff_angle '+tempActive} style={{ backgroundColor: 'CCCCCC6B' }} onClick={(e) => Click_ImgPreviewDifferenceAngle(e)} >
                                    <img src={img_folder+mockImgs[i]} className="max-h-28 py-2"/>
                                </div>);
            });
            js_MocksDiv.render(mocks_div);
        } else {
            if (response.status == 500) { window.location.href = "/404"; }
        }
    }

    let js_MaterialDiv = null; let material_div = []; 
    let js_ColorButtons = null; let color_div = [];
    let js_MocksDiv = null;

    useEffect(() => {
        js_ColorButtons = ReactDOM.createRoot(document.querySelector('.js_inform1_color'));
        js_MaterialDiv = ReactDOM.createRoot(document.querySelector('.js_inform1_material'));
        js_MocksDiv = ReactDOM.createRoot(document.querySelector('.js_inform1_mocks'));

        //Create dynamical material
        let choosed_material = 0; let product_id = 0;
        gProducts.material.forEach(function(el, i){ if (pmaterial == el.replace(/ /g, '').toLowerCase()) { choosed_material = i; product_id = i; } });
        loadProductMocks(gProducts.ids[product_id]);
        gProducts.material.forEach(function(el, i){ 
            let tcolor = 'btn-nocolor'; 
            if (i == choosed_material) { tcolor = 'btn-color'; gform.material = el; gform.priceMaterial = gProducts.price[i]; }
            material_div.push(<button key={'matbutt'+i} className={tcolor+' cursor-scale js_inform1_mat'} onClick={(e) => Click_ChooseMaterial(e, el, i)}>{el}</button>);
        });
        js_MaterialDiv.render(material_div);
        //Create dynamical color
        RenderNewColors(choosed_material);
        reCalculatedPrice(gform.priceMaterial, -1);
        ////RenderCanvasToNewColor();
    });
    // --  --  --  Step 1 logic  --  --  --          --         --         --         --         --         --         --         --         --         --
    //#1 Material / Customization
    function Click_ChooseMaterial(e, attr_material, btn_id) { 
        let e2 = null; let product_id = 0;
        document.querySelectorAll('.js_inform1_mat').forEach(function(el, i){ if (btn_id == i) { e2 = el; gform.priceMaterial = gProducts.price[i]; product_id = i; } });
        loadProductMocks(gProducts.ids[product_id]);
        ChangeStateOfButtons('.js_inform1_mat', [e.currentTarget, e2]); gform.material = attr_material;

        RenderNewColors(gProducts.material.indexOf(attr_material));
        reCalculatedPrice(gform.priceMaterial, -1);
        ////RenderCanvasToNewColor();
    }
    function RenderNewColors(materialId) {
        color_div = [];
        gProducts.color[materialId].forEach(function(el, i){
            let smallcolor = el.replace(/ /g, '').toLowerCase();
            let tactivate = ''; if (i == 0) { tactivate = 'clactivated'; gform.color = el; }
            color_div.push(<div key={`color`+i} className={`${smallcolor} h-6 ${tactivate}`} onClick={(e) => Click_ChooseColorButton(e, el, i)} ></div>);
        });
        js_ColorButtons.render(color_div);
    }
    function Click_ChooseCustomization(e, attr_customization, btn_id) {
        let e2 = null;
        document.querySelectorAll('.js_inform1_cust').forEach(function(el, i){ if (btn_id == i) { e2 = el; } });
        ChangeStateOfButtons('.js_inform1_cust', [e.currentTarget, e2]); gform.customization = attr_customization;
        console.log(btn_id);
        if (btn_id == 0) { reCalculatedPrice(-1,0); } else { reCalculatedPrice(-1,100); }
    }
    function ChangeStateOfButtons(cls_name, e_arr) {
        e_arr.forEach(function(e){
            if (!e.classList.contains('clactivated')) {
                e.parentElement.querySelector(cls_name+'.btn-color').classList.add('btn-nocolor');
                e.parentElement.querySelector(cls_name+'.btn-color').style.color = null; e.parentElement.querySelector(cls_name+'.btn-color').style.borderColor = null;
                e.parentElement.querySelector(cls_name+'.btn-color').classList.remove('btn-color');
                e.classList.remove('btn-nocolor'); e.classList.add('btn-color');
            }
        });
    }
    //#1 Imgs Different sides
    function Click_ImgPreviewDifferenceAngle(e) {
        if (e.currentTarget.classList.contains('card')) { return; }
        e.currentTarget.parentElement.querySelector('.js_img_diff_angle.card').classList.remove('card'); e.currentTarget.classList.add('card');
        document.querySelector('#ProductImg').setAttribute('src', e.currentTarget.querySelector('img').getAttribute('src'));
    }
    //#1 Colors
    function RenderCanvasToNewColor() {
        gform.BackTemplate.background = gform.color.replace(/ /g, '').toLowerCase(); canvas.loadFromJSON(gform.BackTemplate, canvas.renderAll.bind(canvas)); document.querySelector('.js_editor_changePreviewBack').setAttribute('src','data:image/svg+xml;utf8,' + encodeURIComponent(canvas.toSVG()));
        gform.FrontTemplate.background = gform.color.replace(/ /g, '').toLowerCase(); canvas.loadFromJSON(gform.FrontTemplate, canvas.renderAll.bind(canvas)); document.querySelector('.js_editor_changePreviewFront').setAttribute('src','data:image/svg+xml;utf8,' + encodeURIComponent(canvas.toSVG()));
    }
    //// ////
    function Click_ChooseColorButton(e, btn_color, btn_id) {
        let e2 = null;
        document.querySelectorAll('.js_inform1_color div').forEach(function(el, i){ if (btn_id == i) { e2 = el; } });
        [e.currentTarget, e2].forEach(function(e){
            if (e.classList.contains('clactivated')) { return; }
            e.parentElement.querySelector('.clactivated').classList.remove('clactivated'); e.classList.add('clactivated');
        });
        gform.color_id = btn_id; gform.color = btn_color;
        ////RenderCanvasToNewColor();
    }
    function reCalculatedPrice(newMaterialPrice, newCustomizationPrice) {
        if (newMaterialPrice != -1) { gform.priceMaterial = newMaterialPrice; }
        if (newCustomizationPrice != -1) { gform.priceCustomization = newCustomizationPrice; }
        document.querySelector('.js_inform1_price').innerHTML = ((parseInt(gform.priceMaterial)+parseInt(gform.priceCustomization))*currencyRate).toFixed(2) + ' ' + currencySymbol;
        document.querySelector('.js_inform3_price').innerHTML = ((parseInt(gform.priceMaterial)+parseInt(gform.priceCustomization))*currencyRate).toFixed(2) + ' ' + currencySymbol;
        document.querySelector('.js_inform3_price2').innerHTML = ((parseInt(gform.priceMaterial)+parseInt(gform.priceCustomization))*currencyRate).toFixed(2) + ' ' + currencySymbol;
        document.querySelector('.js_inform3_price3').innerHTML = ((parseInt(gform.priceMaterial)+parseInt(gform.priceCustomization))*currencyRate).toFixed(2) + ' ' + currencySymbol;
        document.querySelector('.js_submitbutt_step3').innerHTML = t("index.pay") + ' ' + ((parseInt(gform.priceMaterial)+parseInt(gform.priceCustomization))*currencyRate).toFixed(2) + ' ' + currencySymbol;
    }
    
    return (
        <div className="block js_product_step1">
            <div className="py-12 overflow-hidden relative">
                <div data-aos="fade-left"
                    data-aos-easing="linear"
                    data-aos-duration="1000" className=" flex px-[5rem]  w-full h-[46px] -mt-[1rem] md:mb-[3rem] lg:mb-0 relative overflow-hidden margin-add ">
                    <a href="/" target="_blank" className="flex flex gap-x-[1rem] items-center cursor-pointer" style={{ textDecoration: 'none' }}>
                        <img className="w-[2rem] cursor-scale small " src={BackArrowImg} alt="" srcSet="" />
                        <span className="font-bold cursor-scale small text-[1rem]" >{t("index.back")}</span>
                    </a>
                </div>
                <div className="absolute top-0 right-0 w-[10rem]" data-aos="fade-left" data-aos-delay="500">
                    <img src={Group857Img} alt="" />
                </div>
                <div className="flex m_xl:flex-col items-center justify-between gap-x-10 mt-[3rem] xl:pl-10">
                    <div className="flex flex-col m_xl:w-full xl:w-3/12 rounded-2xl relative gap-y-[3rem]" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000" style={{ backgroundColor: 'CCCCCC6B' }}>
                        <div className="flex mx-auto mb-[1rem] relative justify-center" >
                            <img className="floating-div feature-image m_xl:w-1/2" src=""  id="ProductImg" />
                        </div>
                        <div className="-ml-[1rem] flex justify-center gap-10 js_inform1_mocks" >
                            
                        </div>
                    </div>
                    <div className="flex flex-col lg:px-20 m_xl:pt-10 m_xl:w-full xl:w-9/12 m_xl:items-center">
                        <h1  className="product-title text-[1.3rem] cursor-scale grow card__title">{t("index.card_nfc")}</h1>
                        <div id="Description" style={{ letterSpacing: '-0.03em' }}>
                            <p  className="text-[#838383] text-sm pt-[1rem] m_xl:px-10 cursor-scale small">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                            <div className="mt-[2rem] mb-[2rem]" style={{ border: '0.947648px solid #D7D2D2CC' }}></div>
                            <div className="flex flex-col gap-y-[1rem] pb-[1rem]">
                                <div className="flex items-center m_xl:justify-center gap-x-[1rem] pb-[1rem]">
                                    <div className="w-[2rem]"><img src={Image9Img} alt="" /></div>
                                    <div className="text-[1.3rem] font-[600] cursor-scale small">
                                        <h2>{t("index.material")}</h2>
                                    </div>
                                </div>
                                <div className="m_xl:grid m_md:grid-cols-2 md:grid-cols-4 m_xl:justify-items-center xl:flex gap-[.4rem] w-full btn-wrapper-mq font-[500] js_inform1_material">
                                </div>
                                <div className=" mb-[1rem] mt-[1rem]" style={{ border: '0.947648px solid #D7D2D2CC' }}></div>
                            </div>
                            <div className="flex flex-col">
                                <div className="mb-[1.4rem] flex items-center m_xl:justify-center gap-x-[1rem] gap-y-[1rem]">
                                    <div className="w-[2rem]"><img src={Image10Img} alt="" /></div>
                                    <h4 className="text-[1.3rem] font-[600] cursor-scale small">{t("index.choose_colors")}</h4>
                                </div>
                                <div className="colors w-full m_xl:justify-center mt-[1rem] gap-x-[1rem] js_inform1_color">

                                </div>
                            </div>
                            <div className="my-[2rem] lg:ml-0" style={{ border: '0.947648px solid #D7D2D2CC' }}></div>
                            <div className="flex flex-col gap-y-[1rem] lg:w-full">
                                <div className="flex items-center m_xl:justify-center gap-x-[1rem]">
                                    <div className="w-[2rem]"><img src={Image11Img} alt="" /></div>
                                    <div className="text-[1.3rem] font-[600] cursor-scale small">
                                        <h2>{t("index.customization")}</h2>
                                    </div>
                                </div>
                                <div className="flex gap-[1rem] mt-[1rem] m_xl:justify-center">
                                    <button className="btn-color w-[11rem] cursor-scale js_inform1_cust" onClick={(e) => Click_ChooseCustomization(e, 'standart', 0)}>{t("index.standard")}</button>
                                    <button className="btn-nocolor w-[11rem] cursor-scale js_inform1_cust" onClick={(e) => Click_ChooseCustomization(e, 'custom', 1)}>{t("index.custom_print")}</button>
                                </div> 
                            </div>
                            <div className="my-[2rem]" style={{ border: '0.947648px solid #D7D2D2CC' }}></div>
                            <div className="flex gap-x-[2rem] items-center m_xl:justify-center">
                                <div className=" mt-[15px]">
                                    <span className="price text-[#329B67] text-xl font-bold cursor-scale small card__price js_inform1_price">{(gform.priceMaterial*currencyRate).toFixed(2)} {currencySymbol}</span>
                                </div>
                                <button className="custom-btn cursor-scale flex items-center justify-center gap-x-[2rem] js_inform1_nextstp" onClick={(e) => Step1_methods.Click_NextStep(e)} style={{ borderRadius: '94.04px; padding: 0.9rem 0' }} 
                                    data-id="6">{t("index.continue")} <span className="w-[1.5rem]"><img src={RightArrowImg} alt="" /></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product_step1_information;
import React from "react";
import { useTranslation } from "react-i18next";

import HeaderNavBar from "./Index_page/HeaderNavBar";
import HeaderLandingSection from "./Index_page/HeaderLandingSection";
import CostSavings from './Index_page/CostSavings';
import FeaturesDesktop from './Index_page/FeaturesDesktop';
import CustomSection from './Index_page/CustomSection';
import HowItWorks from './Index_page/HowItWorks';
import Testimonials from './Index_page/Testimonials';
import ProductsSectionNew from './Index_page/ProductsSectionNew';
import Faq from './Index_page/Faq';
import Footer from './Index_page/Footer';


function Index() {
    const { t, i18n } = useTranslation();
    
    return (
        <div id="IndexPage" className="App IndexPage overflow-x-hidden">
            <div className="antialiased h-mq font-1 lg:min-h-[40.188rem] overflow-hidden ">
                <HeaderNavBar />
                <HeaderLandingSection />
            </div>
            <CostSavings />
            <FeaturesDesktop />
            <CustomSection />
            <HowItWorks />
            <Testimonials />
            <ProductsSectionNew />
            <Faq />
            <Footer />
        </div>
    );
}

export default Index;
import React, { useEffect, useRef } from 'react';
import QRCodeStyling from "qr-code-styling";

export function QRCodeStyling_render({size, url}) {
	const ref = useRef(null);
	const qrCode = new QRCodeStyling({
        width: size,
        height: size,
        dotsOptions: {
            color: "#00000",
            type: "square"
        }
    });

	useEffect(() => {
   		qrCode.append(ref.current);
        	console.log(ref);
        qrCode.update({
			data: url
        });
    });

    return (
        <div ref={ref}>
            
        </div>
    )
}
import React, { useEffect } from 'react';
import { api_Guest, api_Check, api_SendRecoverCode, api_CheckRecoverCode, api_SetRecoverPassword } from './lib/Axios'
import { useTranslation } from "react-i18next";
import Logo from "../assets/images/logo.svg";
import HeroImg from '../assets/images/frame-110.png'
import HeroImgMob from '../assets/images/Frame-97.png'
import Cloud from '../assets/images/cloud-3.png'
const ForgotPassword = () => {
    const { t, i18n } = useTranslation();
    var AxiosWork = false;
    var step = 1;
    // -- Axios Works -- 
    const guestToken = async () => {
        let response = await api_Check();
        if( response.status == 500 || response.status == 401 ) { response = await api_Guest(); }
    }
    useEffect(() => {
        guestToken();
    });
    // -- Events Step 1 -- 
    const Click_ForgotButtonStep1 = async (e) => {
        if (step == 1){
            if (document.querySelector('#inp_email').value == '') { InputWasWrong('inp_email'); }
            else if (document.querySelector('#inp_email').value.indexOf('@') == -1) { InputWasWrong('inp_email', t("index.email_format_error")); }
            else if (document.querySelector('#inp_email').value.length < 7 || document.querySelector('#inp_email').value.length > 50) { InputWasWrong('inp_email', t("index.email_length_error")); }
            else {
                if (AxiosWork) { return; } else { document.querySelector('.js_forgot_button_step1').classList.add('opacity-50'); AxiosWork = true; }
                let response = await api_SendRecoverCode({ email: document.querySelector('#inp_email').value });
                AxiosWork = false; document.querySelector('.js_forgot_button_step1').classList.remove('opacity-50');
                console.log(response);
                if( response.status == 200 ) {
                    step = 2;
                    document.querySelector('.js_activate_input_email_step1').disabled = true;
                    document.querySelector('#inp_activate').placeholder = t("index.enter_activation_code");
                    document.querySelector('#inp_activate').disabled = false;
                    document.querySelector('#inp_activate').focus();
                    document.querySelector('.js_forgot_button_step1').innerHTML = t("index.activate_account");

                    document.querySelector('.js_forgot_success_message_step1').classList.remove('hidden');
                } else {
                    if (response.status == 400) { InputWasWrong('inp_email', 'Sorry you can ask recover code only 1 time per minute.'); }
                    if (response.status == 401) { InputWasWrong('inp_email', t("index.internal_error")); }
                    if (response.status == 404) { InputWasWrong('inp_email', t("index.email_not_found_error")); }
                    if (response.status == 500) { InputWasWrong('inp_email', t("index.unexpected_error")); }
                } 
            }
        } else if (step == 2){
            if (document.querySelector('#inp_activate').value.length < 15 || document.querySelector('#inp_activate').value.length > 17) { InputWasWrong('inp_activate', t("index.wrong_activate_code_error")); }
            else {
                if (AxiosWork) { return; } else { document.querySelector('.js_forgot_button_step1').classList.add('opacity-50'); AxiosWork = true; }
                let response = await api_CheckRecoverCode({ code: document.querySelector('#inp_activate').value });
                AxiosWork = false; document.querySelector('.js_forgot_button_step1').classList.remove('opacity-50');
                console.log(response);
                if( response.status == 200 ) {
                    step = 3;
                    document.querySelector('.js_forgot_div_step1').classList.add('hidden');
                    document.querySelector('.js_forgot_div_step2').classList.remove('hidden');
                } else {
                    if (response.status == 400) { InputWasWrong('inp_activate', 'Sorry the code is expired refresh page and try again.'); }
                    if (response.status == 401) { InputWasWrong('inp_activate', t("index.internal_error")); }
                    if (response.status == 404) { InputWasWrong('inp_activate', 'Recover code was not found please input your activation code.'); }
                    if (response.status == 500) { InputWasWrong('inp_activate', t("index.unexpected_error")); }
                }
            }
        }
    }
    function Change_Input_Email_forgot1(e) { e.currentTarget.style.border = null; InputClearError('inp_email'); }
    function Change_Input_ForgotCode_forgot1(e) { e.currentTarget.style.border = null; InputClearError('inp_activate'); }
    function InputClearError(dom_id) { document.querySelector('.'+dom_id+'_error').classList.add('hidden'); }
    function InputWasWrong(dom_id, error_text = '') {
        document.querySelector('#'+dom_id).style.border = '1px solid #f56565';
        if (error_text != '') { document.querySelector('.'+dom_id+'_error').innerHTML = error_text; document.querySelector('.'+dom_id+'_error').classList.remove('hidden'); }
    }

    // -- Events Step 2 -- 
    function Change_Input_Pass_activate2(e) { e.currentTarget.style.border = null; InputClearError('inp_pass'); }
    function Change_Input_RePass_activate2(e) { e.currentTarget.style.border = null; InputClearError('inp_repass'); }
    const Click_ActivateButtonStep2 = async (e) => {
        let notFilled = '';
        if (document.querySelector('#inp_pass').value == '') { InputWasWrong('inp_pass'); notFilled = 'r'; }
        else if (document.querySelector('#inp_pass').value.length < 6 || document.querySelector('#inp_pass').value.length > 24) { InputWasWrong('inp_pass', t("index.password_length_error")); notFilled = 'r';  }
        
        if (document.querySelector('#inp_repass').value == '') { InputWasWrong('inp_repass'); notFilled = 'r1';  }
        else if (document.querySelector('#inp_repass').value.length < 6 || document.querySelector('#inp_repass').value.length > 24) { InputWasWrong('inp_repass', t("index.confirm_password_length_error")); notFilled = 'r1';  }
        
        if (notFilled == '' && document.querySelector('#inp_pass').value != document.querySelector('#inp_repass').value){
            InputWasWrong('inp_repass', t("index.password_mismatch_error"));
        } else if (notFilled == '') {
            if (AxiosWork) { return; } else { document.querySelector('.js_forgot_button_step2').classList.add('opacity-50'); AxiosWork = true; }
            let response = await api_SetRecoverPassword({ 
                code: document.querySelector('#inp_activate').value,
                email: document.querySelector('#inp_email').value,
                password: document.querySelector('#inp_repass').value
            });
            AxiosWork = false; document.querySelector('.js_forgot_button_step2').classList.remove('opacity-50');
            if( response.status == 200 ) {
                localStorage.setItem('token', response.data);
                localStorage.setItem('islogged', true);
                window.location.href = "/profile";
            } else {
                if (response.status == 401) { InputWasWrong('inp_repass', t("index.internal_error")); }
                if (response.status == 404) { InputWasWrong('inp_repass', t("index.email_not_found_refresh_error")); }
                if (response.status == 500) { InputWasWrong('inp_repass', t("index.unexpected_error")); }
            }
        }
    }

    return (
    <section className='flex flex-col lg:flex-row w-full min-h-screen font-primary overflow-x-hidden'>
        <div className="block lg:hidden w-full ml-10 my-2" >
            <a className="cursor-pointer" href="/"><img src={Logo} alt='nfc-logo'/></a>
        </div>

        <div className='w-full lg:mt-[3rem] flex items-center justify-center lg:hidden'>
            <div className='flex w-full bg-[#F5F5F5] m-[1rem] justify-center items-center relative' style={{borderRadius:'60px'}}>
                <div className='absolute -top-[1rem] -left-[6rem] w-[10rem]'><img src={Cloud} alt=''/></div>
                <div className='flex w-full justify-center'>
                    <img className="w-full" src={HeroImg} alt='' style={{maxWidth: '504px'}}/>
                </div>
            </div>
        </div>

        <div className="flex flex-row w-full">
            <div className="w-full lg:w-1/2 flex flex-col justify-center gap-[2rem] mx-6 sm:mx-20 md:mx-40 lg:mx-8 2xl:mx-40 xl:gap-[5rem] js_forgot_div_step1">
                <nav className="hidden lg:block absolute top-8 left-8" style={{ width: '178px' }}>
                    <a className="cursor-pointer" href="/"><img src={Logo} alt='nfc-logo'/></a>
                </nav>
                <div className='flex flex-col w-full gap-[1rem] xl:mt-[1rem]'>
                    <div className='flex flex-col gap-[1rem] m_lg:items-center m_lg:pt-2 '>
                        <h1 className='text-[1.8rem] sm:text-[2.3rem] font-[600] 2xl:text-[3.4rem]'>Forgot Password?</h1>
                    </div>
                    <div className='flex flex-col gap-[1.4rem] w-full mt-[2rem]'>
                        <span className='text-[#ccc]'>Enter your Email address below.<br></br>We will email you instructions on recovering your account.</span>
                        <input type="text" id="inp_email" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input js_activate_input_email_step1" 
                            onChange={(e) => Change_Input_Email_forgot1(e)} placeholder=""/>
                        <span className="text-red-400 w-full text-sm hidden inp_email_error"></span>
                    </div>
                    <div className='flex flex-col gap-[1.4rem] w-full mt-[2rem]'>
                        <div className="flex justify-between">
                            <span className='text-[#ccc]'>Recover Code</span>
                            <span className='text-[#08C768] font-[600] js_forgot_success_message_step1 hidden'>{t("index.code_send_successful")}</span>
                        </div>
                        <input type="text" id="inp_activate" className="input-styles profile-inputs lg:py-4 semi_hero_input text-center text-2xl text-[#08C768] py-3.5 placeholder-green-400"
                            placeholder="XXXX XXXX XXXX XXXX" onChange={(e) => Change_Input_ForgotCode_forgot1(e)} disabled
                            style={{ border: 'none', background: 'rgb(219, 245, 219)', borderRadius: '40px' }} />
                        <span className="text-red-400 w-full text-sm hidden inp_activate_error"></span>
                    </div>
                    <button className='primary-btn text-white mt-[1rem] py-3 js_forgot_button_step1' onClick={(e) => Click_ForgotButtonStep1(e)}
                        style={{ background: 'linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)', boxShadow: '4px 16px 32px rgb(35 180 107 / 35%)', borderRadius: '40px', outline: 'none' }}>
                        Send Recover Code to Email
                    </button>
                </div>
            </div>
            <div className="hidden w-full lg:w-1/2 flex flex-col justify-center gap-[2rem] mx-6 sm:mx-20 md:mx-40 lg:mx-8 2xl:mx-40 xl:gap-[5rem] js_forgot_div_step2">
                <nav className="hidden lg:block absolute top-8 left-8" style={{ width: '178px' }}>
                    <a className="cursor-pointer" href="/"><img src={Logo} alt='nfc-logo'/></a>
                </nav>
                <div className='flex flex-col w-full gap-[1rem] xl:mt-[1rem]'>
                    <div className='flex flex-col gap-[1rem] m_lg:items-center m_lg:pt-2 '>
                        <h4 className='text-[#08C768] font-[500] 2xl:text-2xl'>{t("index.step_2")}</h4>
                        <h1 className='text-[1.8rem] sm:text-[2.3rem] font-[600] 2xl:text-[3.4rem]'>{t("index.create_account")}</h1>
                    </div>
                    <div className='flex flex-col gap-[1.4rem] w-full mt-[2rem]'>
                        <div className="flex justify-between">
                            <span className='text-[#ccc]'>{t("index.password")}</span>
                            <span className="text-[#08C768] font-[600]">{t("index.code_is_activated_successfully")}</span>
                        </div>
                        <input type="password" id="inp_pass" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input js_activate_input_pass_step2" 
                            onChange={(e) => Change_Input_Pass_activate2(e)} placeholder={t("index.password")}/>
                        <span className="text-red-400 w-full text-sm hidden inp_pass_error"></span>
                    </div>
                    <div className='flex flex-col gap-[1.4rem] w-full mt-[2rem]'>
                        <span className='text-[#ccc]'>{t("index.confirm_password")}</span>
                        <input type="password" id="inp_repass" className="input-styles profile-inputs py-4 disabled:bg-gray-200 semi_hero_input js_activate_input_repass_step2" 
                            onChange={(e) => Change_Input_RePass_activate2(e)} placeholder={t("index.repeat_your_password")}/>
                        <span className="text-red-400 w-full text-sm hidden inp_repass_error"></span>
                    </div>

                    <button className='primary-btn text-white mt-[1rem] py-3 js_forgot_button_step2' onClick={(e) => Click_ActivateButtonStep2(e)} 
                        style={{ background: 'linear-gradient(94.73deg, #00CC66 -8.28%, #23B46B 110.81%)', boxShadow: '4px 16px 32px rgb(35 180 107 / 35%)', borderRadius: '40px' }}>
                        {t("index.create_account")}
                    </button>
                    <div className='flex items-center ml-[1rem] sm:ml-[3rem] mt-[1rem] pb-14 w-full'>
                        <span className='text-[#ccc]'>{t("index.already_have_an_account")} <a href='#' className='text-black'>{t("index.sign_in")}</a></span>
                    </div>
                </div>
            </div>
            <div className='hidden w-1/2 m-[1rem] lg:flex items-center relative' style={{borderRadius:'60px'}}>
                <div className='absolute -top-[1rem] -left-[6rem] w-[14rem]'><img src={Cloud} alt=''/></div>
                <div className='w-full'>
                    <div className='w-full bg-[#F5F5F5]' style={{borderRadius:'60px'}}><img src={HeroImg} alt='' /></div>
                </div>
            </div>
        </div>
    </section>
    )

}
export default ForgotPassword;
import { useEffect, useState } from "react";
import ReactDOM from 'react-dom/client';
import { useTranslation } from "react-i18next";
import { api_GetSocial, api_SetSocial, api_GetProfile } from './lib/Axios'
import ProfileNavBar from "./Settings_page/ProfileNavBar";
import { AiOutlineLink } from "react-icons/ai";
import { BsInstagram, BsWhatsapp, BsFacebook, BsSnapchat, BsLinkedin, BsYoutube, BsTelegram, BsDribbble, BsPinterest, BsBehance, BsReddit } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { AiFillWechat } from "react-icons/ai";

import Logo from "../assets/images/logo.svg";
import PersonalInfo from "../assets/images/personal-info.png";
import PersonalIcon from "../assets/images/PersonalIcon.png";
import ProfileIcon2 from "../assets/images/Personal-icon-svg.svg";
import Superior from "../assets/images/SuperiorIcon.png";
import PlusIcon from "../assets/images/Plus-Icon.png";
import InstagramImg from "../assets/images/facebook-icon.svg";
import FacebookImg from "../assets/images/whatsapp-icon.svg";
import LinkedInImg from "../assets/images/linkedin-icon.svg";
import TelegramImg from "../assets/images/telegram-icon.svg";
import WhatsappImg from "../assets/images/whatsapp.png";
import SnapImg from "../assets/images/snap-icon.svg";
import Logout from "../assets/images/Logout.svg";
import ProfileImg from "../assets/images/ProfileImg.png";
const SocialProfile = () => {
    const { t, i18n } = useTranslation();
    let profile_obj = {};
    var AxiosWork = false;
    let js_SocialMediaDiv = null;
    let social_arr = ['instagram', 'whatsapp', 'facebook', 'tiktok', 'snap', 'linkedin', 'youtube', 'telegram', 'weechat', 'dribble', 'pinterest', 'behance', 'reddit'];
    let social_img = { 
        instagram: <BsInstagram size="24px" color="#00cc66" />, whatsapp: <BsWhatsapp size="24px" color="#00cc66" />, facebook: <BsFacebook size="24px" color="#00cc66" />,
        tiktok: <FaTiktok size="24px" color="#00cc66" />, snap: <BsSnapchat size="24px" color="#00cc66" />, linkedin: <BsLinkedin size="24px" color="#00cc66" />,
        youtube: <BsYoutube size="24px" color="#00cc66" />, telegram: <BsTelegram size="24px" color="#00cc66" />, weechat: <AiFillWechat size="24px" color="#00cc66" />,
        dribble: <BsDribbble size="24px" color="#00cc66" />, pinterest: <BsPinterest size="24px" color="#00cc66" />, behance: <BsBehance size="24px" color="#00cc66" />,
        reddit: <BsReddit size="24px" color="#00cc66" />
    }
    let social_obj = {
        instagram: [{name: '', active: false}],
        whatsapp: [{name: '', active: false}],
        facebook: [{name: '', active: false}],
        tiktok: [{name: '', active: false}],
        snap: [{name: '', active: false}],
        linkedin: [{name: '', active: false}],
        youtube: [{name: '', active: false}],
        telegram: [{name: '', active: false}],
        weechat: [{name: '', active: false}],
        dribble: [{name: '', active: false}],
        pinterest: [{name: '', active: false}],
        behance: [{name: '', active: false}],
        reddit: [{name: '', active: false}]
    }

    const getSocialData = async (e) => {
        let response = await api_GetSocial();
        if( response.status == 200 ) {
            social_obj = response.data; RenderSocialDiv();
        } else {
            if (response.status == 401 || response.status == 404) { window.location.href = "/login"; }
            if (response.status == 500) { window.location.href = "/"; }
        }
    }
    const SetSocialData = async () => {
        if (AxiosWork) { return; } else { document.querySelector('.js_social_submit').classList.add('opacity-50'); AxiosWork = true; }
        let response = await api_SetSocial({social_obj: social_obj});
        AxiosWork = false; document.querySelector('.js_social_submit').classList.remove('opacity-50');
        console.log(response);
        if( response.status == 200 ) {
            document.querySelectorAll('.inp_social_error').forEach(function(el){el.classList.remove('text-red-400');}); document.querySelectorAll('.inp_social_error').forEach(function(el){el.classList.add('text-green-400');});
            document.querySelectorAll('.inp_social_error').forEach(function(el){el.innerHTML = 'Saved successfuly';}); document.querySelectorAll('.inp_social_error').forEach(function(el){el.classList.remove('hidden');});
        } else {
            if (response.status == 401 || response.status == 404) { window.location.href = "/login"; }
            else if (response.status == 400) { InputWasWrong('inp_social', t("index.social_username_length_error")); }
            else if (response.status == 500) { InputWasWrong('inp_social', t("index.request_error")); }
        }
    }
    const getProfileData = async (e) => {
        let response = await api_GetProfile();
        if( response.status == 200 ) {
            profile_obj = response.data;
            document.querySelectorAll('.js_pnav_fullname').forEach(function(el){ el.innerHTML = response.data.fullname; });
            document.querySelectorAll('.js_pnav_email').forEach(function(el){ el.innerHTML = response.data.email; });
            if (response.data.avatar != ''){ document.querySelector('.inp_p_leftavatar_img').setAttribute('src', 'http://'+window.location.hostname+':5000/static/avatar/'+response.data.avatar); response.data.avatar = ''; }
        } else {
            if (response.status == 401 || response.status == 404) { window.location.href = "/login"; }
            if (response.status == 500) { window.location.href = "/"; }
        }
    }
    function ReloadPage(e) { window.location.reload(); }
    function GoToUsernamePage(e) { window.open('/'+profile_obj.username, '_blank'); }

    function InputClearError(dom_id) { document.querySelectorAll('.'+dom_id+'_error').forEach(function(el){el.classList.add('hidden');}); }
    function InputWasWrong(dom_id, error_text = '') {
        document.querySelectorAll('.'+dom_id+'_error').forEach(function(el){el.classList.remove('text-green-400');}); document.querySelectorAll('.'+dom_id+'_error').forEach(function(el){el.classList.add('text-red-400');});
        document.querySelectorAll('.'+dom_id+'_error').forEach(function(el){el.innerHTML = error_text;}); document.querySelectorAll('.'+dom_id+'_error').forEach(function(el){el.classList.remove('hidden');});
    }

    function SocialBlock({ stype }) {
        let tdiv = [];
        social_obj[stype].forEach(function(sn, i){
            let slast = false; if (social_obj[stype].length == (i+1)) { slast = true; }
            tdiv.push(<SocialInputBlock key={`child_${stype+i}`} stype={stype} si={i} svalue={sn.name} sactive={sn.active} slast={slast} />);
        });
        
        return (
            <div className="flex flex-col md:flex-row items-center relative gap-[1rem] lg:gap-[4rem]">
                <div className="flex items-center gap-[.6rem] font-[600] md:w-48">
                    {/*<img src={social_img[stype]} alt="" />*/}
                    {social_img[stype]}
                    <h3 className="font-[500] capitalize">{stype}</h3>
                </div>
                <div className="flex flex-col gap-[1rem] w-full">
                    {tdiv}
                </div>
            </div>
        )
    }

    function SocialInputBlock({ stype, si, svalue, sactive, slast }) {
        let inpbg = ''; if (!sactive) { inpbg = 'bg-green_readonly'; }

        return (
            <div className="flex items-center gap-[1rem] w-full md:w-10/12 lg:w-8/12 2xl:w-6/12">
                <div className="relative w-full">
                    <input className={`w-full focus:outline-none focus:ring-2 focus:ring-green-400 input-styles profile-inputs sm-input pl-4 pr-20 ${inpbg}`}
                        id={`inp${stype+si}`} name={`inp${stype+si}`} type="text" placeholder="@username" defaultValue={svalue} maxLength="50" readOnly={!sactive} onChange={(e) => Change_InputSocial(e, stype, si)} />
                    <button className="absolute top-3 right-4" style={{ outline: 'none' }} >
                        <input id={`ch${stype+si}`} className="socswitch socswitch--shadow" type="checkbox" defaultChecked={!sactive} onChange={(e) => Change_ActiveStatus(e, stype, si)} />
                        <label htmlFor={`ch${stype+si}`} style={{ width: "60px" }}></label>
                    </button>
                </div>
                
                <div className="btn-box flex flex-col gap-[.6rem]">
                    { slast ? <SocialPlusButton key={`pbutton_${stype+si}`} stype={stype} si={si} /> : <SocialMinusButton key={`mbutton_${stype+si}`} stype={stype} si={si} /> }
                </div>    
            </div>
        )
    }
    function SocialPlusButton({ stype }) {
        //w-10 h-10 md:w-12 md:h-12
        return(
            <button className="flex items-center justify-center add-btn-style w-8 h-8 md:w-6 md:h-6 hover:opacity-80" style={{ outline: 'none' }}
                    onClick={(e) => Click_AddNewSocial(e, stype)} >
                <img src={PlusIcon} atl="" />
            </button>
        )
    }
    function SocialMinusButton({ stype, si }) {
        //w-10 h-10 md:w-12 md:h-12
        return(
            <button className="flex items-center justify-center add-btn-style w-8 h-8 md:w-6 md:h-6 hover:opacity-80" style={{ outline: 'none' }}
                    onClick={(e) => Click_MinusOldSocial(e, stype, si)}>
                <svg className="w-6 h-6 text-[#fff]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"></path>
                </svg>
            </button>
        )
    }

    const RenderSocialDiv = async (man_rerender = false) => {
        InputClearError('inp_social');
        let tdiv = []; tdiv.push(<div key="hr-base" className="border w-full my-[1.5rem]" style={{color: "#989898", opacity: ".2"}}></div>);
        social_arr.forEach(function(sc, i){
            tdiv.push(<SocialBlock key={`main_${sc}`} stype={sc} />);
            tdiv.push(<div key={`hr-${i}`} className="border w-full my-[1.5rem]" style={{color: "#989898", opacity: ".2"}}></div>);
        });
        await js_SocialMediaDiv.render(tdiv);
        if (man_rerender){
            social_arr.forEach(function(ic, i){
                social_obj[ic].forEach(function(jc, j){
                    document.querySelector("#inp"+ic+j).value = jc.name;
                    document.querySelector("#ch"+ic+j).checked  = jc.active;
                });
            });
        }
    }
    
    useEffect(() => {
        js_SocialMediaDiv = ReactDOM.createRoot(document.querySelector('.js_social_media_div'));
        getSocialData();
        getProfileData();
    });

    function Change_ActiveStatus(e, stype, si) {
        social_obj[stype][si].active = !social_obj[stype][si].active; RenderSocialDiv();
    }
    function Click_AddNewSocial(e, stype) {
        social_obj[stype].push({name: '', active: true}); RenderSocialDiv();
    }
    function Click_MinusOldSocial(e, stype, si) {
        social_obj[stype].splice(si, 1); RenderSocialDiv(true);
    }
    function Change_InputSocial(e, stype, si) {
        if (e.currentTarget.value.substring(0, 1) == "@") { e.currentTarget.value = e.currentTarget.value.substring(1); }
        social_obj[stype][si].name = e.currentTarget.value; InputClearError('inp_social');
    }

    return (
    <>
    {/*<NavBar />*/}
    <section id="SocialMediaPage" className="w-full min-h-screen flex flex-col lg:flex-row font-primary overflow-hidden">
        <ProfileNavBar id_menu_active={1} />

        <div className="w-full bg-[#F5FAF5] m_md:px-4 md:px-12 py-[3rem] tab-full-width lg-full-width h-screen overflow-y-auto">
            <div className="flex flex-col gap-[2rem]">
                <div className="flex flex-row justify-between">
                    <h2 className="text-2xl lg:text-3xl font-[600]">Edit Social Media</h2>
                    <button className="flex items-center min-w-[7.625rem] gap-2 hover:opacity-75" onClick={(e) => GoToUsernamePage(e)} style={{ padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px"}}>
                        <AiOutlineLink size="26px" color="#000" /> {t("index.view_profile")}
                    </button>
                </div>
                <div className=" flex items-center gap-[1rem] ">
                    <span className="text-[#A4A4A4]">{t("index.my_profile")}</span>
                    <span>
                        <img src={Superior} alt="" />
                    </span>
                    <span className="font-[600]">{t("index.social_media")}</span>
                </div>
                <div className="hidden lg:flex justify-between w-full">
                    <div className=" flex flex-col gap-[.7rem]">
                        <h2 className="lg:text-2xl font-[600]">{t("index.social_media")}</h2>
                        <p className="text-[#A4A4A4] text-[.9rem]">{t("index.update_social")}</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex items-center justify-center gap-[1rem]">
                            <button className="min-w-[7.625rem]" onClick={(e) => ReloadPage(e)} style={{ padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px", }}>
                                {t("index.cancel")}
                            </button>
                            <button className="min-w-[7.625rem] text-white save-button js_social_submit" onClick={(e) => SetSocialData(e)}
                                style={{ background: "linear-gradient(94.73deg, rgb(0, 204, 102) -8.28%, rgb(35, 180, 107) 110.81%)", boxShadow: "rgb(35 180 107 / 35%) 4px 16px 32px", padding: "0.875rem 1.875rem", borderRadius: "40px" }}>
                                <div className="text flex items-center justify-center w-full">{t("index.save")}</div>
                                <div className="loader"></div>
                            </button>
                        </div>
                        <span className="text-red-400 text-sm pt-2 pl-4 inp_social_error hidden" style={{ maxWidth: '260px' }}></span>
                    </div>
                </div>
            </div>

            <div className="js_social_media_div">
                
            </div>
            <div className="flex flex-col">
                <div className="flex lg:hidden items-center justify-center gap-[1rem]">
                    <button onClick={(e) => ReloadPage(e)} className="min-w-[7.625rem]" style={{ padding: "0.875rem 1.875rem", border: "2px solid #001428", borderRadius: "40px" }}>
                        {t("index.cancel")}
                    </button>
                    <button className="min-w-[7.625rem] text-white save-button" onClick={(e) => SetSocialData(e)}
                            style={{ background: "linear-gradient(94.73deg, rgb(0, 204, 102) -8.28%, rgb(35, 180, 107) 110.81%)", boxShadow: "rgb(35 180 107 / 35%) 4px 16px 32px", padding: "0.875rem 1.875rem", borderRadius: "40px" }}>
                        <div className="text flex items-center justify-center w-full"> {t("index.save")} </div>
                        <div className="loader"></div>
                    </button>
                </div>
                <span className="text-sm pt-2 inp_social_error text-green-400 text-center"></span>
            </div>
        </div>
    </section>
    </>
    );
};

export default SocialProfile;